import { Box, Button, CircularProgress, Container, Drawer, Grid, MenuItem, TextField } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { useEffect, useState } from 'react';
import { DealerSecondaryProfileData, IContactJobPosition, IDealerBusinessType } from './twilioInterfaces';
import twilioApi from './twilio-api';
import { useAppSelector } from 'app';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import AppSnackbar from 'components/snackbar/snackbar';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import GoogleAutocomplete from 'common/components/input-fields/GoogleAutocomplete';
import GoogleAutocompleteResp from 'common/models/google-api';

export default function PhoneRegistrationDrawer({
  open,
  onClose,
  onDataLoad, // Prop for the callback function
}: {
  open: boolean;
  onClose(): void;
  onDataLoad(data: any): void; // Declare the onDataLoad function type
}): React.ReactElement {
  const [businessTypes, setBusinessTypes] = useState<IDealerBusinessType[]>([]);
  const [jobPositions, setJobPositions] = useState<IContactJobPosition[]>([]);
  const [emailError, setEmailError] = useState<{ notEmail: boolean; helperText: string }>({
    notEmail: false,
    helperText: '',
  });
  const [phoneError, setPhoneError] = useState<{ notPhone: boolean; helperText: string }>({
    notPhone: false,
    helperText: '',
  });

  const dealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setSubmissionStatus] = useState<boolean>(false);
  const [registrationStatus, setRegistrationStatus] = useState<string>('');
  const [registrationFailedReson, setregistrationFailedReson] = useState<string>('');
  const [registrationIdentifier, setRegistrationIdentifier] = useState<string>('EIN');
  const [googleApiData, setGoogleApiData] = useState<GoogleAutocompleteResp>();

  const initialValues: DealerSecondaryProfileData = {
    companyName: '',
    location: '',
    businessType: '',
    ein: '',
    businessIdentifier: '',
    streetAddress: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    website: '',
    firstName: '',
    lastName: '',
    businessTitle: '',
    jobPosition: '',
    email: '',
    phoneNumber: '',
  };
  const validationSchema = Yup.object<any>({
    companyName: Yup.string().trim().required('Company Name Required'),
    phoneNumber: Yup.string().required('Phone Required'),
    firstName: Yup.string().trim().required('First Name Required'),
    lastName: Yup.string().trim().required('Last Name Required'),
    businessTitle: Yup.string().trim().required('Business Title Required'),
    jobPosition: Yup.string().required('Job Position Required'),
    region: Yup.string().trim().required('Region Required'),
    country: Yup.string().required('Country Required'),
    city: Yup.string().trim().required('City Required'),
    ein: Yup.string().required('Required')
    .matches(/^\d{9}$/, 'Require 9 digit code'),
    businessIdentifier: Yup.string().required('Business Identifier Required'),
    streetAddress: Yup.string().trim().required('Address Required'),
    businessType: Yup.string().required('Business Type Required'),
    email: Yup.string().required('Email Required'),
    postalCode: Yup.string()
      .required('Postal Code Required')
      .matches(/^([0-9]{5}(?:-[0-9]{4})?|[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)$/, 'Invalid Postal Code Format'),
    website: Yup.string()
      .required('Website Required')
      .url('Website URL must start with "https://')
      .test('is-https', 'Website URL must start with "https://"', (value) => {
        return !value || value.startsWith('https://');
      }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (profileData: DealerSecondaryProfileData) => {

      // Check if phoneNumber starts with "+1", if not, append "+1"
      if (!profileData.phoneNumber.startsWith('+1')) {
        profileData.phoneNumber = `+1${profileData.phoneNumber}`;
      }

      setLoading(true);
      const response = await twilioApi.addPhoneRegistration(dealerIdCrm, profileData);
      if (response.data.toString() === 'Success') {
        showSnackBar({
          type: 'success',
          message:
            'Registration submitted. You will be notified if additional information is needed, or when it is approved.',
        });
        setLoading(false);
        setSubmissionStatus(true);
        setRegistrationStatus('pending-review');
      } else {
        setLoading(false);
        showSnackBar({ type: 'error', message: response.data.toString() });
      }
    },
  });
  function handleChangeEIN(event: any) {
    if (isNaN(Number(event.target.value.replace('-', '')))) {
      return;
    }

    if (registrationIdentifier === 'EIN') {
      let value = event.target.value;
      if (event.target.value.length === 3 && event.target.value.includes('-')) {
        value = event.target.value.replace('-', '');
      }
      if (event.target.value.length === 2) {
        value = event.target.value + '-';
      }
      event.target.value = value;
    }
    if(registrationIdentifier === 'CBN' && event.target.value.length >= 10){
      return;
    }

    formik.handleChange(event);
  }

  function ValidateEmail(email: any) {
    if (email.length > 0 && !email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      formik.setFieldError('email', 'Invalid Email');
      setEmailError({ notEmail: true, helperText: 'Invalid Email' });
    } else {
      setEmailError({ notEmail: false, helperText: '' });
    }
  }

  function ValidatePhoneNumber(phoneNumber: any) {
    if (phoneNumber.length > 0 && !phoneNumber.match(/^\d{10}$/)) {
      formik.setFieldError('phoneNumber', 'Invalid Phone Number');
      setPhoneError({
        notPhone: true,
        helperText: 'Please enter a valid 10-digit phone number',
      });
    } else {
      setPhoneError({ notPhone: false, helperText: '' });
    }
  }

  const handleZipCodeChange = (e:any) => {
    let newValue = e.target.value;
    if (newValue.length > 5 && !newValue.includes('-')) {
      newValue = newValue.substring(0, 5) + '-' + newValue.substring(5);
    } else if (newValue.length < 7) {
      newValue = newValue.substring(0, 5);
    }
    formik.setFieldValue(`postalCode`, newValue);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await twilioApi.getSecondaryProfileData(dealerIdCrm);
        if (response.companyName.toString().length > 0) {
          
          if (response.campaignStatus === 'VERIFIED' && response.isActive == true) {
            onDataLoad(true);
            
          }
          else{
            onDataLoad(false);
          }
          setRegistrationStatus(response.status);
          formik.setValues(response);
          if(response.ein.toString().includes('-')){
            formik.setFieldValue('businessIdentifier', 'EIN');
          }
          else{
            formik.setFieldValue('businessIdentifier', 'CBN');
          }
          if(response.status === 'twilio-rejected' || response.brandStatus === 'FAILED')  {            
            setSubmissionStatus(false);
            setRegistrationStatus('twilio-rejected');
            setregistrationFailedReson(response.brandFailedReason)
          }          
          else 
          {
            setSubmissionStatus(true);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setBusinessTypes(twilioApi.businessTypes);
    setJobPositions(twilioApi.jobPositions);
  }, []);

  useEffect(() => {
    if (googleApiData) {
      formik.setFieldValue(`streetAddress`, googleApiData?.address1);
      formik.setFieldValue(`city`, googleApiData?.city);
      formik.setFieldValue(`region`, googleApiData?.state);
      formik.setFieldValue(`postalCode`, googleApiData?.postalCode);
      formik.setFieldValue(`country`, googleApiData?.country);
    }
  }, [googleApiData]);

  return (
    <Drawer PaperProps={{ style: { width: '500px' } }} variant="temporary" anchor="right" open={open} onClose={onClose}>
      <DrawerToolbar
        title="Phone Registration "
        onClose={() => {
          onClose();
        }}
      />
      <Box mb={1} />
      <Container>
        {open && (
          <>
            <Box mt={1}>
              <Grid spacing={2} container>
                <Grid item xs={8}>
                  {(isSubmitted || registrationStatus === 'twilio-rejected') ? (
                    <Box fontSize={14} color={registrationStatus === 'twilio-rejected' ? 'red' : 'green'}>
                      {registrationStatus === 'pending-review' || registrationStatus === 'in-review'
                        ? 'Form Submitted Successfully. Your submission is under review.'
                        : registrationStatus === 'twilio-rejected'
                        ? 'Your registration is rejected '+registrationFailedReson +'.'
                        : registrationStatus === 'twilio-approved'
                        ? 'Your registration is approved.'
                        : 'Form Submitted Successfully.'}
                    </Box>
                  ):<></>
                  }
                </Grid>
                <Grid item xs={4}>
                  <Button
                    disabled={isSubmitted}
                    style={{ float: 'right', width: '100px' }}
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    onClick={() => formik.handleSubmit()}>
                    {loading ? (
                      <CircularProgress size={'1rem'} style={{ color: 'white' }} />
                    ) : registrationStatus === 'pending-review' ? (
                      'Submitted'
                    ) : registrationStatus === 'twilio-rejected' ? (
                      'Re-submit'
                    ) : (
                      'SUBMIT'
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextLimitField
                    disabled={isSubmitted}
                    helperTextAlignLeft
                    fullWidth
                    required
                    characters={50}
                    name="companyName"
                    value={formik.values.companyName}
                    error={!!formik.errors.companyName && formik.touched.companyName}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Legal Company Name"
                    variant="outlined"
                    size="small"
                    style={{ marginTop: '20px' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    select
                    required
                    name="businessType"
                    value={formik.values.businessType}
                    error={!!formik.errors.businessType && formik.touched.businessType}
                    helperText={formik.touched.businessType&& formik.errors.businessType}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Business Type"
                    variant="outlined"
                    size="small">
                    {businessTypes.map((item) => (
                      <MenuItem key={item.business_type} value={item.business_type}>
                        {item.business_type}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    select
                    name="businessIdentifier"
                    value={formik.values.businessIdentifier}
                    error={!!formik.errors.businessIdentifier && formik.touched.businessIdentifier}
                    helperText={formik.touched.businessIdentifier && formik.errors.businessIdentifier }
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {
                      setRegistrationIdentifier(e.target.value);
                      formik.setFieldValue('ein', '');
                      formik.handleChange(e);
                    }}
                    placeholder="EIN"
                    label="Business Identifier"
                    variant="outlined"
                    size="small">
                    <MenuItem key={1} value={'EIN'}>
                      EIN/TIN
                    </MenuItem>
                    <MenuItem key={2} value={'CBN'}>
                      CBN
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (!/^[0-9]+$/.test(value)) {
                        return;
                      }
                      formik.handleChange(event)
                    }}
                    name="ein"
                    value={formik.values.ein}
                    error={!!formik.errors.ein && formik.touched.ein}
                    helperText={formik.touched.ein && formik.errors.ein }
                    placeholder={'xxxxxxxxx'}
                    //onChange={formik.handleChange}
                    label={registrationIdentifier === 'EIN' ? 'EIN/TIN' : 'CBN'}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 9 }}
                  />
                </Grid>
              </Grid>{' '}
            </Box>
            <hr style={{ marginTop: '15px', marginBottom: '15px' }} />
            <Box mt={1}>
              <Grid spacing={2} container>
                <Grid item xs={12}>
                  <GoogleAutocomplete
                    name="streetAddress"
                    label={"Street Address"}
                    isSubmitted={isSubmitted}
                    setResponse={setGoogleApiData}
                    initValue={formik.values.streetAddress}
                    onChange={(e: any) => {
                      formik.setFieldValue(`streetAddress`, e);
                    }}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="city"
                    value={formik.values.city}
                    error={!!formik.errors.city &&formik.touched.city}
                    helperText={formik.touched.city && formik.errors.city }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Any City"
                    label="City"
                    variant="outlined"
                    size="small"
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="region"
                    value={formik.values.region}
                    error={!!formik.errors.region && formik.touched.region}
                    helperText={formik.touched.region && formik.errors.region }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Any Region"
                    label="State/Province"
                    variant="outlined"
                    size="small"
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="postalCode"
                    value={formik.values.postalCode}
                    error={!!formik.errors.postalCode && formik.touched.postalCode}
                    helperText={formik.touched.postalCode && formik.errors.postalCode}
                    onBlur={formik.handleBlur}
                    onChange={(e: any) => {handleZipCodeChange(e)}}
                    placeholder="12345"
                    label="Postal Code"
                    variant="outlined"
                    size="small"
                  />
                </Grid>{' '}
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    select
                    name="country"
                    value={formik.values.country}
                    error={!!formik.errors.country && formik.touched.country}
                    helperText={formik.touched.country && formik.errors.country}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="US"
                    label="Country"
                    variant="outlined"
                    size="small">
                    <MenuItem key={1} value={'US'}>
                      US
                    </MenuItem>
                    <MenuItem key={2} value={'CA'}>
                      CA
                    </MenuItem>
                  </TextField>
                </Grid>{' '}
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="website"
                    value={formik.values.website}
                    error={!!formik.errors.website && formik.touched.website}
                    helperText={formik.touched.website && formik.errors.website}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="https://www.test.com"
                    label="Website URL"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>{' '}
            </Box>
            <hr style={{ marginTop: '15px', marginBottom: '15px' }} />
            <Box mt={1}>
              <Grid spacing={2} container>
                <Grid item xs={6}>
                  <TextLimitField
                  helperTextAlignLeft
                    disabled={isSubmitted}
                    fullWidth
                    required
                    characters={50}
                    name="firstName"
                    value={formik.values.firstName}
                    error={!!formik.errors.firstName && formik.touched.firstName}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Contact First Name"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextLimitField
                  helperTextAlignLeft
                    disabled={isSubmitted}
                    fullWidth
                    required
                    characters={50}
                    name="lastName"
                    value={formik.values.lastName}
                    error={!!formik.errors.lastName && formik.touched.lastName}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Contact Last Name"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="businessTitle"
                    value={formik.values.businessTitle}
                    error={!!formik.errors.businessTitle && formik.touched.businessTitle}
                    helperText={formik.touched.businessTitle && formik.errors.businessTitle }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Title"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    select
                    name="jobPosition"
                    value={formik.values.jobPosition}
                    error={!!formik.errors.jobPosition && formik.touched.jobPosition}
                    helperText={formik.touched.jobPosition && formik.errors.jobPosition }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Job Position"
                    variant="outlined"
                    size="small">
                    {jobPositions.map((item) => (
                      <MenuItem key={item.jobPosition} value={item.jobPosition}>
                        {item.jobPosition}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="email"
                    value={formik.values.email}
                    error={(emailError.notEmail || !!formik.errors.email)&& formik.touched.email}
                    helperText={formik.touched.email && (emailError.helperText||formik.errors.email) }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onChangeCapture={(e: any) => {
                      ValidateEmail(e.target.value);
                    }}
                    label="Email"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={isSubmitted}
                    fullWidth
                    required
                    name="phoneNumber"
                    placeholder="1234567890"
                    value={formik.values.phoneNumber}
                    error={(phoneError.notPhone || !!formik.errors.phoneNumber) && formik.touched.phoneNumber}
                    helperText={formik.touched.phoneNumber && (phoneError.helperText || formik.errors.phoneNumber)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onChangeCapture={(e: any) => {
                      ValidatePhoneNumber(e.target.value);
                    }}
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>{' '}
            </Box>
          </>
        )}
      </Container>
    </Drawer>
  );
}
function dispatch(arg0: { payload: boolean; type: string }) {
  throw new Error('Function not implemented.');
}
