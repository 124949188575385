import { Box, Container, FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core';
import DrawerToolbar from 'common/components/drawer/drawer-toolbar';
import React, { ReactElement, useRef, useState, Ref } from 'react';
import { useAppDispatch } from 'app';
import { hideTaskDrawer, saveTask } from '../taskboardSlice';
import TaskForm, { TaskFormRef } from './TaskForm';
import { Task, TaskListObject } from 'components/lead/models/task';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'capitalize',
  },
}));

let alreadySelectedCustomer = {
  customerId: 0,
  customerName: '',
  locationDealerId:0,
};

function TaskAddDrawer(props: any): ReactElement {
  const dispatch = useAppDispatch();

  const classes: any = useStyles();
  const [followTask, setFollowTask] = useState(false);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);

  const taskFormRef = useRef<TaskFormRef>(null);
  const followUpTaskFormRef = useRef<TaskFormRef>(null);
  
  let preSelectedCustomer: any = new Object();

  if (props.customer) {
    preSelectedCustomer.customerId = props.customer.crmCustomerId
    preSelectedCustomer.customerName = 
    props.customer.name || 
    (props.customer.lastName && props.customer.firstName ? 
      props.customer.lastName + ', ' + props.customer.firstName : 
      (props.customer.lastName || props.customer.firstName || props.customer.middleName) ||     
      props.customer.organizationName
    );  }
  const handleTaskFormSubmit = (task: TaskListObject): void => {
    if (taskFormRef && taskFormRef.current) {
      let task1 = taskFormRef.current.getValues();
      let task2 = null;
      if (task1) {
        if (followTask) {
          if (followUpTaskFormRef && followUpTaskFormRef.current) {
            task2 = followUpTaskFormRef.current.getValues();
          }
        }
      }
      if (task1 && task2) {
        // if (task2.dueDate.length < 12) {
        //   task2.dueDate = moment.utc(new Date(task2.dueDate + 'T' + task2.dueTime)).format();
        // }
        dispatch(saveTask(task1, task2));
      } else if (task1) {
        // if (task1.dueDate.length < 12) {
        //   task1.dueDate = moment.utc(new Date(task1.dueDate + 'T' + task1.dueTime)).format();
        // }
        dispatch(saveTask(task1));
      }
    }
  };
  const handleOnClose = (): void => {
    dispatch(hideTaskDrawer());
  };

  return (
    <>
      <DrawerToolbar title="New Task" onClose={handleOnClose} />
      <Container maxWidth="sm">
        <TaskForm
          ref={taskFormRef}
          disabled={false}
          btnDisabled={false}
          onFormSubmit={handleTaskFormSubmit}
          onClose={handleOnClose}
          onMarkAlreadyCompleted={(e: boolean) => {
            setAlreadyCompleted(e);
          }}
          validate
          reschedule={false}
          done={null}
          edit={false}
          hideButtons={alreadyCompleted && followTask}
          previousCustomer={props.customer? preSelectedCustomer: null}
          disableCustomerSelect={props.customer? true : false}
        />

        {alreadyCompleted && (
          <>
            <hr />
            <Box flexGrow={1}>
              <Typography className={classes.title} variant="h4">
                Follow-Up task (Optional)
                <FormControlLabel
                  control={
                    <Switch
                      id="followTask"
                      checked={followTask}
                      onChange={(e: any) => {
                        setFollowTask(e.target.checked);
                        alreadySelectedCustomer = {
                          customerId: taskFormRef?.current?.getValues()?.customerId ?? 0,
                          customerName: taskFormRef?.current?.getValues()?.customerName ?? '',
                          locationDealerId: taskFormRef?.current?.getValues()?.dealerId ?? 0,
                        };
                        //Re-render component again
                        setAlreadyCompleted(false);
                        setTimeout(() => {
                          setAlreadyCompleted(true);
                        }, 0);
                      }}
                      name="followTask"
                      color="primary"
                    />
                  }
                  label=""
                  labelPlacement="start"
                />
              </Typography>
            </Box>

            <TaskForm
              ref={followUpTaskFormRef}
              disabled={false}
              btnDisabled={false}
              onFormSubmit={handleTaskFormSubmit}
              onClose={handleOnClose}
              onMarkAlreadyCompleted={() => {}}
              done={followTask}
              validate
              reschedule={false}
              edit={false}
              hideButtons={!alreadyCompleted}
              previousCustomer={alreadySelectedCustomer}
              disableCustomerSelect={props.customer? true : false}
            />
          </>
        )}
      </Container>
    </>
  );
}

export default TaskAddDrawer;
