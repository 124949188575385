import React, { ReactElement } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import LoginPage from 'components/authentication/components/login';
import BroadCastPage from 'components/broadcast/components/broadcast-page/broadcast-page';
import BroadcastCustomersGroup from 'components/broadcast/components/broadcast-page/customer-groups';
import MessageTemplatePage from 'components/broadcast/components/broadcasts-page';
import { CustomerPage } from 'components/customer';
import DashboardPage from 'components/dashboard/components/dashboard-page';
import NotFoundPage from 'components/error/404Page';
import InternalServerError from 'components/error/internal-service-error-page';
import { DashboardLayout, PublicLayout, PublishLayout } from 'components/layouts';
import Messenger from 'components/messaging/components/messenger';
import Setting from 'components/setting/components/settings';
import { SurveyPage, SurveysPage } from 'components/survey';
import { PublishedSurvey } from 'components/survey/components/published-survey';
import SurveyCustomersGroup from 'components/survey/components/survey-page/customers-group';
import Unsubscribe from 'components/unsubscribe/unsubscribe';

import CustomerGroups from 'components/customer-groups/components/customer-groups';
import CustomerGroup from 'components/customer-groups/components/customer-group';
import Invoice from 'components/invoice/components/invoice';
import SurveyResponse from 'components/survey/components/survey-response';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';
import PublishRoute from './publish-route';
import TaskBoard from 'components/lead/components/task-board/task-board';
import Inventory from 'components/inventory/Inventory';
import Deals from 'components/deals/deals';
import VideoCall from 'components/videocall/videocall';
import { VideoCallLayout } from 'components/layouts/PublicLayout/PublicLayout';
import VideoCallRoute from './videocall-route';
import Dealer from 'components/dealer/components/dealer/dealer';

import DealerAPI from 'components/authentication/api/dealer-api';
import { useAppSelector } from 'app';
import UserProfile from '../components/user-profile/UserProfile';
import { RemotePaymentPage } from 'components/payment/paymentPage';
import MessengerCustomer from 'components/messaging/components/messenger-customer';
import CustomersReviewHubPage from 'components/customers-review-hub/components/customers-review-hub';

interface RouteType {
  exact?: boolean;
  path: string;
  component: any;
}

const privateRoutes: RouteType[] = [
  {
    path: '/tasks',
    component: TaskBoard,
    exact: true,
  },
  {
    path: '/survey-response',
    component: SurveyResponse,
    exact: true,
  },
  {
    path: '/dealer',
    component: Dealer,
    exact: true,
  },
  {
    path: '/invoice',
    component: Invoice,
    exact: true,
  },
  {
    path: '/customers',
    component: CustomerPage,
    exact: true,
  },
  {
    path: '/review-hub',
    component: CustomersReviewHubPage,
    exact: true,
  },
  {
    path: '/',
    exact: true,
    component: DashboardPage,
  },
  {
    path: '/surveys',
    component: SurveysPage,
    exact: true,
  },
  {
    path: '/surveys/:id/designer',
    component: SurveyPage,
    exact: true,
  },
  {
    path: '/messages',
    component: Messenger,
    exact: true,
  },
  {
    path: '/messages-customer',
    component: MessengerCustomer,
    exact: true,
  },
  {
    path: '/broadcasts',
    component: MessageTemplatePage,
    exact: true,
  },
  {
    path: '/broadcasts/add',
    component: BroadCastPage,
    exact: true,
  },
  {
    path: '/broadcasts/:id',
    component: BroadCastPage,
    exact: true,
  },
  {
    path: '/settings',
    component: Setting,
    exact: true,
  },
  {
    path: '/surveys/:id/customers-group',
    component: SurveyCustomersGroup,
    exact: true,
  },
  {
    path: '/broadcasts/:type/:id/customers-group',
    component: BroadcastCustomersGroup,
    exact: true,
  },
  {
    path: '/customer-groups',
    component: CustomerGroups,
    exact: true,
  },
  {
    path: '/customer-groups/:id',
    component: CustomerGroup,
    exact: true,
  },
  {
    path: '/inventory/',
    component: Inventory,
    exact: true,
  },
  {
    path: '/deals',
    component: Deals,
    exact: true,
  },
  {
    path: '/user-profile',
    component: UserProfile,
    exact: true,
  },
  {
    path: '/payments',
    component: RemotePaymentPage,
    exact: true,
  },
];

const publicRoutes: RouteType[] = [
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/unsubscribe',
    component: Unsubscribe,
    exact: true,
  },
];

const videoCallRoutes: RouteType[] = [
  {
    path: '/videocall',
    component: VideoCall,
    exact: true,
  },
];

const ShareRoutes: RouteType[] = [
  {
    path: '/publishedSurvey/:hashId',
    component: PublishedSurvey,
  },
  {
    path: '//publishedSurvey/:hashId',
    component: PublishedSurvey,
  },
];

function Routes(): ReactElement {
  const allowedRoutesPath = privateRoutes.map((r) => r.path);
  const publicRoutesPath = publicRoutes.map((r) => r.path);
  const videoCallRoutesPath = videoCallRoutes.map((r) => r.path);
  const history = useHistory();
  const user = useAppSelector((state) => (state ? state.user : null));

  console.log("Routes");
  React.useEffect(() => {
    history.listen((location: any, action: any) => {
      let route = `${location.pathname}${location.search}${location.hash}`;
      console.log('accessed route :', route);
      if (user) {
        console.log(user);

        DealerAPI.logUserActivity(user.crmUserId, user.dealerId, route, action)
          .then((r) => {})
          .catch((e) => {});
      }
    });
  }, [1]);

  const shareRoutesPath = ShareRoutes.map((r) => r.path);
  return (
    <Switch>
      <Route exact path={shareRoutesPath}>
        <PublishLayout>
          <Switch>
            {ShareRoutes.map((route, index) => (
              <PublishRoute key={index} {...route} />
            ))}
          </Switch>
        </PublishLayout>
      </Route>
      <Route exact path={videoCallRoutesPath}>
        <VideoCallLayout>
          <Switch>
            {videoCallRoutes.map((route, index) => (
              <VideoCallRoute key={index} {...route} />
            ))}
          </Switch>
        </VideoCallLayout>
      </Route>
      <Route path={publicRoutesPath}>
        <PublicLayout>
          <Switch>
            {publicRoutes.map((publicRoute, index) => (
              <PublicRoute key={index} {...publicRoute} />
            ))}
          </Switch>
        </PublicLayout>
      </Route>

      <Route exact path={allowedRoutesPath}>
        <DashboardLayout>
          <Switch>
            {privateRoutes.map((privateRoute, index) => (
              <PrivateRoute key={index} {...privateRoute} />
            ))}
          </Switch>
        </DashboardLayout>
      </Route>
      <Route path="/internalServerError" component={InternalServerError} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default Routes;
