import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  Button,
  TextField,
  MenuItem,
  Container,
  CircularProgress,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { useFormik } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import dealsApi from '../apis/deals-api';
import CloseIcon from '@material-ui/icons/Close';
import {
  DealType,
  NewDealApi,
  CustomerContactReq,
  CustomerTreeOption,
  CustomerContacResponse,
  PipelineUsersRequest,
  PipelineListResponse,
} from '../model/dealsInterfaces';
import * as Yup from 'yup';
import { useAppSelector } from 'app';
import { Autocomplete } from '@material-ui/lab';
import AppSnackbar from 'components/snackbar/snackbar';
import DealContext from '../contexts/deal-context';
import User from 'components/setting/models/user';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CustomerContactDrawer } from '../../customer/components/CustomerDrawer/CustomerContactDrawer';
import CustomerContactTreeView from './customer-contact-treeview';
import { CustomerDto } from 'common/models';
import CustomerRootPageDrawer from 'components/customer/components/CustomerDrawer/CustomerRootPageDrawer';
import CustomerRootDrawer from 'components/customer/components/CustomerDrawer/CustomerRootDrawer';
import useStyles from '../styles/deals-styles';
import DealDetailsAPI from '../apis/deals-details-api';
import { PipeLineStage } from 'components/setting/models/deal-pipelines';
import Customer from 'common/models/customer';
import { PipelineStageList } from '../model/deal-edit-drawer';
import LeadSourceAPI from 'components/setting/api/manage-lead-source-api';
import config from 'config';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const textEditorApiKey = config.get('api.textEditor');

const init = {
  height: 200,
  convert_urls: true,
  plugins: 'lists',
  toolbar: `bullist numlist | bold italic underline | alignleft aligncenter alignright alignjustify`,
  menubar: false,
  branding: false,
  force_br_newlines: true,
  force_p_newlines: false,
  forced_root_block: '', // Needed for 3.x
};

function AddNewDeal({
  open,
  onClose,
  customer,
  pipelineStages,
  renderDeals,
}: {
  open: boolean;
  onClose(val: boolean): void;
  customer?: Customer;
  pipelineStages?: PipelineStageList[];
  renderDeals(): void;
}): ReactElement {
  const classes = useStyles();
  const dealContext = useContext(DealContext);
  const [newDealTypes, setNewDealTypes] = useState<DealType[]>([]);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const currentUserId = useAppSelector((state) => state.user.crmUserId);
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const [editorLoader, setEditorLoader] = useState(true);
  const [pipelineUsers, setPipelineUsers] = useState<User[] | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const userExists = dealContext.user?.find((x) => x.crmUserId === currentUserId) ? true : false;
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  //States for cutomer contacts
  const [customerContactDrawerState, setCustomerContactDrawerState] = useState(false);
  const [selectedContacts, setSelectedContacts] = React.useState<number[]>([]);
  const [customerTreeOptions, setCustomerTreeOptions] = React.useState<CustomerTreeOption[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<CustomerDto[] | undefined>([]);
  const [newCustomerContact, setNewCustomerContact] = useState<CustomerContacResponse | null>(null);
  const [enableCustomerValidation, setEnableCustomerValidation] = useState<boolean>(false);
  const [nodeIdCount, setNodeIdCount] = useState<number>(0);
  const [pipelineList, setPipelineList] = useState<PipelineListResponse[]>([]);
  const [drawerLoading, setDrawerLoading] = useState<boolean>(false) 
  let currentPipelineId = 0

  const [altPipelineStages , setAltPipelineStages] = useState<PipelineStageList[]>(pipelineStages? pipelineStages : []);
  
  let departments: any = [];

  let preSelectedCustomer: any = new Object();
  if (customer) {
    preSelectedCustomer.id = customer && customer.crmCustomerId? customer.crmCustomerId : null,
    preSelectedCustomer.name = customer 
    ? (customer.name || customer.organizationName || (customer.firstName && customer.lastName ? `${customer.lastName}, ${customer.firstName}` : (customer.lastName || customer.firstName || null))) 
    : null;
  }
  //States for cutomer contacts


  useEffect (() => {
    if (customer) {
      setSelectedCustomers([preSelectedCustomer])
    }
  } ,[customer])

  const defaultUserValue: User = {
    crmUserId: currentUserId,
    dealerId: 0,
    departments: [],
    fullName: '',
    id: '',
    isActive: false,
    isAdmin: false,
    userId: 0,
    userRoles: [],
    username: dealContext.user?.find((x) => x.crmUserId === currentUserId)?.username!,
  };

  const initialValues: NewDealApi = {
    type: 0,
    dealType: '',
    customerContacts: [],
    description: '',
    title: customer? customer.name : '',
    assignedUserId: userExists ? currentUserId : '',
    createdByUserId: currentUserId,
    pipelineId: 0,
    amount: undefined,
    pipelineStageName: pipelineStages && pipelineStages[0]? pipelineStages[0].stageName : null,
    position: 1,
    pipelineStageId: pipelineStages && pipelineStages[0]? pipelineStages[0].stageId : null
  };
  const validationSchema = Yup.object<any>({
    dealType: Yup.string().required('Lead Source Required'),
    title: Yup.string().required('Deal Title Required').max(50, 'Max 50 characters allowed.'),
    assignedUserId: !userExists ? Yup.string().required('Deal Assignee Required') : undefined,
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: NewDealApi) => {
      setSaveButtonLoading(true);
      await onSubmitHandler(values);
      setSaveButtonLoading(false);
    },
  });

  function beforeSubmitHandler() {
    if (!selectedCustomers || selectedCustomers.length === 0) {
      setEnableCustomerValidation(true);
    }

    formik.handleSubmit();
  }

  async function onSubmitHandler(values: NewDealApi) {
    //Setting the customers contacts value
    let customerContacts: CustomerContactReq[] = [];
    if (selectedContacts?.length > 0) {
      customerTreeOptions.forEach((cusOpt: CustomerTreeOption) => {
        const contacts = cusOpt.nodes?.filter((x) => selectedContacts.includes(x.value));
        const contactsReq = contacts?.map((x) => ({
          customerId: x.customerId,
          contactId: x.value,
        })) as CustomerContactReq[];
        customerContacts = customerContacts.concat(contactsReq);
      });
    }
    if (selectedCustomers && (selectedCustomers?.length > 0 || preSelectedCustomer.id)) {
      const customersAlreadyInTreeView = customerContacts.map((x) => x.customerId);
      selectedCustomers
        .filter((x) => !customersAlreadyInTreeView.includes(x.id))
        .forEach((cus: CustomerDto) => {
          const contactsReq = {
            customerId: cus.id,
            contactId: 0,
          } as CustomerContactReq;
          customerContacts.push(contactsReq);
        });
    } else {
      return;
    }
    formik.setFieldValue('customerContacts', customerContacts);
    values.customerContacts = customerContacts;

    //Assigning current user as assigned user initially
    if (userExists && values.assignedUserId == '') {
      values.assignedUserId = currentUserId;
    }

    values.pipelineId = values.pipelineId != 0 ? values.pipelineId : dealContext.defaultPipelineId ?? 0;

    //Setting amount to zero when its emtpy
    values.amount = values.amount?.toString() === '' ? 0 : values.amount;

    try {
      await dealsApi.PipelineDeals.addNewDeal(dmsDealerIdCrm, values);
      onCloseDrawerHandler();
      renderDeals();
      formik.resetForm();
      showSnackBar({ type: 'success', message: 'Deal added successfully' });
    } catch (err) {
      showSnackBar({ type: 'error', message: 'Unable to add deal.' });
    }
  }

  const handleUserChange = (val: User) => {
    if (val && val.crmUserId) {
      formik.setFieldValue('assignedUserId', val.crmUserId);
    } else {
      formik.setFieldValue('assignedUserId', '');
    }
    setSelectedUser(val);
  };

  useEffect(() => {
    LeadSourceAPI.getLeadSources().then((response) => {
      setNewDealTypes(
        response.map((x) => ({
          typeId: x.id,
          value: x.leadTagName,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (newCustomerContact != null) {
      setNewContactForAlreadySelectedCustomer(newCustomerContact);
    }
  }, [newCustomerContact]);

  useEffect(() => {
    if (selectedCustomers && selectedCustomers?.length > 0) {
      formik.setFieldValue('title', selectedCustomers[0].name);
    }
  }, [selectedCustomers]);

  async function getPipelineList() {
    try {
       const data = await dealsApi.PipelineDeals.getDealsPipelinesDD(dmsDealerIdCrm);
        if (data && data.length > 0) {
          setPipelineList(data);
          departments= data[0].departmentIds;
          currentPipelineId = data[0].pipelineId
          formik.setFieldValue('pipelineId', currentPipelineId)

        } 
    } catch (error: any) {
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }

  async function GetPipelineUsers() {
    const request: PipelineUsersRequest = {
      pipelineDepartments: departments,
    };
    const response = await dealsApi.PipelineDeals.getPipelineUsers(request);
    if (customer) {
      const user = response && response.length > 0 && (response.find((x) => x.crmUserId === currentUserId) ?? (response[0]? response[0] : null));
      formik.setFieldValue('assignedUserId', user? user.crmUserId : '')
      setSelectedUser(user? user : null);
    }
    setPipelineUsers(response);
  }

  async function GetPipelineStagesList(pipelineId: number) {
    try {
      const response = await dealsApi.PipelineDeals.getPipelineStagesList(pipelineId);
      
      if (pipelineStages) {
        setAltPipelineStages(response);

      } else {
        setAltPipelineStages(response)
        formik.setFieldValue('pipelineStageId', response[0].stageId)
        formik.setFieldValue('position', 1)
      }
      
    } catch (error: any) {
      showSnackBar({ type: 'error', message: 'Something went wrong' });
    }
  }

  useEffect(() => {
    if (!pipelineStages) { 
     getPipelineData();
    }
  }, [])

  const getPipelineData = async () => {
    setDrawerLoading(true)
    await getPipelineList()
    await GetPipelineStagesList(currentPipelineId)
    await GetPipelineUsers();
    setDrawerLoading(false)
}

  function setNewContactForAlreadySelectedCustomer(newCustomerContact: CustomerContacResponse) {
    let customerDD = selectedCustomers?.find((x) => x.id == newCustomerContact.crmCustomerId);

    if (customerDD) {
      let updatedCustomerTreeOptions = [...customerTreeOptions];

      let customerExists = customerTreeOptions.find((x) => x.value == newCustomerContact.crmCustomerId);
      const lastCustomerContacts =
        customerTreeOptions.length > 0 ? customerTreeOptions[customerTreeOptions.length - 1].nodes : undefined;
      let nodeId = lastCustomerContacts ? lastCustomerContacts[lastCustomerContacts.length - 1].nodeId : 0;

      if (customerExists) {
        customerExists.nodes?.push({
          type: 'contact',
          title: newCustomerContact.name,
          value: newCustomerContact.id,
          nodeId: ++nodeId,
          customerId: newCustomerContact.crmCustomerId,
        });

        const customerOptionIndex = customerTreeOptions.findIndex((x) => x.value == newCustomerContact.crmCustomerId);
        updatedCustomerTreeOptions[customerOptionIndex] = customerExists;
        setCustomerTreeOptions(updatedCustomerTreeOptions);
        setNodeIdCount(nodeId);
      } else {
        updatedCustomerTreeOptions.push({
          type: 'customer',
          title: customerDD.name,
          value: newCustomerContact.crmCustomerId,
          nodeId: ++nodeId,
          nodes: [
            {
              type: 'contact',
              title: newCustomerContact.name,
              value: newCustomerContact.id,
              nodeId: ++nodeId,
              customerId: newCustomerContact.crmCustomerId,
            },
          ],
        });
        setCustomerTreeOptions(updatedCustomerTreeOptions);
        setNodeIdCount(nodeId);
      }
    }
  }

  function onCloseDrawerHandler() {
    onClose(false);
    formik.resetForm();
    setSelectedUser(defaultUserValue);
    setPipelineUsers(undefined);
    setSelectedCustomers([]);
    setCustomerTreeOptions([]);
    setEnableCustomerValidation(false);
  }

  function CheckAmount(value: any) {
    //float number and replace other with empty
    const newvalue = value.replace(/[^\.0-9]/g, '')
    if (isNaN(newvalue)) return;
    if (newvalue.toString().indexOf('.') > -1 && newvalue.toString().split('.')[1].length > 2) return;
    formik.setFieldValue(`amount`, newvalue);
  }

  function handleStageChange(value: PipelineStageList) {
    formik.setFieldValue('position', value.stagePosition)
    formik.setFieldValue('pipelineStageName', value.stageName)
    formik.setFieldValue('pipelineStageId', value.stageId)
  }

  async function changePipelineHandler(pipelineId: number) {
    formik.setFieldValue('pipelineId', pipelineId);
    currentPipelineId = pipelineId;
    formik.setFieldValue('assignedUserId', '');

    setUsersLoading(true);
    if (pipelineStages && !customer) {
    const departmentIds = dealContext.pipeline?.find((x) => x.pipelineId === pipelineId)?.departmentIds;
    const request: PipelineUsersRequest = {
      pipelineDepartments: departmentIds,
    };
    const response = await dealsApi.PipelineDeals.getPipelineUsers(request);
    if (response) {
      setPipelineUsers(response);
      const user = response && response.length > 0 && (response.find((x) => x.crmUserId === currentUserId) ?? (response[0]? response[0] : null));
      formik.setFieldValue('assignedUserId', user? user.crmUserId : '')
      setSelectedUser(user? user : null);
    }
    const pipelineStagesResponse = await DealDetailsAPI.getPipelinesStagesDD(pipelineId);
    
    if (pipelineStagesResponse.length > 0) {
      const stages: PipelineStageList[] = []; 
      pipelineStagesResponse.forEach((stage) => {
        const pipeineStage: PipelineStageList = {
          stageId: stage.stageId,
          stageName: stage.stageName,
          stagePosition: stage.stagePosition,
        }
        stages.push(pipeineStage)
      })
      setAltPipelineStages(stages)
      formik.setFieldValue('position', stages[0].stagePosition)
      formik.setFieldValue('pipelineStageName', stages[0].stageName)
      formik.setFieldValue('pipelineStageId', stages[0].stageId)
    }
  } else {
    departments = pipelineList.find((x) => x.pipelineId === pipelineId)?.departmentIds;
    await GetPipelineStagesList(pipelineId)
    await GetPipelineUsers()
  }
    setUsersLoading(false);
  }

  return (
    <form>
      <Drawer
        variant="temporary"
        anchor={'right'}
        open={open}
        onClose={() => {
          onCloseDrawerHandler();
        }}>
        { drawerLoading? (
          <Box style={{ width: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress /> {/* Display spinner */}
        </Box>
        ) :
        (<Box style={{ width: '500px' }}>
          <Toolbar variant="dense">
            <Box flexGrow={1}>
              <Typography variant="h4">New Deal</Typography>
            </Box>
            <IconButton onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Container>
            <Box mt={1}>
              <Grid container style={{ paddingRight: '2%' }} justifyContent="flex-end" alignItems="flex-end">
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                    disabled={saveButtonLoading}
                    onClick={beforeSubmitHandler}>
                    {saveButtonLoading ? <CircularProgress style={{ color: 'white' }} size="1.4rem" /> : 'Save'}
                  </Button>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: '1em' }}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textFieldPadding}
                    select
                    autoFocus
                    fullWidth
                    name="dealType"
                    size="small"
                    variant="outlined"
                    label="Lead Source"
                    value={formik.values.dealType}
                    error={!!formik.errors.dealType}
                    helperText={formik.errors.dealType}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    onChange={formik.handleChange}>
                    {newDealTypes.map((options) => {
                      return (
                        <MenuItem key={options.typeId} value={options.typeId}>
                          {options.value}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>                
                <CustomerContactTreeView
                  preSelected={customer? true : false}
                  dealCustomerContacts={[]}
                  selectedContacts={selectedContacts}
                  selectedCustomers={selectedCustomers}
                  customerTreeOptions={customerTreeOptions}
                  enableCustomerValidation={enableCustomerValidation}
                  nodeIdCount={nodeIdCount}
                  disabledTreeView={false}
                  setSelectedContacts={setSelectedContacts}
                  setSelectedCustomers={setSelectedCustomers}
                  setCustomerTreeOptions={setCustomerTreeOptions}
                  setCustomerContactDrawerState={setCustomerContactDrawerState}
                  setNodeIdCount={setNodeIdCount}
                />
                <Grid item xs={12}>
                  <Box className={classes.textFieldPadding}>
                    {editorLoader && (
                      <div style={{ zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size="2rem" />
                      </div>
                    )}
                    <Editor
                      value={formik.values.description}
                      onEditorChange={(e) => {
                        formik.handleChange({
                          target: {
                            name: 'description',
                            value: e,
                          },
                        });
                      }}
                      apiKey={textEditorApiKey ||''}
                      id="description"
                      init={init}
                      onInit={() => {
                        setEditorLoader(false);
                      }}></Editor>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textFieldPadding}
                    fullWidth
                    name="title"
                    value={formik.values.title}
                    error={!!formik.errors.title}
                    helperText={formik.errors.title}
                    onChange={formik.handleChange}
                    label="Title"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textFieldPadding}
                    fullWidth
                    name="amount"
                    value={formik.values.amount}
                    onChange={(e: any) => {                      
                      CheckAmount(e.target.value);
                    }}
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type='text'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textFieldPadding}
                    select
                    fullWidth
                    name="pipelineId"
                    value={dealContext.defaultPipelineId && formik.values.pipelineId == 0 ? dealContext.defaultPipelineId : formik.values.pipelineId}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                      },
                    }}
                    onChange={(event: any) => {
                      changePipelineHandler(event.target.value);
                    }}
                    label="Select Pipeline"
                    variant="outlined"
                    size="small">
                    {!customer?  (dealContext.pipeline?.map((options) => {
                      return (
                        <MenuItem key={options.pipelineId} value={options.pipelineId}>
                          {options.pipelineName}
                        </MenuItem>
                      );
                    })) : (pipelineList?.map((options) => {
                      return (
                        <MenuItem key={options.pipelineId} value={options.pipelineId}>
                          {options.pipelineName}
                        </MenuItem>
                      );
                    }))
                    }
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  {usersLoading ? (
                    <LinearProgress color="primary" style={{ marginBottom: '2%' }} />
                  ) : (
                    <Box>
                    <Autocomplete
                      id="usersDropdown"
                      disableClearable={true}
                      value={selectedUser || defaultUserValue}
                      options={pipelineUsers || dealContext.user || []}
                      onChange={(event: any, values: any): void => handleUserChange(values)}
                      getOptionLabel={(option: any) => option.username}
                      disableCloseOnSelect
                      placeholder={selectedUser?.username}
                      getOptionSelected={(option: User, value: User): boolean => {
                        return option.crmUserId === value.crmUserId;
                      }}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                          {option.username}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="users"
                          id="users"
                          variant="outlined"
                          className={classes.textFieldPadding}
                          label="Assignment"
                          size="small"
                          value={formik.values.assignedUserId}
                          error={!!formik.errors.assignedUserId}
                          helperText={formik.errors.assignedUserId}
                          placeholder="Select Users"
                        />
                       
                      )}
                    />
                    <TextField
                    select
                    fullWidth
                    name="pipelineStage"
                    variant="outlined"
                    className={classes.textFieldPadding}
                    label="Pipeline Stage"
                    size="small"
                    value={formik.values.position}                 
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                      },
                    }}>
                    {altPipelineStages.map((options) => {
                      return (
                        <MenuItem key={options.stagePosition} value={options.stagePosition} onClick={() => {handleStageChange(options)}}>
                          {options.stageName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  </Box>
                  )}
                </Grid>
              </Grid>
            </Box>

            <CustomerContactDrawer
              open={customerContactDrawerState}
              selectedCustomers={selectedCustomers}
              setNewCustomerContact={setNewCustomerContact}
              onClose={() => setCustomerContactDrawerState(false)}
              convPhoneIds={undefined}
            />

            {!customer && (
              <>
                <CustomerRootPageDrawer />
                <CustomerRootDrawer />
              </>
            )}
          </Container>
        </Box>
      )}
      </Drawer>
    </form>
  );
}
export default AddNewDeal;
