import { Box, Container, Grid, Paper, Tab, Tabs, withStyles } from '@material-ui/core';
import { TabPanel } from 'common/components/tabs';
import { useRouter } from 'common/hooks';
import React, { FC } from 'react';
import Account from '../account';
import AutoResponder from './../auto-responder';

import Role from '../role/roles';
import Departments from './../department/departments';
import EmailSetting from '../email';
import General from '../general';
import Customers from '../customers/customers';
import QuickMessages from './../quick-messages';
import Users from './../users/users';
import Widget from './../widget';
import useStyles from './settings-styles';
import TaskboardSetting from '../taskboard-setting';
import DealPipeLines from '../deal-pipelines/deal-pipelines';
import GravityPaymentSettings from '../gravity-payment/gravityPaymentSettings'
import { useAppSelector } from 'app';
import TextAutomation from '../text-automation/text-automation';

const Setting: React.FC = () => {
  const classes: any = useStyles();
  // const isAdmin = useAppSelector((state)=> state.user.isUserAdmin);
  let userPermissions = useAppSelector((state) => state.user?.userPermissions);
  if (!userPermissions) {
    userPermissions = [];
  }
  const [value, setValue] = React.useState(0);
  const router = useRouter();

  let tabsArray = [
    { index: 0, label: 'General', component: General },
    { index: 1, label: 'Customers', component: Customers },
    { index: 2, label: 'Auto-Responder', component: AutoResponder },
    { index: 3, label: 'Quick-Messages', component: QuickMessages },
    { index: 4, label: 'Account', component: Account },
    // { index: 4, label: 'Roles', component: Role },
    { index: 5, label: 'Tasks', component: TaskboardSetting },
    { index: 6, label: 'Email', component: EmailSetting },
    { index: 7, label: 'Departments', component: Departments },
    { index: 8, label: 'Users', component: Users },
    { index: 9, label: 'Widget', component: Widget },
    { index: 10, label: 'Deals', component: DealPipeLines },
    { index: 11, label: 'Payments', component: GravityPaymentSettings },    
  ]
  if (userPermissions.find((x) => x.featureName.toLowerCase() === 'automations')) {
    tabsArray.push({ index: 12, label: 'Automation', component: TextAutomation as FC<{}> } );
  } 
  //It removes the last element of the tabs Array
  // if (!isAdmin){
  //   tabsArray.pop()
  // }

  const tabs = React.useMemo(
    () => tabsArray,
    [],
  );


  const CenteredTabs = withStyles({
    flexContainer: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })(Tabs);

  const handleTabChange = React.useCallback(
    (newValue: number): void => {
      window.location.hash = `${tabs[newValue].label.toLowerCase()}`;
      setValue(newValue);
    },
    [tabs],
  );

  React.useEffect(() => {
    const currentTab = tabs.find((x) => `#${x.label.toLowerCase()}` === router.location.hash);
    if (currentTab) {
      setValue(currentTab.index);
    } else {
      handleTabChange(0);
    }
  }, [handleTabChange, router.location.hash, tabs]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container>
        <Grid item xs={12} >
          <Paper square>
              <CenteredTabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={(_, newValue: any): void => handleTabChange(newValue)}
                aria-label="settings-tabs"
                >
                {tabs.map((tab: any) => (
                  tab.label=="Automation"?<></>:
                  <Tab style={{  minWidth:'65px' }} key={tab.index} label={tab.label} />
                ))}
              </CenteredTabs>
          </Paper>
          <Box mt={1} />
          {tabs.map((tab: any) => {
            const Component = tab.component;
            return (
              <TabPanel key={tab.index} value={value} index={tab.index}>
                <Component />
              </TabPanel>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Setting;
