import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from 'axios';
import { Button } from '@material-ui/core';
import DepartmentAPI from 'components/setting/api/department-api';
import { useAppSelector } from 'app';
import AppSnackbar from 'components/snackbar/snackbar';
import { object } from 'yup';
import config from 'config';
interface ChildComponentProps {
  onRefresh: () => void;
}

const FacebookLoginButton: React.FC<ChildComponentProps> = ({ onRefresh }) => {
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [Updated, setUpdated] = useState<boolean>(false);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const crmUserId = useAppSelector((state) => state.user.crmUserId);
  const facebookAppId = config.get('api.facebookAppId');
  const responseFacebook = async (response :any) => {
    // Assuming 'response.accessToken' is the user access token
    console.log("User access toke response ================>",response);
    
    try {
      // Make a request to exchange user token for a page token
      // const pageAccessTokenResponse = await axios.get(
      //   `https://graph.facebook.com/v18.0/me/accounts?access_token=${userAccessToken}`
      // );      
  
      // // Extract the page access token from the response
      // console.log("page access toke response ================>",pageAccessTokenResponse)
      // console.log("page access toke response data================>",pageAccessTokenResponse.data.data)
      if(response.status==="unknown"){}
      else{
            let CrmuserIds :any;
            let facebookdepartment : any;
            CrmuserIds = [crmUserId]
            //let department = await DepartmentAPI.getDepartments(dealerId,dmsDealerIdCrm)
            //let filteredArray  = department.filter(obj => obj.departmentName === 'Facebook')
            // console.log("filterd array  =====================>",filteredArray )      
            // if (filteredArray.length === 0) {
            //   facebookdepartment= await DepartmentAPI.createDepartment(dmsDealerIdCrm,'Facebook',dealerId,CrmuserIds)  
            // }
            // else{
            //   facebookdepartment= filteredArray[0];
            // }
            //console.log("facebook department array  =====================>",facebookdepartment ) 

            let facebookSettingsRequest :any ={   

              "dealerId": dealerId,
              "dmsDealerIdCrm":dmsDealerIdCrm,
              "userAccessToken": response.accessToken,
              "facebookUserId": response.userID,
              "facebookEmail": response.email,
              "facebookName": response.name,
              "CrmUserIds":CrmuserIds
            }
            await DepartmentAPI.updateDealerFacebookTokens(facebookSettingsRequest);
            showSnackbar({ type: 'success', message: 'Facebook settings updated' });
            onRefresh(); 
            setUpdated(true);
          }
     
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to update facebook tokens' });
      console.error('Error exchanging user token for page token', error);
    }
  };

  return (    
    <FacebookLogin
      appId={facebookAppId} // Replace with your Facebook App ID
      autoLoad={false}
      fields="name,email,picture"
      scope="public_profile,pages_show_list,pages_messaging"
      callback={responseFacebook}
      render={renderProps => (        
        <Button
          color="primary"
          variant="contained"
          size="small"
          type="submit"
          onClick={renderProps.onClick}>
          Connect to Facebook
        </Button>
      )}
    />
    
  );
};

export default FacebookLoginButton;
