import React, { ReactElement, ReactNode, useEffect, useRef, useState, forwardRef, useImperativeHandle, Ref } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Media, Message } from '../../models/message';
import ImageModal from './image-modal';
import ImageModalContext from './image-modal-context';
import { Box, Button, capitalize, CircularProgress, Divider, Drawer, FormControl, Grid, IconButton, Link, makeStyles, Modal, Theme, Toolbar, Tooltip, Typography } from '@material-ui/core';
import Footer from './message-bubble/footer';
import MessageMedia from './message-bubble/media';
import VideocamIcon from '@mui/icons-material/Videocam';
import Linkify from 'react-linkify';
import { blue } from '@material-ui/core/colors';
import MessagingAPI, { GravityPaymentCancelOrVoid, GravityPaymentDetails, GravityPaymentMessageBody } from 'components/messaging/api/messaging-api';
import config from 'config';
import { useAppSelector } from 'app';
import { GetLink, GetVideoStream } from 'dmsAppsCallbacks';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import MergeTag from './message-bubble/merge-tag';



const useMessageBodyStyles = makeStyles((theme: Theme): any => ({
  
  root: {
    overflowWrap: 'break-word',
    textAlign: 'left',
    padding: theme.spacing(1),
    borderRadius: '14px',
    marginBottom: '1px',
    whiteSpace: 'break-spaces',
    '&&.left': {
      backgroundColor: '#EEE',
      marginLeft: theme.spacing(1),
    },
    '&&.right': {
      color: 'white',
      backgroundColor: blue[500],
      marginRight: theme.spacing(2),
      padding: theme.spacing(1),
    },
    '&&.survey': {
      backgroundColor: theme.messenger.surveyMessageBg,
    },
    '&&.broadcast': {
      backgroundColor: theme.messenger.broadcastMessageBg,
    },
    '&&.websitelead': {
      backgroundColor: theme.messenger.leadTextingMessageBg,
    },
    '&&.highlightedMessage': {
      backgroundColor: theme.messenger.highlightedMessageBg,
    }
  },

  header: {
    color: 'white',
    fontVariant: 'small-caps',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
    '&&:hover': {
      color:'whitesmoke'
    },
    color:'powderblue',
    '&&.left': {
      color: 'blue',
    },
    '&&.right': {
      color: 'white',
    },
  },
  linkLeft: {
    textDecoration: 'underline',
    '&&:hover': {
      color:'darkblue'
    },
    color:'blue',
    '&&.left': {
      color: 'blue',
    },
    '&&.right': {
      color: 'white',
    },
  },
  broadcastLink: {
    color: 'white',
    textDecoration: 'underline'
  },
  mySwalPopup: {
    borderRadius: '10px !important',
  }
}));

type MessageListType = {

  messageSid: string;
  pageNumber: number;
  totalPages: number;
  messagesList: Message[];
  scrollIndex: number;
  onScrollTop: () => void;
  onScrollBottom: () => void;

}

let dollarUSLocale = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}); // $148,000

export interface messageListRef {
  scrollToSelectedMessage(): void;
}

const MessagesList = forwardRef((props: MessageListType, ref: Ref<messageListRef>): ReactElement | null => {

  useImperativeHandle(ref, () => ({
    scrollToSelectedMessage() {
    }
  }));


  const appUrl = config.get('app.url');
  let { messageSid, messagesList, pageNumber, scrollIndex, onScrollTop, onScrollBottom, totalPages } = props;
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined);
  const scrollBarRef = useRef<any | null>(null);
  const scrollBarContainerRef = useRef<any | null>(null);
  const [oldScrollTop, setOldScrollTop] = useState(0);
  const [oldScrollHeight, setOldScrollHeight] = useState(0);
  const isFetching = useRef(true);
  const [highlightClass, setHighlightCLass] = useState(true);
  const classes: any = useMessageBodyStyles();
  const [firstPage, setFirstPage] = useState<boolean>(true);
  const myRef = useRef<any>();
  const [textToPaySearch, setTextToPaySearch] = useState('');
  const [paymentDetails, setPaymentDetials] = useState<GravityPaymentDetails | null>(null);
  const [cancelPaymentLoader, setCancelPaymentLoader] = useState<boolean>(false);
  const DmsCheck = (localStorage.getItem('Login_Source')?.toString() !== 'DMS_IDEAL' && localStorage.getItem('Login_Source')?.toString() !== 'DMS_INFINITY')
  
  const user = useAppSelector((state: any) => state.user);
  const executeScroll = () => {
    if (scrollBarContainerRef && scrollBarContainerRef.current) {
      scrollBarContainerRef.current.scrollTop = myRef.current.offsetTop - 50; //.scrollHeight; //.getBoundingClientRect().top + window.scrollY ;//scrollBarContainerRef.current.scrollHeight;
      scrollBarRef.current.updateScroll();
      isFetching.current = false;
    }
  };

  useEffect(() => {
    if (scrollBarContainerRef && scrollBarContainerRef.current && scrollIndex === messagesList.length - 1) {
      scrollBarContainerRef.current.scrollTop = scrollBarContainerRef.current.scrollHeight;
      scrollBarRef.current.updateScroll();
      isFetching.current = false;
    }

  }, [messagesList.length, scrollBarContainerRef, scrollIndex]); //, // 

  useEffect(() => {
    if (scrollIndex !== messagesList.length - 1 && scrollBarContainerRef.current.scrollHeight !== oldScrollHeight) {
      scrollBarContainerRef.current.scrollTop = oldScrollTop + (scrollBarContainerRef.current.scrollHeight - oldScrollHeight);
      scrollBarRef.current.updateScroll();
      scrollBarRef.current.scroll
      isFetching.current = false;
    }
  }, [messagesList.length, oldScrollHeight, oldScrollTop, scrollIndex]); //, //

  useEffect(() => {
    if (myRef && myRef.current) {
      setHighlightCLass(true);
      setTimeout(() => {
        setHighlightCLass(false);
      }, 2000);
      executeScroll();
    }
  }, [messageSid]);


  const onListEnd = (): void => {
    if (scrollBarContainerRef && scrollBarContainerRef.current) {
      const element = scrollBarContainerRef.current;
      const diff = element.scrollHeight - element.scrollTop;
      const { clientHeight } = element;
      const isAtBottom = diff >= clientHeight - 2 && diff <= clientHeight + 2;
      if (isAtBottom) {
        setFirstPage(false);
        onScrollBottom();
      }
    }
  };

  const onListTop = (): void => {
    if (scrollBarContainerRef && scrollBarContainerRef.current) {
      isFetching.current = true;
      const { current } = scrollBarContainerRef;
      setOldScrollTop(current.scrollTop);
      setOldScrollHeight(current.scrollHeight);
      setFirstPage(false);
      onScrollTop();
    }
  };
 
  const handleLinkClick = (decoratedHref: string ):any =>{
    if(localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL' || localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY') {
      GetLink(decoratedHref);
    }else{
      window.open(decoratedHref);
    }
  }

  function linkDecorator(decoratedHref: string, decoratedText: string, key: number,): ReactNode {
    return (
      <Link
        href={decoratedHref}
        target="_blank"
        onClick={(): any => handleLinkClick(decoratedHref)}
        className={`${classes.link}`} //${direction}
        key={key}>
        {decoratedText}
      </Link>
    );
  }
  function linkDecoratorReciever(decoratedHref: string, decoratedText: string, key: number,): ReactNode {
    return (
      <Link
        href={decoratedHref}
        target="_blank"
        onClick={(): any => handleLinkClick(decoratedHref)}
        className={`${classes.linkLeft}`} //${direction}
        key={key}>
        {decoratedText}
      </Link>
    );
  }

  function linkDecoratorWhite(decoratedHref: string, decoratedText: string, key: number): ReactNode {
    return (
      <Link
        href={decoratedHref}
        target="_blank"
        onClick={(): any => handleLinkClick(decoratedHref)}
        className={`${classes.broadcastLink} `} //${direction}
        key={key}>
        {decoratedText}
      </Link>
    );
  }

  function getVideoCallLink(conversationId: number,) {
    MessagingAPI.getVideoCallUrl(conversationId, user.crmUserId).then(res => {
      if (localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL' || localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY' ) {
        GetVideoStream(res);
      }
      else{
        window.open(res, '_blank');
      }
    });;
  }

  function ParsePayment(body: string, returnKey: string) {
    try {
      const obj = JSON.parse(body);
      let r = obj[returnKey];
      if (returnKey == 'Status') {
        if (r == undefined) r = "";
      }
      return r;
    }
    catch (err) {
      return body;
    }
  }

  function ParseUrlShortner(body: string, key: string) {
    const obj = JSON.parse(body);
    let val: string = obj["urlShortner"];
    val = val.replace("https://trgt.io/", "")
    setTextToPaySearch(val);
  }

  function GetPaymentBody(message: Message){
    if(!message) alert(`Invalid Data!\n${JSON.stringify(message)}`);
    const PaymentData:GravityPaymentDetails ={
      conversationId:message.conversationId,
      dateCreated:(message.dateCreated ?? message.dateTime?.split('T')[0]) ,
      id:message.id,
      messageSid:(message.messageSid ?? ''),
      status:message.status,
      messageBody:JSON.parse(message.body)
    }
    setPaymentDetials(PaymentData);
  }
  
  function HandlePaymentCancel(payment: GravityPaymentDetails){
    const data:GravityPaymentCancelOrVoid = {
      conversationId:payment.conversationId,
      conversationMessageId:payment.id,
      paymentPageId:payment.messageBody.paymentPageId,
      transactionReferenceId:payment.messageBody.Id,
      messageSid:payment.messageSid
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to cancel this payment!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText:'Close',
      width: '21em',
      customClass: { popup: `${classes.mySwalPopup}` },
    }).then(async (result) => {
      if (result.isConfirmed) {
      setCancelPaymentLoader(true);
      const response = await MessagingAPI.cancelGravityPayment(data);
      if(response){
        const messageIndex = messagesList.findIndex(x=>x.id === data.conversationMessageId && x.body.includes(data.paymentPageId));
        const message:Message = messagesList[messageIndex];
        if (message && message.body) {
          let messageBody:GravityPaymentMessageBody = (JSON.parse(message.body) as GravityPaymentMessageBody);
          if(message.messageType != 'PaymentCanceled' && messageBody.paymentStatus.toLowerCase() !== 'canceled'){
            messageBody.paymentStatus='Canceled'
            messagesList[messageIndex] = {...messagesList[messageIndex],messageType:'PaymentCanceled',body:JSON.stringify(messageBody)}
          }
        }
        Swal.fire({
          title: 'Canceled!',
          text: 'Your payment has been canceled.',
          icon: 'success',
          customClass: { popup: `${classes.mySwalPopup}` },
          })
          setPaymentDetials(null);
          setCancelPaymentLoader(false);
        }else{
          Swal.fire({
            title: 'Error!',
            text: 'Error occured while canceling.',
            icon: 'error',
            customClass: { popup: `${classes.mySwalPopup}` },
          });          
          setCancelPaymentLoader(false);
        }
      }  
    })
  } 

  return (
    <ImageModalContext.Provider value={{ openImageModal: (imgSrc: string): void => setImageSrc(imgSrc) }}>
      <ScrollBar
        component="div"
        ref={scrollBarRef}
        options={{
          minScrollbarLength: 0,
        }}
        containerRef={(ref: HTMLElement): void => {
          scrollBarContainerRef.current = ref;
        }}
        onYReachStart={onListTop}
        onYReachEnd={onListEnd}>
        {messagesList.map((message, index) => {

          const direction = message.direction === 'outbound-api' ? 'right' : 'left';
          let flexDirection = direction === 'right' ? 'flex-end' : 'flex-start';
          let hideBody = message.body === '' && message.mediaUrls && message.mediaUrls.length > 0 ? false : true;
          const isSurveyOrBroadcastMessage = ['Survey', 'Broadcast'].indexOf(message.messageType) > -1;
          const isLeadWidget = message.originType === 'Widget';
          const hasHeader = isSurveyOrBroadcastMessage || isLeadWidget;
          const title = isSurveyOrBroadcastMessage ? message.messageType : 'Website Lead';
          const additionalBgColor = hasHeader ? title.toLowerCase().replace(' ', '') : '';
          const isMergeMessage = 'MergedText' == message.messageType?true:false;
          // Archive Conversation is merged to chat by Tomi on 12/22/20220
            return (
                <>
                {isMergeMessage &&
                <MergeTag
                body={message.body}
                mergedUserName={message.mergedUserName}
                dateMerged={message.dateMerged}
                />
                }
            {!isMergeMessage &&
                        <Box display="flex" flexGrow={1} key={index} justifyContent={flexDirection}>
                            <Box maxWidth="50%">
                                {hideBody && (
                                    <>
                                        {
                                            (message.messageType != "VideoCall" && message.messageType != 'PaymentSent' && message.messageType != 'PaymentReceived' && message.messageType != 'PaymentCanceled' && message.messageType != 'PaymentVoided') &&
                                            <Box my={1} className={`${classes.root} ${direction} ${additionalBgColor} ${message.messageSid === messageSid && highlightClass ? 'highlightedMessage' : ''} `}>
                                                {hasHeader ? (
                                                    <Typography variant="h5" className={classes.header}>
                                                        {title}
                                                    </Typography>
                                                ) : null}
                                                <Typography variant="body1">
                                                    {(message.messageSid === messageSid) &&
                                                        <div ref={myRef} >
                                                            {
                                                                message.messageType == "Broadcast" ?
                                                                    <Linkify componentDecorator={linkDecoratorWhite}>{message.body}</Linkify>
                                                                    :
                                                                    <Linkify componentDecorator={linkDecorator}>{message.body}</Linkify>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        !(message.messageSid === messageSid) &&
                                                        <>
                                                            {
                                                                message.messageType === "Broadcast" ?
                                                                    <Linkify componentDecorator={linkDecoratorWhite}>{message.body}</Linkify>
                                                                    : direction !== 'right' ?
                                                                        <Linkify componentDecorator={linkDecoratorReciever}>{message.body}</Linkify>
                                                                        :
                                                                        <Linkify componentDecorator={linkDecorator}>{message.body}</Linkify>
                                                            }
                                                        </>
                                                    }
                                                </Typography>
                                            </Box>
                                        }
                                        {
                                            message.messageType == "VideoCall" &&
                                            <Box my={1} className={`${classes.root} ${direction}`} style={{ background: "#6E6E6E", borderRadius: "10px", padding: "10px" }}>
                                                <Grid container>
                                                    <Grid item><VideocamIcon /> </Grid>
                                                    <Grid item>
                                                        <Typography variant="h4" className={classes.header}>Video Call</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" className={classes.header}>Video call has started</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button variant="contained" style={{ background: "#1976D3", color: "white" }} onClick={() => { getVideoCallLink(message.conversationId) }}>Join  Call</Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                        {message.messageType == 'PaymentSent' && (
                                            <Box
                                                style={{ backgroundColor: "#4692f8", color: "white", padding: "7px 15px", marginRight: "20px", marginTop: "10px", borderRadius: "15px", minWidth: "150px" }}
                                                onClick={() => GetPaymentBody(message)}
                                            >
                                                <Typography variant="h5" className={classes.header}>
                                                    Payment
                                                </Typography>
                                                <Typography variant="body1">
                                                    <Linkify componentDecorator={linkDecorator}>Amount: {dollarUSLocale.format(ParsePayment(message.body, 'amount'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Memo: {ParsePayment(message.body, 'memo')}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Status: {ParsePayment(message.body, 'paymentStatus') ?? 'N/A'} </Linkify><br></br>
                                                </Typography>
                                            </Box>
                                        )}
                                        {message.messageType == 'PaymentReceived' && (
                                            <Box
                                                style={{ backgroundColor: Number(ParsePayment(message.body, 'amountReceived')) < Number(ParsePayment(message.body, 'amount')) ? "#d6ac02" : "green", color: "white", padding: "7px 15px", marginRight: "20px", marginTop: "10px", borderRadius: "15px", minWidth: "150px" }}
                                            // onClick={() => GetPaymentBody(message)}
                                            >
                                                <Typography variant="h5" className={classes.header}>
                                                    Payment {ParsePayment(message.body, 'status')}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <Linkify componentDecorator={linkDecorator}>Amount: {dollarUSLocale.format(ParsePayment(message.body, 'amount'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Amount Received: {dollarUSLocale.format(ParsePayment(message.body, 'amountReceived'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Memo: {ParsePayment(message.body, 'memo')}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Status: {(ParsePayment(message.body, 'paymentStatus')).replace('C', ' C') ?? 'N/A'} </Linkify><br></br>
                                                </Typography>
                                            </Box>
                                        )}
                                        {message.messageType == 'PaymentVoided' && (
                                            <Box
                                                style={{ backgroundColor: "#5b175d", color: "white", padding: "7px 15px", marginRight: "20px", marginTop: "10px", borderRadius: "15px", minWidth: "150px" }}
                                            >
                                                <Typography variant="h5" className={classes.header}>
                                                    Payment {ParsePayment(message.body, 'status')}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <Linkify componentDecorator={linkDecorator}>Amount: {dollarUSLocale.format(ParsePayment(message.body, 'amount'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Amount Received: {dollarUSLocale.format(ParsePayment(message.body, 'amountReceived'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Memo: {ParsePayment(message.body, 'memo')}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Status: {(ParsePayment(message.body, 'paymentStatus')).replace('C', ' C') ?? 'N/A'} </Linkify><br></br>
                                                </Typography>
                                            </Box>
                                        )}
                                        {message.messageType == 'PaymentCanceled' && (
                                            <Box
                                                style={{ backgroundColor: "red", color: "white", padding: "7px 15px", marginRight: "20px", marginTop: "10px", borderRadius: "15px", minWidth: "150px" }}
                                            >
                                                <Typography variant="h5" className={classes.header}>
                                                    Payment {ParsePayment(message.body, 'status')}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <Linkify componentDecorator={linkDecorator}>Amount: {dollarUSLocale.format(ParsePayment(message.body, 'amount'))}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Memo: {ParsePayment(message.body, 'memo')}</Linkify><br></br>
                                                    <Linkify componentDecorator={linkDecorator}>Status: {ParsePayment(message.body, 'paymentStatus') ?? 'N/A'} </Linkify><br></br>
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                )}
                                <Box mr={1} mt={1} flexDirection="column" display="flex">
                                    {message.mediaUrls &&
                                        message.mediaUrls.map((mediaUrl: Media, i: number) => <MessageMedia key={i} mediaSrc={mediaUrl} />)}
                                </Box>
                                <Footer status={message.status} dateTime={message.dateCreated ?? message.dateTime} userName={message.userName} direction={direction} />
                            </Box>
                        </Box>
                    }
                        </>
          )

        })}
        <ImageModal open={imageSrc !== undefined} imageSrc={imageSrc} onClose={(): void => setImageSrc(undefined)} />
        <Drawer
          PaperProps={{ style: { width: '400px', minWidth: "25%" } }}
          anchor="right"
          open={paymentDetails != null}
          onClose={() => setPaymentDetials(null)}
          variant={'temporary'}
        >
          <Grid container>
            <Grid item xs={12}>
            <Toolbar variant='dense'>
              <Box flexGrow={1}>
                <Typography variant="h4">
                  GRAVITY PAYMENTS
                </Typography>
              </Box>
              <IconButton id="closeBtn" onClick={() => setPaymentDetials(null)}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <Divider variant='fullWidth'/>
            </Grid>
            <Grid container item xs={12} style={{textAlign:'center', justifyContent:'center'}}>
              <Box mt={5} mr={5} ml={5} flex justifyContent='center'>
                <Typography variant='h4'>PAYMENT DETAILS</Typography>
                <Box mt={2} mb={2} border={1} flex flexWrap justifyContent={'center'} width={'380px'} borderRadius={5}>
                  <Box p={1} mb={2}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper'}}>
                      <Typography variant='h5'>Memo:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper' }}>
                      <Typography variant='h5'>{capitalize(paymentDetails?.messageBody.memo ?? '')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper'}}>
                      <Typography variant='h5'>Amount:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper' }}>
                      <Typography variant='h5'>{dollarUSLocale.format((parseFloat((paymentDetails?.messageBody.amount ?? '0'))))}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper'}}>
                      <Typography variant='h5'>Payment Url:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper' }}>
                      <Link href={paymentDetails?.messageBody.urlShortner} style={{color:'mediumBlue'}} onClick={(): any => window.open(paymentDetails?.messageBody.urlShortner)}  target="_blank" rel="noopener">{paymentDetails?.messageBody.urlShortner}</Link>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper'}}>
                      <Typography variant='h5'>Date Created:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper' }}>
                      <Typography variant='h5'>{(paymentDetails?.dateCreated.split('T')[0] ?? 'N/A')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper'}}>
                      <Typography variant='h5'>Status:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box sx={{ bgcolor: 'background.paper' }}>
                      <Typography variant='h5'>{capitalize(paymentDetails?.messageBody.paymentStatus ?? 'N/A')}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  </Box>
                  <Button 
                    onClick={()=>{paymentDetails && HandlePaymentCancel(paymentDetails)}} 
                    size='small' style={{marginBottom:'10px',width:'135px', height:'30px'}} 
                    variant='contained' 
                    color='secondary'>{cancelPaymentLoader ? <CircularProgress size={20} color='inherit'/> : 'Cancel Payment'}</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Drawer>
      </ScrollBar>
    </ImageModalContext.Provider>
  );
});

export default MessagesList;