import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField, Typography
} from '@material-ui/core';
import { CustomerNameObject } from 'common/models/customer';
import { useFormik } from 'formik';
import React, { ReactElement, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { useAppSelector } from 'app';
import PersonIcon from '@mui/icons-material/Person';
import InitialsAvatar from 'components/messaging/components/initials-avatar';
import CustomerAPI from 'components/customer/api/CustomerAPI';
import AppSnackBar from 'components/snackbar/snackbar';
import { useStyles } from './CustomerForm';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';

export function CustomerNameComponent({
  customerNameObject, dmsTypeId, crmCustomerId, formikErrors, onFormSubmit, SetisCustomerNumberUnique,SetNameObject,
}: {
  customerNameObject: CustomerNameObject | undefined;
  dmsTypeId: number;
  crmCustomerId: number;
  formikErrors: any;
  onFormSubmit(customerName: CustomerNameObject): void;
  SetisCustomerNumberUnique(value: boolean): void;
  SetNameObject (customerName: CustomerNameObject): void;
}): ReactElement {
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [showSpinner, setShowSpinner] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  let initialValues: CustomerNameObject = {
    dateOfBirth: new Date(),
    customerNumber: null,
    firstName: '',
    middleName: '',
    lastName: '',
    name: '',
    jobTitle: '',
    contactId: -1,
  };
  if (customerNameObject) {
    initialValues = { ...customerNameObject };
  }
  

  const formik = useFormik({
    initialValues,
    onSubmit: (values: CustomerNameObject) => {
      if(dmsTypeId ===9 && values.customerNumber.length <=0 ){
        formik.setFieldError('customerNumber','Customer id is required')
      }
      const sanitizedValues = { ...values };
      onFormSubmit(sanitizedValues);
    },
  });
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose(){

    SetNameObject(formik.values);
    formik.handleSubmit();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  async function checkCustomerNumberExists(value:any) {
    const customerNumber = value;
    if (customerNumber && customerNumber.length > 0) {
      setShowSpinner(true);
      const req = {
        crmCustomerId: crmCustomerId,
        customerNumber: customerNumber.toString(),
      };
      const customerNumberExists = await CustomerAPI.checkCustomerNumberExists(req);
      if (customerNumberExists) {
        SetisCustomerNumberUnique(false);
        //showSnackBar({ type: 'error', message: 'Customer ID should be unique' });
        formik.setFieldError('customerNumber', `'${customerNumber}' is not available`)
      } else {
        SetisCustomerNumberUnique(true);
        //showSnackBar({ type: 'success', message: 'Customer ID available' });
      }
      setShowSpinner(false);
    }
  }

  const GetCustomerName = () => {
    let name = formik.values.firstName;
    if (formik.values.middleName) {
      name += ' ' + formik.values.middleName;
    }
    if (formik.values.lastName) {
      name += ' ' + formik.values.lastName;
    }
    // if (formik.values.customerNumber) {
    //   name += ' (' + formik.values.customerNumber + ') ';
    // }
    if (dmsTypeId === 1) {
      name = formik.values.name;
    }
    if (!name) {
      name = '';
    }

    return name;
  };
  const name = GetCustomerName();
  const classes: any = useStyles();
  return (
    <>
      <Box mt={1}>
        <Grid container spacing={0}>
          <Grid item>
            <Typography variant="body1">
              {name && (
                <>
                  <InitialsAvatar
                    name={name?.replace('(', '').replace(')', '')}
                    maxInitials={2}
                    textSizeRatio={1.75}
                    size="30"
                    round
                  />{' '}
                  {name}
                </>
              )}
              {!name && (
                <>
                  <Grid container>
                    <Grid item>
                      <PersonIcon style={{ marginTop: '-2px', marginRight: '5px' }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="h5" style={{ color: 'rgb(152 152 152)' }}>
                        Enter Customer Name
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-describedby={id} className={classes.iconButton} onClick={(e: any) => handleClick(e)}>
              <EditIcon style={{ fontSize: '1rem' }} />
            </IconButton>
          </Grid>
          {/* {(!!formikErrors.customerContact?.firstName || !!formikErrors.customerContact?.lastName) && (
            <span className={classes.errorMsg}>Customer First and Last Name Required</span>
          )}
          {!!formikErrors.customerContact?.name && <span className={classes.errorMsg}>Customer Name Required</span>} */}
        </Grid>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          <Grid container justifyContent="space-between" spacing={2}>
            {dmsTypeId === 1 && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  id="name"
                  style={{ padding: '2px' }}
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  error={!!formik.errors.name}
                  helperText={formik.errors.name}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
            {dmsTypeId !== 1 && (
              <>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="firstName"
                    style={{ padding: '2px' }}
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    // error={!!formik.errors.firstName}
                    // helperText={formik.errors.firstName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="middleName"
                    style={{ padding: '2px' }}
                    name="middleName"
                    label="Middle Name"
                    value={formik.values.middleName}
                    // error={!!formik.errors.middleName}
                    // helperText={formik.errors.middleName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    id="lastName"
                    style={{ padding: '2px' }}
                    name="lastName"
                    label="Last Name"
                    value={formik.values.lastName}
                    // error={!!formik.errors.lastName}
                    // helperText={formik.errors.lastName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  {dmsTypeId === 9 && (
                    <>
                      {showSpinner ? (
                        <LinearProgress style={{ marginTop: '10px' }} />
                      ) : (
                        <TextField
                          fullWidth
                          onBlur={() => {
                            if (formik.values.customerNumber?.length <= 15) {
                              checkCustomerNumberExists(formik.values.customerNumber);
                            } else {
                              formik.setFieldError('customerNumber', 'Max 15 characters allowed.');
                            }
                          }}
                          variant="outlined"
                          margin="dense"
                          id="CustomerId"
                          style={{ padding: '2px', marginTop: '-3px' }}
                          name="customerNumber"
                          label="Customer ID"
                          value={formik.values.customerNumber}
                          error={!!formik.errors.customerNumber}
                          helperText={formik.errors.customerNumber}
                          onChange={(e: any) => {
                            formik.setFieldValue('customerNumber', e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Button
                fullWidth
                id="saveCustomerNameBtn"
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => {
                  handleClose();
                }}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
