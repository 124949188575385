import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'app';

import useLocations from 'common/hooks/useLocations';
import formatPhoneNumber from 'common/utils/format-phoneNumber';
import DepartmentFilterContext from 'components/layouts/DashboardLayout/department-filter-context';
import DepartmentAPI from 'components/setting/api/department-api';
import Department from 'components/setting/models/department';
import AppSnackbar from 'components/snackbar/snackbar';

import EditDepartmentDrawer from './edit-department-drawer';
import NewDepartmentDrawer from './new-department-drawer';
import { SmallCircularProgress } from 'common/components/loaders';
import PhoneRegistrationDrawer from '../twilio/phone-registration-drawer';
import FacebookLogin from '../FacebookLogin/FacebookLogin';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Console } from 'console';
import { blue } from '@material-ui/core/colors';
const useStyles = makeStyles(() => ({
  departmentName: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

const Departments: React.FC = () => {
  const classes: any = useStyles();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { fetchUserDepartmentSettings } = React.useContext(DepartmentFilterContext);
  const [loading, setLoading] = React.useState(false);
  const [showSnackbar] = AppSnackbar.useSnackBar();
  const [departments, setDepartments] = useState<Department[] | undefined>();
  const [showAddDepartment, setShowAddDepartment] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<number | undefined>();
  const { loading: locationLoading, defaultLocationOption, locationOptions } = useLocations(dmsDealerIdCrm);
  const [openTwilioDrawer, setOpenTwilioDrawer] = React.useState<boolean>(false);
  const [isProfileTwilioApproved, setProfileStatus] = useState<boolean>(true);
  const [IsfacebookExpired, setIsfacebookExpired] = useState<boolean>(false);
  const [refresh, setrefresh] = useState('refresh Data');
  const [dealerLocationId, setDealerLocationId] = React.useState<number | undefined>(
    defaultLocationOption?.dealerLocationId,
  );

  React.useEffect(() => {
    setDealerLocationId(defaultLocationOption?.dealerLocationId);
  }, [defaultLocationOption]);
  const refreshParent = () => {
    setrefresh('Updated Data');
  };
  const fetchDepartments = useCallback(async (): Promise<void> => {
    if (dealerLocationId !== undefined && dealerLocationId >= 0) {
      try {
        setLoading(true);
        const response = await DepartmentAPI.getDepartments(
          dealerLocationId,
          dealerLocationId !== 0 ? 0 : dmsDealerIdCrm,
        );
        setDepartments(response);
        setLoading(false);        
        let filteredArray = response?.filter(department => department.departmentType === 'Facebook');
        setIsfacebookExpired(filteredArray.length==0||(filteredArray.some(d =>d.isFacebookTokenExpired == true)))
      } catch (error) {
        setLoading(false);
        showSnackbar({ type: 'error', message: 'Unable to fetch departments' });
      }
    }
  }, [dealerLocationId, dmsDealerIdCrm, showSnackbar]);

  useEffect(() => {
    fetchDepartments();
  }, [fetchDepartments ,refresh]);
  useEffect(() => {
    fetchDepartments();
    fetchUserDepartmentSettings();
  }, [refresh]);

  function openAddDepartmentDrawer(): void {
    setShowAddDepartment(true);
  }

  function closeAddDepartmentDrawer(): void {
    setShowAddDepartment(false);
  }

  async function handleCreateDepartment(createDepartmentFormValue: any, createMore: boolean): Promise<void> {
    try {
      const { departmentName, dealerLocationId, users } = createDepartmentFormValue;
      const crmUsersIds = users.map((user: any) => user.crmUserId);
      const response = await DepartmentAPI.createDepartment(
        dmsDealerIdCrm,
        departmentName,
        dealerLocationId,
        crmUsersIds,
      );
      if (!response.departmentId) throw new Error('Unable to create department');
      showSnackbar({ type: 'success', message: 'Department created' });
      if (!createMore) {
        setShowAddDepartment(false);
        fetchDepartments();
        fetchUserDepartmentSettings();
      }
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to create department' });
      throw new Error('Unable to create department');
    }
  }

  async function handleSaveDepartment(editDepartmentFormValue: any): Promise<void> {
    try {
      const { departmentId, departmentName, dealerLocationId, users } = editDepartmentFormValue;
      const crmUsersIds = users.map((user: any) => user.crmUserId);
      await DepartmentAPI.updateDepartment(dmsDealerIdCrm, departmentId, departmentName, crmUsersIds, dealerLocationId);
      showSnackbar({ type: 'success', message: 'Department updated' });
      setSelectedDepartmentId(undefined);
      fetchDepartments();
      fetchUserDepartmentSettings();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to save department' });
    }
  }

  async function deleteDepartment(departmentId: number): Promise<void> {
    try {
      await DepartmentAPI.deleteDepartment(dmsDealerIdCrm, departmentId);
      showSnackbar({ type: 'success', message: 'Department deleted' });
      fetchDepartments();
      fetchUserDepartmentSettings();
    } catch (error) {
      showSnackbar({ type: 'error', message: 'Unable to delete department' });
    }
  }

  function onEditDepartment(department: Department): void {
    setSelectedDepartmentId(department.departmentId);
  }

  function updateDeleteConfirmation(departmentId: number, status: boolean) {
    setDepartments(
      departments?.map((x) => {
        if (x.departmentId === departmentId) {
          x.deleteConfirmation = status;
        }
        return x;
      }),
    );
  }

  // Callback function to receive the data from PhoneRegistrationDrawer
  const handlePhoneRegistrationData = (status: boolean) => {
    setProfileStatus(status);
  };
  return (
    <>
      <Paper square style={{ padding: '10px' }}>
        <Box mb={1} display="flex">
          <Box>
            <Button onClick={openAddDepartmentDrawer} startIcon={<AddCircleIcon />} color="primary">
              Add Department
            </Button>
          </Box>
          <Box mx={1}>
            {!isProfileTwilioApproved ? (
              <Badge badgeContent={1} color="secondary">
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                  onClick={() => {
                    setOpenTwilioDrawer(true);
                  }}>
                  Phone Registration
                </Button>
              </Badge>
            ) : (
              <Button
                color="primary"
                variant="contained"
                size="small"
                type="submit"
                onClick={() => {
                  setOpenTwilioDrawer(true);
                }}>
                Phone Registration
              </Button>
            )}
          </Box>
          <Box mx={1}>
            {/* {IsfacebookExpired?<FacebookLogin onRefresh={refreshParent}/>:<></>} */}
            <FacebookLogin onRefresh={refreshParent}/>
                       
          </Box>
          <Box flexGrow={1} />
          <Box>
            <TextField
              select
              size="small"
              margin="dense"
              variant="outlined"
              name="location"
              label="Location"
              value={dealerLocationId}
              disabled={locationLoading}
              inputProps={{ id: 'location' }}
              onChange={({ target: { value } }): void => setDealerLocationId(parseInt(value, 10))}>
              {locationOptions &&
                locationOptions.map((loc) => (
                  <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                    {loc.name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
        </Box>
        <Box>
          {loading ? (
            <Box height="500px" margin="auto" display="flex" justifyContent="center">
              <SmallCircularProgress />
            </Box>
          ) : (
            <TableContainer style={{ height: '500px', overflowY: 'auto' }}>
              <Table stickyHeader id="departments-table" aria-label="Departments table">
                <TableHead>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Users</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departments?.map((department) => (
                    <TableRow key={department.departmentId}>
                      {department.departmentType=='Facebook'?<>
                        <TableCell className={classes.departmentName} onClick={(): void => onEditDepartment(department)}>
                        <span style={{"float": "inline-end"}}><FacebookIcon style={{'color':'blue'}}/></span><span> {department.departmentName}</span>
                      </TableCell>
                      </>:
                      <TableCell className={classes.departmentName} onClick={(): void => onEditDepartment(department)}>
                      {department.departmentName}
                    </TableCell>
                      }
                      
                      <TableCell>{department.dealerLocationName}</TableCell>
                      {department.departmentType=='Facebook'?<div></div>
                      :
                      <TableCell>
                        {department.isTollFreeNumber ? (
                          <b>{formatPhoneNumber(department.phoneNumber)}</b>
                        ) : (
                          formatPhoneNumber(department.phoneNumber)
                        )}
                      </TableCell>
                      }
                      
                      <TableCell>{department.userCount}</TableCell>
                      <TableCell>
                        {department.departmentType !== 'Marketing' && (
                          <Tooltip title="Delete department">
                            <>
                              {!department.deleteConfirmation && (
                                <IconButton onClick={() => updateDeleteConfirmation(department.departmentId, true)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                              {department.deleteConfirmation && (
                                <>
                                  <Typography variant="body1">Are you sure ?</Typography>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <ButtonGroup>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={(): Promise<void> => deleteDepartment(department.departmentId)}
                                          style={{ marginRight: '10px' }}
                                          color="primary">
                                          Yes
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          onClick={() => updateDeleteConfirmation(department.departmentId, false)}
                                          color="secondary">
                                          No
                                        </Button>
                                      </ButtonGroup>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
      <NewDepartmentDrawer
        locations={locationOptions?.filter((loc) => loc.dealerLocationId > 0) || []}
        open={showAddDepartment}
        onCreate={handleCreateDepartment}
        onClose={closeAddDepartmentDrawer}
      />
      <EditDepartmentDrawer
        departmentId={selectedDepartmentId}
        locations={locationOptions?.filter((loc) => loc.dealerLocationId > 0) || []}
        open={!!selectedDepartmentId}
        onSave={handleSaveDepartment}
        onDelete={deleteDepartment}
        onClose={(): void => setSelectedDepartmentId(undefined)}
      />
      <PhoneRegistrationDrawer
        open={openTwilioDrawer}
        onClose={() => {
          setOpenTwilioDrawer(false);
        }}
        onDataLoad={handlePhoneRegistrationData}
      />
    </>
  );
};

export default Departments;
