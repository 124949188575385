import { Box, Button, Chip, Container, makeStyles, Paper, TextField, Input, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import { SmallCircularProgress } from 'common/components/loaders';
import { PageHeader } from 'common/components/layout';
import { useRouter } from 'common/hooks';
import CustomerGroupAPI from 'components/customer-groups/api/customer-group-api';
import { CustomerCategory } from 'components/customer-groups/models/customer-category';
import CustomerGroupModel from 'components/customer-groups/models/customer-group';
import { Manufacturer } from 'components/customer-groups/models/manufacturer';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app';

import * as Yup from 'yup';
import Customer from '../../models/customer';
import { deleteFilter, FilterItem, parseSegment } from '../../models/filter';
import AddFilterChip from './add-filter-chip';
import CustomerTable from './customers-table';
import FilterChip from './filter-chip';
import FilterDrawer from './filter-drawer';
import { debounce } from 'lodash';
import { RestaurantMenu } from '@material-ui/icons';
import axios from 'axios';
import config from 'config';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import { showSnackbar } from 'components/root-snackbar';
import useLocations from 'common/hooks/useLocations';
const useStyles = makeStyles((theme: Theme): any => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      width: `80%`,
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  paperContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },

  actionFabButtons: {
    position: 'absolute',
    top: '17px',
    right: '-46px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  actionFab: {
    margin: theme.spacing(1, 0),
  },
  exportToCSV: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(0, 0),
    fontSize: '11px',
    backgroundColor: '#F8F9F9',
    border: '1px solid silver',

  },

  exportToCSVLabel: {
    margin: theme.spacing(0, 0),
    padding: theme.spacing(.2, 1),
    fontSize: '11px',
    color: 'Gray'
  },


}));

function useManufacturer(): any {
  const [manufacturers, setManufacturers] = React.useState<Manufacturer[]>([]);
  const [manufacturerMap, setManufacturerMap] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  function getManufacturerCode(id: string): string {
    return manufacturerMap[id];
  }

  React.useEffect(() => {
    async function fetchManufacturers(): Promise<void> {
      try {
        setLoading(true);
        const response = await CustomerGroupAPI.getManufacturers();
        const map = response.reduce(
          (acc, manufacturer) => ({ ...acc, [manufacturer.manufacturerId]: manufacturer.code }),
          {},
        );

        setManufacturerMap(map);
        setLoading(false);
        setManufacturers(response);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchManufacturers();
  }, []);

  return { manufacturers, loading, getManufacturerCode };
}



function useCustomerCategories(): any {
  const [customerCategories, setCustomerCategories] = React.useState<CustomerCategory[]>([]);
  const [customerCategoriesMap, setCustomerCategoriesMap] = React.useState<any>({});

  const [loading, setLoading] = React.useState<boolean>(false);

  function getCustomerCategoryName(id: number): string {
    return customerCategoriesMap[id];
  }
  React.useEffect(() => {
    async function fetchCustomerCategories(): Promise<void> {
      try {
        setLoading(true);
        const response = await CustomerGroupAPI.getCustomerCategories();
        setLoading(false);
        const map = response.reduce((acc, category) => ({ ...acc, [category.customerCategoryId]: category.name }), {});
        setCustomerCategoriesMap(map);
        setCustomerCategories(response);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchCustomerCategories();
  }, []);
  return { loading, customerCategories, getCustomerCategoryName };
}

let mySearchTimeOut: any;
let segmentDealerId: any = 0;
let segmentId: number = -1;
let isReadOnly: boolean = false;
let forAllLocation: any = 0;
export default function CustomerGroup(): React.ReactElement {
  const router = useRouter();
  const classes: any = useStyles();
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const pageSize = 10;
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState<boolean>(false);
  const dealerId = useAppSelector((state) => state.user.dealerId);
  const [customerGroup, setCustomerGroup] = React.useState<CustomerGroupModel | undefined>(undefined);
  const [customersCount, setCustomersCount] = React.useState<number>(0);
  const [loadingCustomerGroup, setLoadingCustomerGroup] = React.useState<boolean>(false);
  const [loadingCustomers, setLoadingCustomers] = React.useState<boolean>(false);
  const [savingCustomerGroup, setSavingCustomerGroup] = React.useState<boolean>(false);
  const [isDownLoadingCsv, setIsDownLoadingCsv] = React.useState<boolean>(false);

  const { manufacturers, getManufacturerCode } = useManufacturer();
  const { customerCategories, getCustomerCategoryName } = useCustomerCategories();
  const [searchSegment, SetSearchSegment] = React.useState<string>("");
  const [EmailOrSms, SetEmailOrSms] = React.useState<string>("S");
  const dispatch = useAppDispatch();
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { locations } = useLocations(dmsDealerIdCrm);

  useEffect(() => {
    // alert('EmailOrSms');
    setPageNumber(1);
    RefreshData();
  }, [EmailOrSms]);

  const initialCustomerGroup = {
    segmentId: -1,
    locations: [],
    segmentName: '',
    dealerId,
    forAllLocation: 1,
    period: 1,
    periodValue: 6,
    invoiceType: 2,
    createdByUser: true,
    invoiceTypes: [{value: 2, label: 'Service Only'}, {value: 3, label: 'Parts Only'}, {value: 4, label: 'Equipment Only'}, {value: 5, label: 'No Invoice'}]
  };
  const schema = Yup.object<any>().shape<any>({
    segmentName: Yup.string().required('Segment Name is required'),
    segmentDescription: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      segmentName: customerGroup?.segmentName || '',
      segmentDescription: customerGroup?.segmentDescription || '',
      segmentSearch: '',
    },
    onSubmit: handleSubmit,
    validationSchema: schema,
    enableReinitialize: true,
  });

  async function updateCustomerGroup(request: CustomerGroupModel): Promise<number> {
    segmentDealerId = request.dealerId;
    setSavingCustomerGroup(true);
    try {
      const customerGroupId = await CustomerGroupAPI.createCustomerGroup(request);
      setSavingCustomerGroup(false);
      return customerGroupId;
    } catch (error) {
      setSavingCustomerGroup(false);
      return 0;
    }
  }


  async function handleSubmit(): Promise<void> {
    try {
      if (customerGroup) {
        const request: CustomerGroupModel = {
          ...customerGroup,
          segmentId: customerGroup?.segmentId < 0 ? 0 : customerGroup?.segmentId,
          segmentName: formik.values.segmentName,
          segmentDescription: formik.values.segmentDescription,
        };
        const customerGroupId = await updateCustomerGroup(request);
        // if (request.segmentId === 0) {
        if (customerGroupId>0) {
          router.push(`/customer-groups/${customerGroupId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  
  async function getCustomerGroup(segmentId: number): Promise<void> {
    setLoadingCustomerGroup(true);
    const response = await CustomerGroupAPI.getCustomerGroup(segmentId);
    isReadOnly = response?.createdByUser === false;
    if (isReadOnly) {
      if (response?.dealerId == null) response.dealerId = -1;
      response.forAllLocation = 1;
      forAllLocation = 1;
    }

    setCustomerGroup(response);
    segmentDealerId = response.dealerId
    forAllLocation = response.forAllLocation;
    setLoadingCustomerGroup(false);

    //alert(isReadOnly);

  }

  async function DownloadCustomerGroup(){
    setIsDownLoadingCsv(true);
    const response = await CustomerGroupAPI.getSegmentCustomers(GetSegmentId(), 1, 100000, forAllLocation, searchSegment, segmentDealerId, EmailOrSms, true);
    setIsDownLoadingCsv(false);
    if (typeof (response) == 'undefined' || response == null || response=='') { return ''; }
    window.location.href = response;
  }

  async function updateCustomers(segmentId: number, page: number, size: number, customerSearch?: string): Promise<void> {
    try {
    if (isNaN(segmentId)) return;
    
    if (segmentId === -1  && !isNaN(GetSegmentId()))
      {
      segmentId = GetSegmentId();
   }
    setLoadingCustomers(true);
    if (customerSearch == undefined) { customerSearch = searchSegment; }
    if (customerSearch == undefined) { customerSearch = ''; }
    if (isReadOnly) forAllLocation = 1;
    console.log('forAllLocation: ' + forAllLocation);
    const response = await CustomerGroupAPI.getSegmentCustomers(segmentId, page, size, forAllLocation, customerSearch, segmentDealerId, EmailOrSms);
    setCustomers(response.customers);
    setCustomersCount(0);
    if (response.customers.length > 0) setCustomersCount(response.customers[0].rowsCount);
    setLoadingCustomers(false);
    if (segmentDealerId == 0) { segmentDealerId = customerGroup?.dealerId; }
    }
    catch (error) {
    setCustomers([])
    setLoadingCustomers(false);
    dispatch(showSnackbar({ type: 'error', message: 'Unable to fetch customers' }));
  }
  }

  function GetSegmentId(): any {
    return Number(customerGroup?.segmentId) < 0 ? segmentId : Number(customerGroup?.segmentId);
  }

  async function onFilterChange(updatedCustomerGroup?: CustomerGroupModel, elUploadFile?: any): Promise<void> {
    try {
      const customerGroupUpdated = updatedCustomerGroup || customerGroup;
      const request: CustomerGroupModel = {
        segmentId: customerGroup?.segmentId || -1,
        segmentName: formik.values.segmentName,
        segmentDescription: formik.values.segmentDescription,
        ...customerGroupUpdated,
      };
      forAllLocation = updatedCustomerGroup?.forAllLocation;
      const previewSegmentId = await updateCustomerGroup(request);
      segmentId = previewSegmentId;
      if (previewSegmentId > 0 && elUploadFile != null && elUploadFile.value != '') {
        let customerUrl = config.get('api.customer');
        let url = `${customerUrl}/UploadSegmentCustomerFile/1/` + + previewSegmentId;
        let formData = new FormData();
        formData.append("file", elUploadFile.files[0]);
        // axios.post('http://localhost:7071/api/UploadSegmentCustomerFile/1/' + previewSegmentId, formData, {
        axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setPageNumber(1);
            updateCustomers(previewSegmentId, 1, pageSize);
            //SetUploadText(res.data)
          })

      }
      else {
        setPageNumber(1);
        await updateCustomers(previewSegmentId, 1, pageSize);
      }


    } catch (error) {
      console.error(error);
    }
  }

  function handleFilterDrawerClose(): void {
    setOpenFilterDrawer(false);
  }

  function handleOpenAddFilterDrawer(): void {
    if (isReadOnly) return;
    setOpenFilterDrawer(true);
  }

  async function handlePageChange(newPage: number): Promise<void> {
    try {
      if (customerGroup) {
        //await updateCustomers(customerGroup?.segmentId, newPage + 1, pageSize);
        await updateCustomers(GetSegmentId(), newPage + 1, pageSize);
        setPageNumber(newPage + 1);
      }
    } catch (error) {
      console.error('Unable to next page', error);
    }
  }

  async function handleAddFilter(data: any, elUploadFile: any): Promise<void> {
    if (customerGroup !== undefined) {
      const updatedCustomerGroup = { ...customerGroup, ...data };
      updatedCustomerGroup.parts = [];
      updatedCustomerGroup.phoneNumber = [];
      if (data.partNumber != null && data.partNumber != '') updatedCustomerGroup.parts = data.partNumber;
      if (data.phoneNumber != null && data.phoneNumber != '') updatedCustomerGroup.phoneNumber = data.phoneNumber.split(",");
    
      setCustomerGroup(updatedCustomerGroup);
      onFilterChange(updatedCustomerGroup, elUploadFile);
      setOpenFilterDrawer(false);
    }
  }

  async function handleDeleteFilter(filterItem: FilterItem): Promise<void> {
    if (customerGroup !== undefined) {
      const updatedCustomerGroup = deleteFilter(customerGroup, filterItem.type);
      setCustomerGroup(updatedCustomerGroup);
      onFilterChange(updatedCustomerGroup);
    }
  }
  async function handleDelete(event: any): Promise<void> {
    try {
      if (customerGroup) {
        await CustomerGroupAPI.deleteCustomerGroup(customerGroup?.segmentId);
        router.push('/customer-groups');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function SearchSegment(search: string) {
    updateCustomers(Number(customerGroup?.segmentId), 1, pageSize, search);
  }

  function RefreshData() {
    // setPageNumber(1);
    updateCustomers(Number(customerGroup?.segmentId), 1, pageSize, searchSegment);
  }

  function HandleSearchChange(e: any) {
    SetSearchSegment(e.target.value);
    clearTimeout(mySearchTimeOut);
    mySearchTimeOut = setTimeout(SearchSegment, 1000, e.target.value);
  }


  React.useEffect(() => {

    async function fetchCustomerGroupNCustomers(): Promise<void> {
      try {
        if (locations && locations.length > 0) {
        const { id } = router.query;
        isReadOnly = false;
        if (id !== 'new') {
          await getCustomerGroup(parseInt(id, 10));

          await updateCustomers(parseInt(id, 10), pageNumber, pageSize);
        } else {
          let dealerIds:  any = []
          locations?.forEach((location) => {
            dealerIds.push(location.dealerId.toString())
          })
          initialCustomerGroup.locations = dealerIds

          setCustomerGroup(initialCustomerGroup);
          onFilterChange(initialCustomerGroup);
        }
      }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCustomerGroupNCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, dealerId, locations]);

  const filterList = customerGroup ? parseSegment(customerGroup) : [];
  const isNewSegment = customerGroup && customerGroup?.segmentId === -1;
  //const isReadOnly = customerGroup?.createdByUser === false;

  // function isReadOnly(){
  //   return customerGroup?.createdByUser === false;;

  // }

  function GetHeaderText() {
    let headerText = 'Customer Group';
    if (isNewSegment) headerText = 'New Customer Group';
    if (isReadOnly) headerText += ' (READONLY)';
    return headerText;
  }

  return (
    <Container className={classes.root}>
      {loadingCustomerGroup ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <SmallCircularProgress />
        </Box>
      ) : (
        <>

          {/* <PageHeader title={isNewSegment ? 'New Customer Group' : 'Customer Group'   }  /> */}
          <PageHeader title={GetHeaderText()} />
          <Box mt={1} />
          <Paper className={classes.paper}>
            <Container className={classes.paperContainer}>
              <form noValidate onSubmit={formik.handleSubmit}>
                <TextLimitField
                  helperTextAlignLeft={true}
                  characters={50}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  label="Name"
                  fullWidth
                  name="segmentName"
                  id="segmentName"
                  error={!!formik.errors.segmentName}
                  helperText={formik.errors.segmentName}
                  value={formik.values.segmentName}
                  onChange={formik.handleChange}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  margin="dense"
                  label="Description"
                  fullWidth
                  multiline
                  rows={3}
                  name="segmentDescription"
                  id="segmentDescription"
                  error={!!(formik.touched.segmentDescription && !!formik.errors.segmentDescription)}
                  helperText={formik.errors.segmentDescription}
                  value={formik.values.segmentDescription}
                  onChange={formik.handleChange}
                />
                <Box mt={1} id="filter" border={1} borderRadius={5} padding={1} borderColor={grey[400]}>
                  <Box id="filter-header" mb={1} display="flex">
                    <Typography variant="h5">Filters</Typography>
                  </Box>
                  <Box display="flex" flexWrap="wrap">
                    {filterList.map((filterItem: FilterItem): any => (
                      <FilterChip
                        key={filterItem.type}
                        filter={filterItem}
                        onClick={handleOpenAddFilterDrawer}
                        onDelete={handleDeleteFilter}
                        getManufacturerCode={getManufacturerCode}
                        getCustomerCategoryName={getCustomerCategoryName}
                        CustomerCategoryReadOnly ={isReadOnly}
                      />
                    ))}
                    {!isReadOnly && (
                      <AddFilterChip onClick={handleOpenAddFilterDrawer} />
                    )}

                  </Box>
                </Box>
                <Box mt={2} display="flex">
                  {/* <Box>

                  </Box> */}
                  <Box >
                    {/* &nbsp;&nbsp;&nbsp;
                    <Button href="" size="small"
                      classes={{
                        root: classes.exportToCSV, // class name, e.g. `classes-nesting-root-x`
                        label: classes.exportToCSVLabel, // class name, e.g. `classes-nesting-label-x`
                      }}
                    >
                      Export to CSV
                    </Button> */}
                  </Box>
                  <Box flexGrow={1}>
                    <table>
                      <tr>
                        <td>
                          <Chip
                            avatar={<PersonIcon />}
                            label={
                              loadingCustomers ? (
                                <SmallCircularProgress />
                              ) : (
                                `Customer count: ${customersCount.toLocaleString()}`
                              )
                            }
                          />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;
                          <Input defaultValue=""
                            placeholder="Search..." inputProps={{ 'aria-label': 'description' }}
                            name="segmentSearch"
                            id="segmentSearch"
                            error={!!(formik.touched.segmentSearch && !!formik.errors.segmentSearch)}
                            value={searchSegment}
                            onChange={HandleSearchChange}
                          />
                        </td>
                        <td>
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={EmailOrSms}
                              // label="Age"
                              onChange={(e: any) => { SetEmailOrSms(e.target.value) }}
                              style={{ fontSize: "small" }}
                            >
                              <MenuItem value="S">By Cell Phone</MenuItem>
                              <MenuItem value="E">By Email</MenuItem>

                            </Select>
                          </FormControl>
                        </td>
                      </tr>
                    </table>

                  </Box>
                  <Box>
                    <Button variant="contained" size="small" onClick={DownloadCustomerGroup} style={{marginRight:"10px"}} disabled={customersCount<=0 || isDownLoadingCsv?true:false} >Download CSV/Excel</Button>
                  </Box>

                  {!isNewSegment && !isReadOnly && (
                                          <Box mr={1}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        type="button"
                        onClick={handleDelete}
                        disabled={savingCustomerGroup}>
                        Delete
                      </Button>
                    </Box>
                  )}
                  {!isReadOnly && (
                                          <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        disabled={savingCustomerGroup}>
                        {isNewSegment ? 'Create' : 'Save'}
                      </Button>
                    </Box>
                  )}


                </Box>
              </form>
            </Container>
          </Paper>
          <Box my={2} pb={2}>
            <CustomerTable
              loading={loadingCustomers}
              pageNumber={pageNumber - 1}
              pageSize={pageSize}
              count={customersCount}
              onPageChange={handlePageChange}
              customers={customers}
            />
          </Box>
        </>
      )}
      {openFilterDrawer && (
        <FilterDrawer
          open={openFilterDrawer}
          customerGroup={customerGroup}
          onClose={handleFilterDrawerClose}
          addFilter={handleAddFilter}
          manufacturers={manufacturers}
          customerCategories={customerCategories}
        />
      )}
    </Container>
  );
}
