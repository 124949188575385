import convict from 'convict';
import convict_format_with_validator from 'convict-format-with-validator';

convict.addFormats(convict_format_with_validator);

const config = convict({
  env: {
    doc: 'The application environment',
    format: ['local', 'dev', 'qa', 'staging', 'production', 'prodbeta'],
    default: 'local',
    env: 'REACT_APP_ENV',
  },
  port: {
    doc: 'The port to bind',
    format: 'port',
    default: 3000,
    env: 'PORT',
    arg: 'port',
  },
  app: {
    url: {
      doc: 'The URL of web app',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_WEB_APP_URL',
    },
  },
  api: {
    customer: {
      doc: 'The customer service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_CUSTOMER_API',
    },
    messaging: {
      doc: 'The messaging service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_MESSAGING_API',
    },
    messaging2: {
      doc: 'The messaging2 service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_MESSAGING2_API',
    },
    messagingExternal: {
      doc: 'The messaging service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_MESSAGING_EXTERNAL_API',
    },
    dealer: {
      doc: 'The dealer service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_DEALER_API',
    },
    log: {
      doc: 'The log service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_LOG_API',
    },
    authentication: {
      doc: 'The dealer service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_AUTHENTICATION_API',
    },
    notification: {
      doc: 'The notification service path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_NOTIFICATION_API',
    },
    lead: {
      doc: 'The lead service path',
      format: 'url',
      nullable: true,
      default: null,
      env: 'REACT_APP_LEAD_API',
    },
    everest: {
      doc: 'The Everest API path',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_EVEREST_URL',
    },
    remotePayment: {
      doc: 'The remote payment service path',
      format: 'url',
      nullable: true,
      default: null,
      env: 'React_APP_REMOTEPAYMENT_URL',
    },
    googlePlaces: {
      doc: 'Google Places API Key',
      format:String,
      nullable: true,
      default: null,
      env: 'REACT_APP_GOOGLE_PLACES_API_KEY',
    },
    textEditor: {
      doc: 'textEditor API Key',
      format:String,
      nullable: false,
      default: null,
      env: 'REACT_APP_TEXT_EDITOR_API_KEY',
    },
    paymentSettings: {
      doc: 'Gravity Payment Settings',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_GRAVITY_PAYMENT_SETTINGS',
    },
    invetoryByParts: {
      doc: 'Invetory By Parts',
      format: 'url',
      nullable: false,
      default: null,
      env: 'REACT_APP_INVENTORY_BY_PARTS',
    },
    facebookAppId: {
      doc: 'Facebook App Id',
      format:String,
      nullable: false,
      default: "",
      env: 'REACT_APP_FACEBOOK_APP_ID',
    },
  },
});

try {
  config.validate({ allowed: 'strict' });
} catch (error) {
  if (window.location.href.indexOf('internalServerError') === -1) {
    window.location.replace('/internalServerError');
  }
}

export default config;
