import {
  Drawer,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useContext, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { DealItem } from '../model/dealsInterfaces';
import AppSnackbar from 'components/snackbar/snackbar';
import useStyles from '../styles/deals-styles';
import DealContext from '../contexts/deal-context';
import DealsApi from '../apis/deals-api';

function MoveDealDrawer({
  open,
  editCount,
  selectedColumn,
  renderDeals,
  onClose,
}: {
  open: boolean;
  selectedColumn: DealItem[];
  editCount: number;
  renderDeals: () => void;
  onClose(val: boolean): void;
}) {
  const classes = useStyles();
  const [showSnackBar] = AppSnackbar.useSnackBar();
  const dealContext = useContext(DealContext);

  const [selectedPipelineId, setSelectedPipelineId] = useState<number>(0);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const onCloseDrawerHandle = () => {
    onClose(false);
    setSelectedPipelineId(0);
  };

  const changeDealsPipeline = async (): Promise<void> => {
    const req = {
      pipelineId: selectedPipelineId,
      dealIds: selectedColumn.map((x) => {
        return x.id;
      }),
    };

    setSaveButtonLoading(true);
    const response = await DealsApi.PipelineDeals.updateDealsPipeline(req);
    setSaveButtonLoading(false);
    if (response === 'Ok') {
      onCloseDrawerHandle();
      renderDeals();
      showSnackBar({
        type: 'success',
        message: `${editCount > 1 ? ' Deals' : ' Deal'} moved to other pipeline successfully`,
      });
    } else {
      onCloseDrawerHandle();
      showSnackBar({ type: 'error', message: 'Something Went Wrong' });
    }
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={onCloseDrawerHandle}>
      <Box style={{ width: '400px' }}>
        <Toolbar className={classes.primary} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4">Edit Selected {editCount > 1 ? ' Deals' : ' Deal'} Pipeline</Typography>
          </Box>
          <IconButton id="closeBtn" onClick={onCloseDrawerHandle}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <Container>
          <Box mt={1}>
            <Grid container justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button variant="contained" size="small" color="primary" onClick={changeDealsPipeline}>
                  {saveButtonLoading ? <CircularProgress style={{ color: 'white' }} size="1.4rem" /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box mt={2} mb={2}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="Pipeline"
                    label="Pipeline"
                    value={selectedPipelineId == 0 ? dealContext.defaultPipelineId : selectedPipelineId}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.muiPopoverPaper,
                        },
                      },
                    }}
                    onChange={({ target: { value } }): void => setSelectedPipelineId(Number(value))}
                    style={{ color: 'rgb(50, 50, 50) !important' }}>
                    {dealContext.pipeline?.map((options) => {
                      return (
                        <MenuItem key={options.pipelineId} value={options.pipelineId}>
                          {options.pipelineName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
}

export default MoveDealDrawer;
