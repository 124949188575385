import axios from 'axios';
import { store } from 'app';
import config2 from 'config';
import { refreshTokenUser } from 'components/authentication/components/authentication-slice';
import { showSnackbar } from 'components/root-snackbar';
const apiClient = axios.create({});
let refreshTokenCalled = false;

apiClient.interceptors.request.use((config) => {
  const state = store.getState().user;
  if (state.authToken !== undefined && state.authToken !== '' && state.authToken !== null) {
    const env = config2.get('env');
    if (env === 'local') {
      config.headers = {
        ...config.headers,
        jwtToken: state.authToken,
      };
    } else {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + state.authToken,
      };
    }
  }
  return config;
});

apiClient.interceptors.response.use(undefined, async (error) => {
  if (error.response) {
    try {
      const state = store.getState().user;
      const { status } = error.response;

      if (status === 401 && !refreshTokenCalled) {
        refreshTokenCalled = true;

        if (state && state.refreshToken) {
          await store.dispatch(refreshTokenUser({ refreshToken: state.refreshToken })).then((resp) => {
            if (resp && resp.payload) {
              //TODO: Need to call the un-authenticated API again
              // error.config.headers['jwtToken'] = 'Bearer ' + state.authToken;
              // return apiClient.request(error.config);
              location.reload();
            }
          });
        }
      }
      if (status == 400) {
        if (error.response.data && error.response.data == 'Invalid Refresh Token') {
          location.href = '/login';
        }
      }
      if (status === 404) {
        // store.dispatch(showSnackbar({ type: 'error', message: 'Something went wrong.' }));
      }
      if (status === 500) {
        // store.dispatch(showSnackbar({ type: 'error', message: 'Something went wrong.' }));
      }
    } catch (ex) {
      console.error(ex);
    }
    return Promise.reject(error.response.data);
  }
  if (error.message === 'Network Error') {
  }
  return Promise.reject(error);
});

const { get, post, put, patch, delete: destroy } = apiClient;
export { get, post, put, patch, destroy };
