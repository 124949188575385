import config2 from 'config';
import { store, useAppSelector } from 'app';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useEffect, useState } from 'react';
import CustomerAPI, { GravityPaymentInvoicesData, GravityPaymentInvoicesResponse } from 'components/customer/api/CustomerAPI';
import { Conversation } from 'components/messaging/models/conversation';
import AppSnackbar from "components/snackbar/snackbar";
// import Button from '@mui/material/Button';
import { AppBar, Box, CircularProgress, Container, Grid, Button, IconButton, MenuItem, Popover, Select, TextField, Toolbar, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Console } from 'console';
import CloseIcon from '@material-ui/icons/Close';
import { Padding } from '@mui/icons-material';
import moment from 'moment';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const useStyles = makeStyles((theme) => ({
  select: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function TextToPay({ Conversation, SendPayment } : { Conversation: Conversation; SendPayment: any }) {
  const classes = useStyles();
  const dmsDealerId = useAppSelector((state) => state.user.dmsDealerId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [isSendingMessage, SetIsSendingMessage] = useState<boolean>(false);
  const [memo, setMemo] = useState<string>('');
  const [showSnackBar] = AppSnackbar.useSnackBar(); 
  const [amount, setAmount] = useState<string>('');
  const [selectedInvoice, setSelectedInvoice] = useState<number>();
  const [gravityInvoices, setGravityInvoices] = useState<GravityPaymentInvoicesResponse>();
  const [isLoading,setIsLoading] = useState<boolean>(false);
  const [isNoInvoices,setIsNoInvoices] = useState<boolean>(false);
  
  const isTesting = ()=>{
    const allowedDealer= [99204001,99203,999002,999302];
    if(allowedDealer.includes(dmsDealerId)){
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (open && isTesting()) {
      const fetchGravityInvoices = async () => {
        try {
          setIsLoading(true);
          let response = await CustomerAPI.GetCustomerInvoices({
            queryBody: { DealerId: Conversation.dealerId, CustomerId: Conversation.invoiceCustomerId?.toString() },
          });
          if (response.length > 0) {
            setGravityInvoices(response);
            setIsNoInvoices(false);
          } else {
            setIsNoInvoices(true);
          }
          setIsLoading(false);
        } catch {
          setGravityInvoices(undefined);
          setIsNoInvoices(true);
          setIsLoading(false);
        }
      };
      fetchGravityInvoices();
    }
  }, [open]);

  const handleClick = (event: any): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
    setMemo('');
    setAmount('');
    setSelectedInvoice(undefined);
  };
  async function SendPaymentRequest() {
    let objNote: any = new Object();
    objNote.ConversationId = Conversation.id;
    objNote.CellPhone = Conversation.customer.cellPhone;
    objNote.CrmCustomerId = Conversation.customer.crmCustomerId;
    let jsonStringNote = JSON.stringify(objNote);

    let obj: any = new Object();
    obj.dmsDealerId = store.getState().user.dmsDealerId;
    obj.dealerId = Conversation.dealerId ?? store.getState().user.dealerId;
    obj.customerId = Conversation.customer.crmCustomerId;
    obj.customerName = Conversation.customer.name;
    obj.departmentName = Conversation.departmentName;
    obj.memo = memo;
    obj.amount = amount;
    obj.invoiceId = selectedInvoice;
    obj.orderId = gravityInvoices?.find(x=>x.invoiceId === selectedInvoice)?.orderId;
    obj.note = jsonStringNote;
    obj.locationName = Conversation.locationName;
    obj.env = config2.get('env');
    let jsonString = JSON.stringify(obj);
    SetIsSendingMessage(true);
    const response: any = JSON.stringify(await CustomerAPI.TextToPayCustomer(jsonString));
    if(response != undefined){ 
      if (response.includes("Err:")) {
        handleClose();
        SetIsSendingMessage(false);
        showSnackBar({ type: 'error', message: 'Payment settings are not valid for ' + Conversation.locationName });
        return;
      }     
      const paymentData: {paymentPageId:string,paymentPageUrl:string,externalTransId:string,urlShortener:string}= JSON.parse(response) ;
      if (paymentData.urlShortener != undefined && paymentData.urlShortener != '') {
        let obj: any = new Object();
        obj.memo = memo;
        obj.amount = amount;
        obj.urlShortner = paymentData.urlShortener;
        obj.Id = paymentData.externalTransId;
        obj.paymentPageId = paymentData.paymentPageId;
        obj.invoiceId = selectedInvoice;
        obj.paymentStatus = 'Pending';
        let jsonRefBody = JSON.stringify(obj);
        let message=paymentData.urlShortener;
        if(memo!='') message =message + ' Memo: ' + memo;
        SendPayment(message, 'PaymentSent', jsonRefBody, paymentData.externalTransId);
        handleClose();
        SetIsSendingMessage(false);
      }     
    }
    
  }
  function CheckAmount(value: any) {
    if (isNaN(value)) return;
    if (value[0] === '0') return;
    if (value.toString().indexOf('.') > -1 && value.toString().split('.')[1].length > 2) return;
    setAmount(value);
  }
  const handleInvoiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedInvoice(event.target.value as number);
    const selectedInvoice = gravityInvoices?.find(x=>x.invoiceId === event.target.value as number)
    if(Number(selectedInvoice?.invoiceAmount) > 0){
      CheckAmount((selectedInvoice?.invoiceAmount ?? 0) - (selectedInvoice?.payment ?? 0));
    }
  };
  return (
    <>
      <Tooltip title="Text-To-Pay">
        <IconButton style={{ padding: 0, marginTop: '.2rem', marginLeft: '-.2rem' }} size="small" onClick={handleClick}>
          <AttachMoneyIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box width={'450px'}>
          <Toolbar style={{ backgroundColor: '#3f51b5' }} variant="dense">
            <Box flexGrow={1}>
              <Typography variant="h4" style={{ color: 'white', fontWeight: 'bold' }}>
                Request Payment
              </Typography>
            </Box>
            <IconButton id="closeBtn" style={{ margin: '-15px', padding: '5px' }} onClick={handleClose}>
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </Toolbar>
          <Box p={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="outlined-basic"
                  label="Memo"
                  size="small"
                  value={memo}
                  variant="outlined"
                  onChange={(e) => setMemo(e.target.value)}
                  error={amount !== '' && memo === '' ? true : false}
                />
              </Grid>
              {isTesting() && (
                <Grid item xs={12}>
                  <TextField
                    select
                    required
                    disabled={isLoading}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    size="small"
                    error={isNoInvoices}
                    helperText={isNoInvoices ? 'No invoices found' : ''}
                    label="Select Invoice"
                    value={selectedInvoice}
                    onChange={handleInvoiceChange}
                    InputProps={{
                      endAdornment: (
                        <>
                          {isLoading && (
                            <div style={{ position: 'absolute', right: 32, top: '50%', transform: 'translateY(-50%)' }}>
                              <CircularProgress color="primary" size={20} />
                            </div>
                          )}
                        </>
                      ),
                    }}>
                    {gravityInvoices?.map((x: GravityPaymentInvoicesData) => (
                      <MenuItem key={x.invoiceId} value={x.invoiceId}>
                        {x.invoiceId} (${(x.invoiceAmount ?? 0) - (x.payment ?? 0)} •{' '}
                        {x.invoiceDate && moment.utc(x.invoiceDate).local().format('MM/DD/YYYY')})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  required
                  label="Amount"
                  size="small"
                  value={amount}
                  variant="outlined"
                  onChange={(e) => !isTesting() && CheckAmount(e.target.value)}
                  error={memo !== '' && amount === '' ? true : false}
                />
              </Grid>
            </Grid>
            <Box mt={'20px'} style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                disabled={memo === '' || amount === '' || (isTesting() && !selectedInvoice) || isSendingMessage}
                variant="contained"
                onClick={SendPaymentRequest}>
                {!isSendingMessage ? (
                  'Send payment request'
                ) : (
                  <>
                    Please wait...! <CircularProgress style={{ marginLeft: '5px' }} color="inherit" size={20} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
export default TextToPay;
