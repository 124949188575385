import moment from 'moment';
import { formatPhoneNumber } from 'common/utils';
import { Box, Drawer, FormControl, InputLabel, MenuItem, FormHelperText, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Button, ButtonGroup, FormControlLabel, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import DealerAPI from 'components/authentication/api/dealer-api';
import { store, useAppSelector } from 'app';
import React from "react";
import DrawerToolbar from "common/components/drawer/drawer-toolbar";
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MailIcon from '@mui/icons-material/Mail';
import SmsIcon from '@mui/icons-material/Sms';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import AppSnackbar from 'components/snackbar/snackbar';
import config from 'config';
import useLocations from 'common/hooks/useLocations';
import MergeTagWidget from 'components/broadcast/components/broadcast-page/merge-tags';
import { EmojiPickerButton } from 'common/components/buttons';
import UrlShortenerWidget from 'components/broadcast/components/broadcast-page/url-shortener-widget';
import { Link } from "react-router-dom";

const useStyles1 = makeStyles((theme: any) => ({
    selectedRow: {
        backgroundColor: "#EAF2F8 !important",
    }
}));

function TextAutomation({ onClose }: { onClose: any }) {

    const formatDate = (date: string): string => moment(date).format('M-DD-YYYY h:mm a');
    //const formatTime = (date: string): string => moment(date).format('h:mm a');
    const classes = useStyles1();
    const [dataAutomation, SetDataAutomation] = React.useState<any>([]);
    const [dataLogs, SetDataLogs] = React.useState<any>();
    const [dataLogsEmail, SetDataLogsEmail] = React.useState<any>();       
    const [dataEmailsReadyToSend, SetDataEmailsReadyToSend] = React.useState<any>();
    const [dataSMSReadyToSend, SetDataSMSReadyToSend] = React.useState<any>();         
    const [dealerName, SetDealerName] = React.useState<any>('');;
    const [selectedRow, SetSelectedRow] = React.useState<any>([]);
    const [selectedRowCopy, SetSelectedRowCopy] = React.useState<any>([]);
    const [editDealer, SetEditDealer] = React.useState(false);
    const [counter, SetCounter] = React.useState(0);
    const [dataWOStatus, SetDataWOStatus] = React.useState<any>([]);
    const [disableLocStatus, setDisabledLocStatus] = React.useState<boolean>(false);
    const [showSnackbar] = AppSnackbar.useSnackBar();
    const [hideDeletebutton, SetHideDeletebutton] = React.useState(false);
    const [departments, setDepartments] = React.useState<any>();
    const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
    const { loading: locationLoading, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);

    const textareaRef = useRef<HTMLInputElement | undefined>(undefined);
    const textareaSubject = useRef<HTMLInputElement | undefined>(undefined);
        
    async function GetData(): Promise<void> {
        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        //console.error(store.getState().user);
        let jsonString = JSON.stringify(obj);
        let response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetSetting]", jsonString);
        response = response.filter((item:any)=>item.TriggerStatusCode!=="");
        SetDataAutomation(response);
        if (response.length > 0) {
            SetDealerName(response[0].DealerName);
        }
    }

    async function GetOpenOrderStatuses(): Promise<void> {
        let obj: any = new Object();
       obj.DealerId = store.getState().user.dealerId;

       try {
        let jsonString = JSON.stringify(obj);
        let response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[USPGetOpenOrderStatus]", jsonString);
        if (response == null || response == undefined || response.length == 0 || response == 'ErrorProcessingWorkOrderJobStatus'){
            showSnackbar({ type: 'error', message: "Work Order Job statuses are missing. Without configuring Work Order Job status, automation will not work." });
        }                
        else {
            SetDataWOStatus(response);    
        }
    } catch (error) {
        showSnackbar({ type: 'error', message: "Work Order Job statuses are missing. Without configuring Work Order Job status, automation will not work." });
        console.error(error);
    }

    }

     async function GetAllDepartments():Promise<void> {
        let locIds = locationOptions?.filter((item:any)=>item.dealerLocationId>0);
        let promises= locIds?.map(async (item:any)=>{
            let obj: any = new Object();
            obj.Dealerid = item.dealerLocationId;
            let jsonString = JSON.stringify(obj);
            const resp = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetDepartment]", jsonString)
            return {
                dealerLocationId: item.dealerLocationId,
                Departments:  [...resp ],
              };
        }) || [];
        const fulfilledPromises = await Promise.all(promises);
        setDepartments(fulfilledPromises)
    }
    
    async function GetEmailsReadyToSend(): Promise<void> {
        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        let jsonString = JSON.stringify(obj);
        const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[ReadyToSendEmailAutomation]", jsonString);
        SetDataEmailsReadyToSend(response);
        SetDataSMSReadyToSend(null);
        SetDataLogs(null);
        SetDataLogsEmail(null);
    }

    async function GetSMSReadyToSend(): Promise<void> {
        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        let jsonString = JSON.stringify(obj);
        const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[ReadyToSendSMSAutomation]", jsonString);
        SetDataSMSReadyToSend(response);
        SetDataEmailsReadyToSend(null);
        SetDataLogs(null);
        SetDataLogsEmail(null);
    }


  async function GetEmailLogs(): Promise<void> {
        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        let jsonString = JSON.stringify(obj);
        const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetSentLogEmail]", jsonString);
        SetDataLogs(null);
        SetDataEmailsReadyToSend(null);
        SetDataSMSReadyToSend(null);
        SetDataLogsEmail(response);
    }
    async function GetSmsLogs(): Promise<void> {
        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        let jsonString = JSON.stringify(obj);
        const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetSentLog]", jsonString);

        SetDataLogs(response);
        SetDataLogsEmail(null);
        SetDataEmailsReadyToSend(null);
        SetDataSMSReadyToSend(null);
    }

    // async function GetDepartments(dealerId: any): Promise<void> {
    //     let obj: any = new Object();
    //     obj.Dealerid = dealerId; //selectedRow.DealerId; // store.getState().user.dealerId;
    //     //obj.DmsDealerId = store.getState().user.dmsDealerId;ExecuteJsonSP
    //     let jsonString = JSON.stringify(obj);
    //     const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetDepartment]", jsonString);
    //     SetDataLocation(response);
    // }   

    function GetTitle() {
        return 'Automation Setting: ' + dealerName;
    }

    function GetLocationTitle() {
        return 'Location Setting: ' + selectedRow.LocationName;
    }

    async function AddSetting(row: any) {

        let obj: any = new Object();
        obj.DmsDealerId = store.getState().user.dmsDealerId;
        obj.DealerId = store.getState().user.dealerId;
        let jsonString = JSON.stringify(obj);
        const response = await DealerAPI.ExecuteJsonSP("[TextAutomation].[spGetDefaultSetting]", jsonString);

        row = response[0];
        row.DepartmentId =departments?.find((item:any)=> item.dealerLocationId==row.DealerId)?.Departments[0].DepartmentId;
        if (response.length > 0) {
            SetDealerName(response[0].DealerName);
        }

        SetHideDeletebutton(true);
        setDisabledLocStatus(false);
        SetSelectedRow(row);
        let copyRow = Object.assign({}, row);
        const newMessage = "Hi, @CustomerName, the status of @JobDescription on shop ticket @JobId has been updated to @JobStatus. This job is one of @JobCount total jobs. As each job is completed, we will keep you updated, and you will receive a final notification when all work on the shop ticket is complete."
        if (copyRow.TextMessage == "") {
            copyRow.TextMessage = newMessage;
        }
        SetSelectedRowCopy(copyRow);
        SetEditDealer(true);       
    }

    function EditSetting(row: any) {
        setDisabledLocStatus(true);
        SetHideDeletebutton(false);
        SetSelectedRow(row);
        let copyRow = Object.assign({}, row);
        SetSelectedRowCopy(copyRow);
        SetEditDealer(true);
    }

    function handleChange(event: any, columnName: any) {
        selectedRowCopy[columnName] = event.target.checked;
        SetCounter(counter + 1);
    }

    function onChange(event: any, columnName: any) {        
        if (event.target.type == "checkbox") {
            selectedRowCopy[columnName] = event.target.checked;
        }
        else{
            selectedRowCopy[columnName] = event.target.value;
        }
        SetCounter(counter + 1);
    }

    function OpenMergeTagsWindowSubject(messageSnippet: string): void {
        insertInMessageSubject(messageSnippet);
        focusInputTextFieldSubject();
    }

    function focusInputTextFieldSubject(): void {
        setTimeout(() => {
          if (textareaSubject && textareaSubject.current) {
            textareaSubject.current?.focus();
            textareaSubject.current.selectionStart = textareaSubject.current.value.length;
            textareaSubject.current.selectionEnd = textareaSubject.current.value.length;
          }
        }, 0);
      }

      function insertInMessageSubject(data: string, type = 'Text'): void {
        let textFieldIndex: number | null = null;
        if (textareaSubject !== undefined && textareaSubject.current !== undefined) {
          textFieldIndex = textareaSubject.current.selectionStart;
        }
        if (textFieldIndex !== null) {
          const newMessage =
          selectedRowCopy.EmailSubject.slice(0, textFieldIndex) + data + selectedRowCopy.EmailSubject.slice(textFieldIndex);
          selectedRowCopy["EmailSubject"] = newMessage;
            SetCounter(counter + 1);
        }
      }   

    function OpenMergeTagsWindow(messageSnippet: string): void {
        insertInMessage(messageSnippet);
        focusInputTextField();
        //selectedRowCopy["TextMessage"] = selectedRowCopy["TextMessage"] + ' ' + messageSnippet;
        //SetCounter(counter + 1);
    }

    function focusInputTextField(): void {
        setTimeout(() => {
          if (textareaRef && textareaRef.current) {
            textareaRef.current?.focus();
            textareaRef.current.selectionStart = textareaRef.current.value.length;
            textareaRef.current.selectionEnd = textareaRef.current.value.length;
          }
        }, 0);
      }

      function insertInMessage(data: string, type = 'Text'): void {
        let textFieldIndex: number | null = null;
        if (textareaRef !== undefined && textareaRef.current !== undefined) {
          textFieldIndex = textareaRef.current.selectionStart;
        }
        if (textFieldIndex !== null) {
          const newMessage =
          selectedRowCopy.TextMessage.slice(0, textFieldIndex) + data + selectedRowCopy.TextMessage.slice(textFieldIndex);
          selectedRowCopy["TextMessage"] = newMessage;
            SetCounter(counter + 1);
        }
      }      

      function handleEmojiSelect(emoji: string): void {
        insertInMessage(emoji);
        focusInputTextField();
      }

      function handleAddUrlShortner(shortner: string): void {
        insertInMessage(shortner);
        focusInputTextField();
      }
      
    async function DeleteSetting() {
        if (selectedRowCopy.isActive == null) selectedRowCopy.isActive = true;
        let jsonString = JSON.stringify(selectedRowCopy);
        try {
            const response = await DealerAPI.ExecuteJsonSPPost("[TextAutomation].[spDeleteSetting]", jsonString);
            SetSelectedRow(selectedRowCopy);
            GetData();
            SetEditDealer(false);
            showSnackbar({ type: 'success', message: "Automation has been deleted successfuly." });
        } catch (error) {
            console.error(error);
        }

    }
    function isStatusPresentInLocation(selectedRowCopy:any):boolean{
        //let duplicate = dataAutomation?.find((item:any)=>item.DealerId==selectedRowCopy.DealerId && item.TriggerStatusCode==selectedRowCopy.TriggerStatusCode && item.MessageType==selectedRowCopy.MessageType);
        let duplicate = dataAutomation?.find((item:any)=>item.DealerId==selectedRowCopy.DealerId && item.TriggerStatusCode==selectedRowCopy.TriggerStatusCode);
        return !!duplicate;
    }
    async function SaveChanges() {
        if (selectedRowCopy.isActive == null) selectedRowCopy.isActive = true;
        if (selectedRowCopy.TriggerStatusCode=="" || (!disableLocStatus && isStatusPresentInLocation(selectedRowCopy)))
            return

        let jsonString = JSON.stringify(selectedRowCopy);
        try {
            const response = await DealerAPI.ExecuteJsonSPPost("[TextAutomation].[spSaveSetting]", jsonString);
            
            if (response == 'Failed') {
                showSnackbar({ type: 'error', message: "Unable to save Automation. Please try again." });
            }
            else {
                SetSelectedRow(selectedRowCopy);
                GetData();   
                showSnackbar({ type: 'success', message: "Automation has been saved successfuly." });
            }          
             SetEditDealer(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        GetData();
        GetOpenOrderStatuses();
    }, []);
    
    useEffect(()=>{
        GetAllDepartments();
    },[locationOptions])

    return (
        <Box>
            <Drawer
                PaperProps={{ style: { width: '90%', minWidth: "25%" } }}
                anchor="right"
                open={true}
                onClose={onClose}
                variant={'temporary'}
            >
                 {/*<DrawerToolbar title={GetTitle()} onClose={onClose} />*/}

                
                <Box>

                    <Box style={{ margin: "5px 10px" }}>
                    <Box>
                    {GetTitle()}    
                        <Link to={{pathname: '/'}}  style={{display: 'flex', justifyContent: 'right'}} > 
                            <CloseIcon></CloseIcon>
                        </Link>                    
                    </Box> 

                    <Box>                        
                        <Button onClick={AddSetting} variant="contained" startIcon={<AddCircleIcon />} color="primary">
                            Create Automation
                        </Button>
                    </Box>

                        <Paper>
                            <TableContainer style={{ border: "1px solid #C8CCCF", marginTop: "10px" }}>


                                <Table stickyHeader size="small"  >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Location</TableCell>
                                            {/* <TableCell>Address</TableCell> */}
                                            <TableCell>Department</TableCell>
                                            <TableCell>StatusCode</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Email Subject</TableCell>   
                                            <TableCell>Message</TableCell>
                                            <TableCell>Signature</TableCell>                                                                                  
                                            {/*<TableCell style={{ textAlign: "right" }}>Delay</TableCell>*/}
                                            <TableCell>Active</TableCell>
                                            {/* <TableCell> <span onClick={AddSetting} ><AddIcon style={{ cursor: "pointer" }}></AddIcon></span></TableCell>
                                                <TableCell></TableCell> 
                                            */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataAutomation.map((row: any) => {
                                            // let surveyDate = ConvetToLocalTime(row.updatedAt).toLocaleDateString();
                                            //let locationName = row.LocationName + ' (' + row.ZipPostal + ')';
                                            let locationName = row.LocationName;
                                            let selected = selectedRow.DealerId == row.DealerId;
                                            return (
                                                <TableRow
                                                    hover
                                                    className={selected ? classes.selectedRow : ""}
                                                    // onClick={() => SetSelectedRow(row)}>
                                                    onClick={() => EditSetting(row)}>
                                                    <TableCell><span style={{ whiteSpace: "nowrap" }}>{locationName}</span></TableCell>
                                                    <TableCell><span style={{ whiteSpace: "nowrap" }}>{row.DepartmentName}</span></TableCell>
                                                    <TableCell>{row.TriggerStatusCode}</TableCell>
                                                    <TableCell>{row.MessageTypeDescription}</TableCell>
                                                    <TableCell>{row.EmailSubject}</TableCell> 
                                                    <TableCell>{row.MessageDescription}</TableCell>
                                                    <TableCell><span style={{ whiteSpace: "nowrap" }}>{row.MessageSignature}</span></TableCell>                                                  
                                                    {/*<TableCell style={{ textAlign: "right" }}>{row.DelayMessage}</TableCell>*/}
                                                    <TableCell>
                                                        {row.isActive && (
                                                            <DoneIcon></DoneIcon>
                                                        )}
                                                    </TableCell>                                                  
                                                    {/*
                                                        <TableCell><span onClick={() => EditSetting} ><EditIcon style={{ fontSize: "medium", cursor: "pointer" }}></EditIcon></span></TableCell>
                                                    */}
                                                    </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                       <Box style={{ marginTop: "10px" }}>

                       <Box mb={1} mt={2} display="flex">
                                    <Box flexGrow={1} />
                                                <Box>
                                                <Grid container spacing={2}>
                                                <Grid item>
                                                        <Button onClick={() => (GetEmailLogs())} variant="contained" startIcon={<MailIcon />} color="primary">
                                                            Email Logs
                                                        </Button>
                                                    </Grid>

                                                   <Grid item>
                                                        <Button onClick={() => (GetEmailsReadyToSend())} variant="contained" startIcon={<MailIcon />} color="secondary">
                                                            Emails Ready To Send
                                                        </Button>
                                                    </Grid>
                                                    
                                                    <Grid item> 
                                                        <Button onClick={() => (GetSmsLogs())} variant="contained" startIcon={<SmsIcon />} color="primary">
                                                            SMS Logs
                                                        </Button>
                                                    </Grid>

                                                    <Grid item>
                                                        <Button onClick={() => (GetSMSReadyToSend())} variant="contained" startIcon={<SmsIcon />} color="secondary">
                                                            SMS Ready To Send
                                                        </Button>
                                                    </Grid>

                                                    {/*
                                                        <Grid item>
                                                        <Button onClick={AddSetting} color="primary" startIcon={<AddCircleIcon />} variant="contained">
                                                            Create Automation
                                                        </Button>
                                                        </Grid>
                                                    */}
                                                </Grid>
                                                </Box>
                                </Box>

                       {/* SMS Logs */}
                            {(dataLogs != undefined && dataLogs.length > 40) && (<span style={{ marginLeft: "10px", color: "silver" }} >Top 50 records only</span>)}
                            {(dataLogs != undefined && dataLogs.length == 0) && (<span style={{ marginLeft: "10px", color: "silver" }} >No log found...</span>)}

                            {(dataLogs != undefined && dataLogs.length > 0) && (
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Department</TableCell>
                                                <TableCell>From</TableCell>
                                                <TableCell>To</TableCell>
                                                <TableCell>Message</TableCell>
                                                <TableCell>Job#</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Message Type</TableCell>                                                
                                            </TableHead>
                                            <TableBody>
                                                {dataLogs.map((row: any) => {
                                                    let locationName = row.LocationName;
                                                    let selected = selectedRow.DealerId == row.DealerId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                        //className={selected ? classes.selectedRow : ""}
                                                        //onClick={() => SetSelectedRow(row)}
                                                        >
                                                            <TableCell valign='top'>{moment.utc(row.DateCreated).local().format('M/D/Y h:mm a')}</TableCell>
                                                            <TableCell valign='top' >{row.DepartmentName}</TableCell>
                                                            <TableCell valign='top' style={{ whiteSpace: 'nowrap' }}>{formatPhoneNumber(row.From)}</TableCell>
                                                            <TableCell valign='top'><span style={{ whiteSpace: 'nowrap' }}>{formatPhoneNumber(row.To)}</span> ({row.Status})</TableCell>
                                                            <TableCell valign='top'>{row.Body}</TableCell>
                                                            <TableCell valign='top'>{row.JobId}</TableCell>
                                                            <TableCell valign='top'>{row.OrderStatus}</TableCell>
                                                            <TableCell valign='top'>{row.MessageTypeDescription}</TableCell>                                                            
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}

            {/* Email Automation Logs */}

                {(dataLogsEmail != undefined && dataLogsEmail.length > 40) && (<span style={{ marginLeft: "10px", color: "silver" }} >Top 50 records only</span>)}
                {(dataLogsEmail != undefined && dataLogsEmail.length == 0) && (<span style={{ marginLeft: "10px", color: "silver" }} >No log found...</span>)}

                {(dataLogsEmail != undefined && dataLogsEmail.length > 0) && (
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell>Date</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>InvoiceId</TableCell>
                                                <TableCell>Job#</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Message Type</TableCell>                                                
                                            </TableHead>
                                            <TableBody>
                                                {dataLogsEmail.map((row: any) => {
                                                    let locationName = row.LocationName;
                                                    let selected = selectedRow.DealerId == row.DealerId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                        //className={selected ? classes.selectedRow : ""}
                                                        //onClick={() => SetSelectedRow(row)}
                                                        >
                                                            <TableCell valign='top'>{moment.utc(row.DateCreated).local().format('M/D/Y h:mm a')}</TableCell>
                                                            <TableCell valign='top' >{row.DepartmentName}</TableCell>
                                                            <TableCell valign='top'>{row.InvoiceID}</TableCell>
                                                            <TableCell valign='top'>{row.JobId}</TableCell>
                                                            <TableCell valign='top'>{row.OrderStatus}</TableCell>
                                                            <TableCell valign='top'>{row.MessageTypeDescription}</TableCell>                                                            
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}

                            {/* Emails Ready to Send List */}
                {(dataEmailsReadyToSend != undefined && dataEmailsReadyToSend.length > 40) && (<span style={{ marginLeft: "10px", color: "silver" }} >Top 50 records only</span>)}
                {(dataEmailsReadyToSend != undefined && dataEmailsReadyToSend.length == 0) && (<span style={{ marginLeft: "10px", color: "silver" }} >No Emails found in Ready to Send...</span>)}

                {(dataEmailsReadyToSend != undefined && dataEmailsReadyToSend.length > 0) && (
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Invoice</TableCell>
                                                <TableCell>Job#</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Subject</TableCell>
                                                <TableCell>Message</TableCell>                                                
                                            </TableHead>
                                            <TableBody>
                                                {dataEmailsReadyToSend.map((row: any) => {
                                                    //let locationName = row.DealerName;
                                                    //let selected = selectedRow.DealerId == row.DealerId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                        //className={selected ? classes.selectedRow : ""}
                                                        //onClick={() => SetSelectedRow(row)}
                                                        >                                                           
                                                            <TableCell valign='top'>{row.DealerName}</TableCell>
                                                            <TableCell valign='top'>{row.InvoiceId}</TableCell>
                                                            <TableCell valign='top'>{row.JobId}</TableCell>
                                                            <TableCell valign='top'>{row.JobStatus}</TableCell>
                                                            <TableCell valign='top' >{row.EmailAddress}</TableCell>
                                                            <TableCell valign='top'>{row.EmailSubject}</TableCell>                                                            
                                                            <TableCell valign='top'>{row.TextMessage}</TableCell>                                                            
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}

                {/* SMS Ready to Send List */}
                {(dataSMSReadyToSend != undefined && dataSMSReadyToSend.length > 40) && (<span style={{ marginLeft: "10px", color: "silver" }} >Top 50 records only</span>)}
                {(dataSMSReadyToSend != undefined && dataSMSReadyToSend.length == 0) && (<span style={{ marginLeft: "10px", color: "silver" }} >No SMS found in Ready to Send...</span>)}
                {(dataSMSReadyToSend != undefined && dataSMSReadyToSend.length > 0) && (
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Invoice</TableCell>
                                                <TableCell>Job#</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Department#</TableCell>
                                                <TableCell>Customer#</TableCell>
                                                <TableCell>Message</TableCell>                                                
                                            </TableHead>
                                            <TableBody>
                                                {dataSMSReadyToSend.map((row: any) => {
                                                    //let locationName = row.DealerName;
                                                    //let selected = selectedRow.DealerId == row.DealerId;
                                                    return (
                                                        <TableRow
                                                            hover
                                                        //className={selected ? classes.selectedRow : ""}
                                                        //onClick={() => SetSelectedRow(row)}
                                                        >                                                           
                                                            <TableCell valign='top'>{row.DealerId}</TableCell>
                                                            <TableCell valign='top'>{row.InvoiceId}</TableCell>
                                                            <TableCell valign='top'>{row.JobId}</TableCell>
                                                            <TableCell valign='top'>{row.JobStatus}</TableCell>
                                                            <TableCell valign='top'>{row.PhoneNumberDepartment}</TableCell>
                                                            <TableCell valign='top'>{row.PhoneNumberCustomer}</TableCell>                                                            
                                                            <TableCell valign='top'>{row.TextMessage}</TableCell>                                                            
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            )}

            {/* End Automation Logs & Ready to Send List*/}


                        </Box>


                    </Box>

                </Box>
            </Drawer>
            {editDealer && (
                <Box>
                    <Drawer
                        // PaperProps={{ style: { width: '90%', minWidth: "25%" } }}
                        PaperProps={{ style: { width: 'auto', minWidth: "50%" } }}
                        anchor="right"
                        open={editDealer}
                        onClose={() => SetEditDealer(false)}
                        variant={'temporary'}
                    >
                        <Box>
                            <DrawerToolbar title={GetLocationTitle()}  onClose={() => {SetEditDealer(false); GetAllDepartments();}} />
                            <Box style={{ margin: "5px 10px" }}>
                                <Box>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedRowCopy.DealerId}
                                            label="location"
                                            disabled={disableLocStatus}
                                            onChange={(event) => {
                                                onChange(event, "DealerId");
                                                selectedRowCopy.DepartmentId=departments?.find((item:any)=> item.dealerLocationId==selectedRowCopy.DealerId)?.Departments[0].DepartmentId;
                                              }}
                                            >
                                            {locationOptions &&
                                                locationOptions.map((loc) => ( loc.dealerLocationId>0 &&
                                                <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                                                  {loc.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>          
                                <Box style={{ marginTop: "10px" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Department</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedRowCopy.DepartmentId}
                                            label="Department"
                                            //disabled={selectedRowCopy.MessageType=="E"}
                                            onChange={(event) => { onChange(event, "DepartmentId") }}
                                        >
                                            {departments?.find((item:any)=> item.dealerLocationId==selectedRowCopy.DealerId)?.Departments?.map((row:any)=>{
                                                return (
                                                    <MenuItem value={row.DepartmentId}>{row.DepartmentName} {formatPhoneNumber(row.PhoneNumber)}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={{ marginTop: "10px" }}>
                                    {typeof (dataWOStatus) !== 'undefined' && (
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Status Trigger</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedRowCopy.TriggerStatusCode}
                                                label="Status Trigger"
                                                disabled={disableLocStatus}
                                                error={selectedRowCopy.TriggerStatusCode=="" || (!disableLocStatus && isStatusPresentInLocation(selectedRowCopy))}
                                                onChange={(event) => { onChange(event, "TriggerStatusCode") }}
                                            >
                                                {dataWOStatus.map((row: any) => {
                                                    return (
                                                        <MenuItem value={row.Status}>{row.Status}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            {selectedRowCopy.TriggerStatusCode=="" && (
                                                 <FormHelperText style={{ color: '#e53935' }}>Status is required</FormHelperText>
                                            )}
                                             {!disableLocStatus && isStatusPresentInLocation(selectedRowCopy) && (
                                                 <FormHelperText style={{ color: '#e53935' }}>Status is already present</FormHelperText>
                                            )}
                                        </FormControl>
                                    )
                                    }
                                </Box>

                                <Box hidden style={{ marginTop: "15px" }}>
                                    <TextField id="outlined-basic" fullWidth value={selectedRowCopy.DelayMessage} label="Delay Message (In Mins)" variant="outlined" onChange={(event) => { onChange(event, "DelayMessage") }} />
                                </Box>

                                <Box style={{ marginTop: "15px" }}>
                                    <TextField id="outlined-basic" 
                                    inputRef={textareaSubject} 
                                    fullWidth 
                                    value={selectedRowCopy.EmailSubject} 
                                    label="Subject" 
                                    variant="outlined" 
                                    disabled={selectedRowCopy.MessageType=="S"}
                                    onChange={(event) => { onChange(event, "EmailSubject") }} 
                                    />
                                    <Grid item container spacing={1} style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px", display: selectedRowCopy.MessageType=="S" ? "none" : "block" }}>                   
                                                    <MergeTagWidget onClick={OpenMergeTagsWindowSubject} disabled={false} />
                                    </Grid> 
                                </Box>                                

                                <Box style={{ marginTop: "15px" }}>
                                    <TextField 
                                            inputRef={textareaRef} 
                                            fullWidth 
                                            multiline 
                                            rows={5}
                                            id="outlined-basic" 
                                            value={selectedRowCopy.TextMessage} 
                                            label="Message" 
                                            variant="outlined" 
                                            margin="dense" 
                                            onChange={(event) => { onChange(event, "TextMessage") }} />
                                    <Grid item container spacing={1} style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "5px" }}>                   
                                                <MergeTagWidget onClick={OpenMergeTagsWindow} disabled={false} />
                                                <EmojiPickerButton onSelect={handleEmojiSelect} />
                                                <UrlShortenerWidget onClick={handleAddUrlShortner} disabled={false} />                                
                                    </Grid> 
                                </Box>
                                         

                                <Box style={{ marginTop: "15px" }}>
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Message Type</InputLabel>
                                        <Select
                                            style={{ minWidth: "200px" }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedRowCopy.MessageType}
                                            error={selectedRowCopy.DepartmentId==0}
                                            label="Message Type"
                                            // onChange={handleChange}
                                            onChange={(event) => { onChange(event, "MessageType") }}
                                        >
                                            <MenuItem value={"S"}>Text</MenuItem>
                                            <MenuItem value={"E"}>Email</MenuItem>
                                            <MenuItem value={"B"}>Text and Email</MenuItem>                                            
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box style={{ marginTop: "5px" }}>
                                    <Typography variant="h3">                                   
                                        <FormControlLabel
                                            control={
                                            <Switch
                                                id="isActive"
                                                checked={selectedRowCopy.isActive}
                                                onChange={(event) => { onChange(event, "isActive") }}
                                                name="isActive"
                                                color="primary"
                                            />
                                            }
                                            label="Active"
                                            labelPlacement="start"
                                        />
                                    </Typography>
                                </Box>

                                <Box mb={1} mt={2} display="flex">
                                    <Box flexGrow={1} />
                                                <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item  >
                                                    <Button onClick={DeleteSetting}  color="secondary" startIcon={<DeleteIcon />} variant="contained" disabled={hideDeletebutton || selectedRowCopy.DepartmentId!=selectedRow.DepartmentId }>
                                                        Delete
                                                    </Button>
                                                    </Grid>
                                                    <Grid item>
                                                    <Button onClick={() => (SaveChanges())} variant="contained" startIcon={<EditIcon />} color="primary">
                                                        Save
                                                    </Button>
                                                    </Grid>
                                                </Grid>
                                                </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Drawer>
                </Box>

            )}
        </Box>
    )
}
export default TextAutomation;


