import {
  Box,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import React, { ReactElement, useContext } from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';

import { SmallCircularProgress } from 'common/components/loaders';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import BroadCastAPI from 'components/broadcast/api/broadcast-api-v2';
import { MergeTag } from 'components/broadcast/models/merge-tag';
import AppSnackBar from 'components/snackbar/snackbar';

import 'emoji-mart/css/emoji-mart.css';
import _ from 'lodash';

interface MergeTagItemProps {
  searchText: string;
  template: MergeTag;
  anyEditable: boolean;
  setAnyEditable(template: MergeTag, editable: boolean): void;
  onUpdate(template: MergeTag): Promise<void>;
  onAdd(template: MergeTag): void;
  onDelete(template: MergeTag): Promise<void>;
}

export const useMessageTemplateStyle = makeStyles((theme: Theme): any => ({
  markColor: {
    backgroundColor: '#0027ff',
    color: 'white',
  },
}));

function useMessageTemplate(): {
  loading: boolean;
  messageTemplates: MergeTag[];
  setMessageTemplates: (templates: MergeTag[]) => void;
  updateMessageTemplate(messageTemplate: MergeTag): Promise<void>;
  deleteMessageTemplate(messageTemplate: MergeTag): Promise<void>;
  reOrder(dragIndex: number, hoverIndex: number): Promise<void>;
} {
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const [loading, setLoading] = React.useState(false);
  const [messageTemplates, setMessageTemplates] = React.useState<MergeTag[]>([]);

  async function handleUpdateMessageTemplate(template: MergeTag): Promise<void> {
    try {
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to update Merge Tag' });
    }
  }
  async function handleDeleteMessageTemplate(template: MergeTag): Promise<void> {
    try {
    } catch (error) {
      showSnackBar({ type: 'error', message: 'Unable to update Merge Tag' });
    }
  }

  const fetchMergeTags = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await BroadCastAPI.getMergeTags();
      setMessageTemplates(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showSnackBar({ type: 'error', message: 'Unable to fetch Merge Tags' });
    }
  }, [showSnackBar]);

  const reOrder = React.useCallback(
    async (dragItemIndex: number, hoverItemIndex: number): Promise<void> => {

    },
    [messageTemplates],
  );

  React.useEffect(() => {
    fetchMergeTags();
  }, [fetchMergeTags]);


  return {
    loading,
    messageTemplates,
    setMessageTemplates: setMessageTemplates,
    reOrder,
    updateMessageTemplate: handleUpdateMessageTemplate,
    deleteMessageTemplate: handleDeleteMessageTemplate,
  };

}

export function MergeTagWidgetWidget({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: (body: string) => void;
}): ReactElement {
  const [searchFilter, setSearchFilter] = React.useState('');
  const {
    loading: messageTemplatesLoading,
    messageTemplates,
    setMessageTemplates,
    updateMessageTemplate,
    deleteMessageTemplate,
    reOrder,
  } = useMessageTemplate();
  const [snippetAnchorEl, setSnippetAnchorEl] = React.useState(null);


  const openSnippet = Boolean(snippetAnchorEl);
  const idSnippet = openSnippet ? 'message-template-widget' : undefined;
  const classes: any = useMessageTemplateStyle({ backgroundColor: 'white' });
  function handleSnippetClose(): void {
    setSnippetAnchorEl(null);
  }

  function handleAddMessageTemplate(template: MergeTag): void {
    handleSnippetClose();
    onClick(template.body);
  }

  function handleSnippetClick(event: any): void {
    setSnippetAnchorEl(event.currentTarget);
  }

  function setEditable(template: MergeTag, editable: boolean): void {    
    const updatedTemplateList = messageTemplates?.map((t) => {
      if (t.id !== template.id) return t;
      return { ...t, editable: editable };
    });
    setMessageTemplates(updatedTemplateList);
  }

  function filterMessageTemplates(messageTemplate: MergeTag): boolean {
    return messageTemplate.body.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1 || messageTemplate.title.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
  }

  async function handleDrop(data: DropResult): Promise<void> {
    if (data.addedIndex !== null && data.removedIndex !== null) {
      //reOrder(data.addedIndex, data.removedIndex);
    }
  }

  const filteredMessageTemplates = messageTemplates.filter(filterMessageTemplates);
  const anyEditable = messageTemplates.some((t) => t.editable);
  const messageTemplateListItems = filteredMessageTemplates.map((template: MergeTag) => (
    <>
      <Draggable
        key={template.id}
        render={(): React.ReactElement => (
          <MergeTagListItem
            searchText={searchFilter}
            template={template}
            key={template.id}
            onAdd={handleAddMessageTemplate}
            onUpdate={updateMessageTemplate}
            onDelete={deleteMessageTemplate}
            anyEditable={anyEditable}
            setAnyEditable={(template, editable) => {
              setEditable(template, editable);
            }}
          />
        )}
      />

    </>
  ));
  const messageTemplatesNotFound = (
    <Box textAlign="center">
      <Typography variant="caption">No Quick messages found</Typography>
    </Box>
  );
  const loadingMessageTemplate = (
    <Box margin="auto" display="flex" justifyContent="center">
      <SmallCircularProgress />
    </Box>
  );
  let messageTemplatesListContent: any = messageTemplatesLoading ? loadingMessageTemplate : null;
  messageTemplatesListContent = filteredMessageTemplates.length ? messageTemplateListItems : messageTemplatesNotFound;

  return (
    <>
      <Tooltip title="Add Merge Tag">
        <IconButton
          style={{ padding: 0, marginTop: '.1rem' }}
          id="addMergeTagBtn"
          size="small"
          onClick={(e: any): void => {
            handleSnippetClick(e);
          }}
          disabled={disabled}>
      <SmartButtonIcon style={{ marginTop: '.1rem' }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={idSnippet}
        open={openSnippet}
        anchorEl={snippetAnchorEl}
        onClose={handleSnippetClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box p={1} border={1} borderColor="grey.300">
          <Box textAlign="center" fontWeight="bold">
            <Typography variant="h5">Personalize</Typography>
          </Box>
          <Box>
            <TextField
              fullWidth
              focused
              margin="dense"
              size="small"
              variant="outlined"
              placeholder="Search"
              onChange={({ target: { value } }): void => setSearchFilter(value)}
            />
          </Box>
        </Box>
        {/* <Box pt={1} px={1} color="grey.500" fontWeight="bold" fontSize="small">
          Create
        </Box>
        <CreateNewMessageTemplate onCreate={createMessageTemplate} /> */}
        <Box pt={1} px={1} color="grey.500" fontWeight="bold" fontSize="small">
          All
        </Box>
        <Box minHeight={200} maxHeight={350} width={350} overflow="scroll">
          <List dense>
            <Container onDrop={handleDrop}>{messageTemplatesListContent}</Container>
          </List>
        </Box>
      </Popover>
    </>
  );
}

const useMessageTemplateItemStyle = makeStyles(() => ({
  smallIconBtn: {
    padding: '8px',
  },
}));

function MergeTagListItem({
  searchText,
  template,
  anyEditable,
  setAnyEditable,
  onUpdate,
  onAdd,
  onDelete,
}: MergeTagItemProps): React.ReactElement {
  const classes: any = useMessageTemplateItemStyle();
  const [editableBody, setEditableBody] = React.useState(template.body);
  const [deleteToggle, setDeleteToggle] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setEditableBody(template.body);
  }, [template.body, template.id]);

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi');
    const parts = text ? text.split(regex) : [];
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <mark className={classes.markColor} key={i}>
                {part}
              </mark>
            ) : (
              <span key={i}>{part}</span>
            ),
          )}
      </span>
    );
  };

  async function handleDelete(): Promise<void> {
    try {
      setLoading(true);
      await onDelete(template);
      setDeleteToggle(false);
      // setEditable(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleUpdate(): Promise<void> {
    try {
      setLoading(true);
      await onUpdate({ ...template, body: editableBody });
      // setEditable(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  let content = null;
  if (template.editable) {
    content = (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={10}>
              <Typography variant="body1">
                <Box sx={{ fontWeight: 'bold' }}>
                  <Highlighted highlight={searchText} text={template.title}></Highlighted>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                size="small"
                onClick={(): void => setAnyEditable(template, false)}
                className={classes.smallIconBtn}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextLimitField
            style={{ width: '100%' }}
            characters={1500}
            variant="filled"
            multiline
            size="small"
            margin="dense"
            id="editSnippetBody"
            name="body"
            value={editableBody}
            onChange={(e: any): void => setEditableBody(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          {!deleteToggle && (
            <Grid container direction="row" justifyContent="flex-end">
              {/*
              <IconButton
                className={classes.smallIconBtn}
                edge="end"
                id="deleteSnippetToggle"
                aria-label="delete"
                onClick={(): void => setDeleteToggle(true)}>
                <DeleteIcon />
              </IconButton>

              <IconButton
                id="editSnippetBtn"
                className={classes.smallIconBtn}
                edge="end"
                aria-label="edit"
                onClick={handleUpdate}>
                <SaveIcon />
              </IconButton>
              */}
            </Grid>
          )}
          {deleteToggle && (
            <Grid container direction="row" justifyContent="flex-end">
              <Typography style={{ marginTop: '9px' }}>Are you sure you want to delete this?</Typography>
              <IconButton
                id="deleteSnippetCancelBtn"
                onClick={(): void => setDeleteToggle(false)}
                aria-label={`Add ${template.title}`}
                className={classes.smallIconBtn}>
                <ArrowBackIcon />
              </IconButton>
              {/*
              <IconButton
                id="deleteSnippetConfirmBtn"
                className={classes.smallIconBtn}
                edge="end"
                aria-label="delete"
                onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
              */}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Box sx={{ fontWeight: 'bold', mt: 1 }}>
                <Highlighted highlight={searchText} text={template.title}></Highlighted>
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ListItemText
              primary={<Highlighted highlight={searchText} text={template.body}></Highlighted>}
              primaryTypographyProps={{
                style: {
                  maxWidth: '400px',
                  marginTop: '8px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            />
            <IconButton
              className={`${classes.smallIconBtn} UseSnippetBtn`}
              edge="end"
              size="small"
              aria-label="add"
              onClick={(): void => onAdd(template)}>
              <AddCircleIcon />
            </IconButton>
            {/*
            <IconButton
              className={`${classes.smallIconBtn} editSnippetToggle`}
              edge="end"
              size="small"
              aria-label="edit"
              onClick={(): void => {
                setAnyEditable(template, true);
              }}>
              <EditIcon />
            </IconButton>
            */}
          </Grid>
        </Grid>
      </>
    );
  }

  if (loading) {
    content = <SmallCircularProgress />;
  }
  let listItemContent = (
    <ListItem button dense>
      {content}
    </ListItem>
  );

  if (!anyEditable) {
    listItemContent = <Draggable>{listItemContent}</Draggable>;
  }

  if (template.editable) {
    return listItemContent;
  }
  return (
    <Tooltip title={<Typography variant="h5">{template.body}</Typography>} placement="right" arrow>
      {listItemContent}
    </Tooltip>
  );
}

export default MergeTagWidgetWidget;
