import config2 from 'config';
import Menu from '@mui/material/Menu';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { store } from 'app';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@material-ui/core";
import { Box, Container, Grid, makeStyles, MenuItem, Paper, Theme,Tab } from '@material-ui/core';
import { pink, teal } from '@material-ui/core/colors';
import React, { useState } from 'react';
import { useAppSelector } from 'app';

import { SmallCircularProgress } from 'common/components/loaders';
import { PageHeader } from 'common/components/layout';
import useLocations from 'common/hooks/useLocations';
import DealerAPI from 'components/authentication/api/dealer-api';
import SurveyAPI, { SurveysStatistics } from 'components/survey/api/survey-api';
import { DatePicker, DateTimePicker, LocalizationProvider , TabContext,TabList,TabPanel } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import DashboardAPI from '../api/dashboard-api';
// import { textAlign } from '@mui/system';
import { formatPhoneNumber } from 'common/utils';
import UserActivity from './userActivity';
import { Drawer } from '@mui/material';
import CustomerEditDrawer from 'components/messaging/components/customer-edit-drawer';
import TextAutomation from 'components/setting/components/text-automation/text-automation';
import { DmsTypeEnum } from 'common/enums/DmsTypeEnum';

// const useStyles2 = makeStyles((theme: Theme): any => ({
// test:{
//   border
// }
// }));
const useStyles = makeStyles((theme: Theme): any => ({
  minDepartmentPhone: {
    display: "none",
    "@media (max-width: 1300px)": {
      display: "block",
      whiteSpace: "pre-wrap",
      color: "gray"
    }

  },
  myCardWidth: {

    // margin:"20px"
    marginRight: "15px !important",
    marginBottom: "3px !important",
    width: "225px",
    // margin:"0px 20px", 
    // marginLeft:"30px",
    "@media (max-width: 1300px)": {
      width: "200px",
      //width:"100%",
      //     margin:"0px 20px",
      //     marginLeft:"30px"
    }
  },

  test: {
    maxWidth: "100px",

  },
  // myTasksHeader: {
  //   display: "table-cell",
  //   fontSize: "medium",
  //   // borderBottom: "1px solid #EAEDED",
  //   backgroundColor: "white",
  //   // border: "1px solid green",
  //   minWidth: "115px !important",
  //   // width: "100px !important",
  //   // maxWidth:"75px !important",
  //   textAlign: "center",

  // },
  myTasks: {
    '& td': {
      fontSize: "medium",
      borderBottom: "1px solid #EAEDED",
      backgroundColor: "white",
      border: "px solid red",
      minWidth: "85px !important",
      // maxWidth:"75px !important",
      textAlign: "center",

      // maxWidth:"350px !important",
      // minWidth:"350px !important",
      // borderBottom: "1px solid red",

      // whiteSpace: "nowrap",
      // height: "25px"
    },

  },
  myCard: {
    display: "table-cell",
    borderStyle: "solid",
    // borderBottomStyle:"outset",
    // padding: "3px 10px",
    fontFamily: "inherit",
    minWidth: "100px",
    borderColor: "#F0F3F4",
    borderBottomColor: "gray",
    borderBottomWidth: "1px",
    // minWidth:"100px !important",
    // maxWidth:"100px !important",

  },
  root: {

    // // '& .MuiAccordionSummary-root':{
    // //   border:"1px solid blue",
    // //   minHeight: 0,
    // //   padding:"0px",
    // //   margin:"0px",
    // // },    
    // '& .MuiAccordionSummary-gutters':{
    //   border:"1px solid blue",
    //   minHeight: 0,
    //   padding:"0px",
    //   margin:"0px",
    // },    

    // '& .MuiAccordionSummary-contentGutters':{
    //   border:"1px solid green",
    //   minHeight: 0,
    //   padding:"0px",
    //   margin:"0px",
    // },    
    // '& .MuiAccordionSummary-content':{
    //   border:"1px solid purple",
    //   minHeight: 0,
    //   padding:"0px",
    //   margin:"0px",
    // },
    '& .Mui-expanded': {
      // minHeight: 0,
      // padding:"0px",
      // margin:"0px",
      // border:"0px solid red",
      // backgroundColor: "yellow",
      '& .MuiAccordionSummary-gutters': {
        border: "0px solid blue",
        // minHeight: 0,
        minHeight: "30px",
        //padding:"0px",
        //margin:"0px",
      },
      '& .MuiAccordionSummary-contentGutters': {
        // border:"1px solid green",
        // minHeight: 0,
        // padding:"0px",
        // margin:"0px",
      },
      '& .MuiAccordionSummary-content': {
        border: "0px solid purple",
        minHeight: 0,
        padding: "0px",
        margin: "0px",
      },

    },
    //width: '100%',
    //border:"4px solid green"
  },
  myTable: {


    '& th': {
      fontSize: "medium",
    },
    '& td': {
      fontSize: "medium",
      borderBottom: "1px solid #EAEDED",
      backgroundColor: "white"
      // borderBottom: "1px solid red",

      // whiteSpace: "nowrap",
      // height: "25px"
    },
    // '& tr:nth-child(even)': { backgroundColor: "#FBFCFC" }
  },
  // 'Mui-expanded': {
  //   //minHeight: 0,
  //   border:"4px solid blue"
  //   },
  // 'MuiAccordionSummary-content': {
  //   border:"3px solid red",
  // },
  message: {
    width: '100px',
    minWidth: '100px',
    // border: '1px solid red;',
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    fontFamily: "Quicksand !important",

  },
  bold: {
    fontWeight: "bold !important",
  },
  phone: {
    //width: '100px',
    //minWidth: '100px',
    // border: '1px solid red;',
    textAlign: "center",
    padding: "0px",
    margin: "0px",
    display: "table-cell",
    "@media (max-width: 1300px)": {
      display: "none"
    }


  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 320,
  },
  dashboardPaper: {
    backgroundColor: pink[300],
  },
  unHappyCustomerCard: {
    backgroundColor: pink[200],
  },
  happyCustomerCard: {
    backgroundColor: teal[200],
  },
  cardButton: {
    height: '100%',
    minWidth: '50px',
  },
  detractor: {
    backgroundColor: 'red',
    color: 'white'
  },
  passive: {
    backgroundColor: 'orange',
    color: 'white'
  },
  promoter: {
    backgroundColor: 'green',
    color: 'white'
  },
  loyaltyScore: {
    backgroundColor: '#F4F6F6',
  },
  totalFeedback: {
    backgroundColor: 'gray',
    color: 'white',
    // maxWidth: "100px"
  }

}));

function DashboardCard({
  id,
  title,
  value,
  className,
  prefix,
  postfix,
}: {
  id: string;
  title: string;
  value: string;
  className?: string;
  prefix?: string;
  postfix?: string;
}): React.ReactElement {
  let displayValue: React.ReactElement | string = <SmallCircularProgress />;

  if (value !== 'loading') {
    displayValue = value;
    if (postfix && value !== 'N/A') {
      displayValue += postfix;
    }
    if (prefix && value !== 'N/A') {
      displayValue = prefix + displayValue;
    }
  }
  return (
    <Paper className={className || ''} id={id}>
      <Box p={2}>
        <Typography variant="overline" component="h3" gutterBottom style={{ fontFamily: "Quicksand" }}>
          {title}
        </Typography>
        <Box>
          <Typography id={`${id}_value`} variant="h6" style={{ fontFamily: "Quicksand" }}>
            {displayValue}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}

let accordExpanded = [] as any;
function DashboardPage(): React.ReactElement {
  const classes: any = useStyles();
  const [surveyStatistics, setSurveyStatistics] = React.useState<SurveysStatistics>({
    responseCount: 'N/A',
    promotersPercentage: 'N/A',
    detractorsPercentage: 'N/A',
    loyaltyScore: 'N/A',
  });
  const [tabValue , settabValue] = useState('1')
  const handleTabChange = (event:  React.SyntheticEvent , newValue : string)=>{
    settabValue(newValue)
  }
  const [dataDashBoard, setDataDashBoard] = React.useState([] as any);
  const [dataDWDashBoard, setDataDWDashBoard] = React.useState([] as any);
  const [totalNewCustomers, setTotalNewCustomers] = React.useState<string>('N/A');
  const [messageCount, setMessageCount] = React.useState<string>('N/A');
  const user = useAppSelector((state) => state.user);
  const dmsDealerIdCrm = useAppSelector((state) => state.user.dmsDealerId);
  const { loading: locationLoading, locationOptions, defaultLocationOption } = useLocations(dmsDealerIdCrm);
  const [dealerLocationId, setDealerLocationId] = React.useState<number | undefined>(
    defaultLocationOption?.dealerLocationId,
  );
  const [expanded, setExpanded] = React.useState(false);
  const [expandedMain, setExpandedMain] = React.useState(false);
  const [showNewDashboard, setShowNewDashboard] = React.useState(true);
  const [startDate, setStartDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 6)));
  //const [startDate, setStartDate] = React.useState(new Date() as any);
  const [endDate, setEndDate] = React.useState(new Date());
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [customerClickCount, setCustomerClickCount] = React.useState(1) as any;
  const [openUserActivity, setOpenUserActivity] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoading2, setIsLoading2] = React.useState(true);
  const [anchorElMore, SetAnchorElMore] = React.useState(null);
  const [openAnchorElMore, SetOpenAnchorElMore] = React.useState(false);
  const [openGravityTextToPay, SetOpenGravityTextToPay] = React.useState(false);
  const [openTextAutomation, SetOpenOpenTextAutomation] = React.useState(false);
  const [dashboardClickCount, SetDashboardClickCount] = React.useState(0);
  const [selectedCustomerId, SetSelectedCustomerId] = React.useState<number>(0);
    let dmsTypeId = useAppSelector((state) => state.user.dmsTypeId);
    let userPermissions = useAppSelector((state) => state.user?.userPermissions);
    let isMessengerPermission = 0;
    let isTaskPermission = 0;
    let isCustomerPermission = 0;
    let isInvoicePermission = 0;
    if (!userPermissions) {
      userPermissions = [];
      isMessengerPermission = 2;
      isTaskPermission = 2;
      isCustomerPermission = 2;
      isInvoicePermission = 2;
    }  
    if (isMessengerPermission == 0) {
      if (userPermissions.find((x) => x.featureName.toLowerCase() === 'messages')) {
        isMessengerPermission = 1;
     } 
   }
   if (isInvoicePermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'invoice')) {
     isInvoicePermission = 1;
   } 
 }
   if (isCustomerPermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'customers')) {
      isCustomerPermission = 1;
   } 
 }
   if (isTaskPermission == 0) {
    if (userPermissions.find((x) => x.featureName.toLowerCase() === 'tasks')) {
        isTaskPermission = 1;
    }
}

  React.useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("message", onMessage, false);
    }
  }, []);
    
  function onMessage(event: any) {
    let data = event.data;
    if (data.func == 'LoadCustomerGravity') {
      if (data.message != undefined && data.message != '') {
        const objMessage = JSON.parse(data.message);
        if (objMessage.Note != undefined && objMessage.Note != '') {
          const objNote = JSON.parse(objMessage.Note);
          SetSelectedCustomerId(objNote.CrmCustomerId);
        }
      }
    }
  }

  React.useEffect(() => {
    if (isNaN(startDate.getTime())) return;
    if (isNaN(endDate.getTime())) return;
    if (startDate.getFullYear() < 1900) return;
    if (endDate.getFullYear() > 2099) return;
    setIsLoading(true);
    setIsLoading2(true);
    GetDashBoard();
    GetDWDashBoard(); 
    //alert('useeffect');
    //const env = config2.get('env');
    //alert(env);

  }, [startDate, endDate]);

  async function GetDashBoard(): Promise<void> {
    let obj: any = new Object();
    obj.Dealerid = store.getState().user.dealerId;
    obj.ForAllLocation = 1;
    obj.StartDate = startDate.toISOString().slice(0, 10);
    obj.EndDate = endDate.toISOString().slice(0, 10);
    //obj.EndDate = endDate;
    let jsonString = JSON.stringify(obj);
    //alert(startDate.toISOString().slice(0, 10));
    //alert(jsonString);
    //alert(startDate.getYear());
    //const response = await SurveyAPI.GetDashboard('{"Dealerid":"10325", "ForAllLocation":1, "StartDate":"04-01-2022"}');
    //let response = await SurveyAPI.GetDashboard(jsonString);
    const response = await DealerAPI.ExecuteJsonSP("Dealers.[spDashboard]", jsonString);
    // alert('test');
    setIsLoading(false);
    if (response != null && typeof response[0] === 'object') {
      if (response[0].SurveyData == undefined) {
        response[0].SurveyData = [{ "DealerLocationId": 0, "DealerLocationNickName": "", "ResponseCount": 0, "Detractor": 0, "Passive": 0, "Promoters": 0, "PromotersPercentage": 0.00, "PassivePercentage": 0.00, "DetractorPercentage": 0.00, "LoyaltyScore": 86 }];
     }
      setDataDashBoard(response[0]);
      }

  }

  async function GetDWDashBoard(): Promise<void> {
    //alert(store.getState().user.dealerId);
    // alert(store.getState().user.dmsDealerId);
    // alert(store.getState().user.dmsTypeId);

    let obj: any = new Object();
    obj.DealerId = store.getState().user.dealerId;
    obj.ForAllLocation = 1;
    obj.StartDate = startDate.toISOString().slice(0, 10);
    obj.EndDate = endDate.toISOString().slice(0, 10);
    let jsonString = JSON.stringify(obj);

    //let url = `http://localhost:7071/api/TargetDashboard/${jsonString}`;
    let url = `https://azurefunctionkg.azurewebsites.net/api/TargetDashboard/${jsonString}?code=6utOavnVBwVzW5LdYAzBd7wcUgZAjpZuJoaI0YydJQIxIGrQ6aKgaA==`;

    //alert(url);
    fetch(url)
      .then(response => response.json())
      .then(data =>
      (
        //alert(data.length),
        setDataDWDashBoard(data),
        setIsLoading2(false)
      )
      ).catch(
      //error => alert('Error: ' + error)
    );
  }




  React.useEffect(() => {
    setDealerLocationId(defaultLocationOption?.dealerLocationId);
  }, [defaultLocationOption]);

  React.useEffect(() => {
    async function fetchDealerSettings(): Promise<void> {
      try {
        setMessageCount('loading');
        const response = await DealerAPI.getDealerPhoneSetting(dmsDealerIdCrm);
        if (response.messageCount) {
          setMessageCount(response.messageCount.toLocaleString());
        }
      } catch (error) {
        setMessageCount('N/A');
      }
    }
    fetchDealerSettings();
  }, [user, dmsDealerIdCrm]);

  React.useEffect(() => {
    async function fetchReports(): Promise<void> {
      if (dealerLocationId !== undefined && dealerLocationId >= 0 && user) {
        try {

          // console.log('store.getState()');
          // console.log(store.getState());
          const response = await DashboardAPI.getAllReports(dealerLocationId);
          setTotalNewCustomers(response.totalNewCustomers.toString());
        } catch (error) {
          setTotalNewCustomers('N/A');
        }
      }
    }
    fetchReports();
  }, [dealerLocationId, user]);


  React.useEffect(() => {
    async function fetchSurveysStatistics(): Promise<void> {
      if (dealerLocationId !== undefined && dealerLocationId >= 0 && user) {
        try {
          setSurveyStatistics({
            responseCount: 'loading',
            promotersPercentage: 'loading',
            detractorsPercentage: 'loading',
            loyaltyScore: 'loading',
          });
          const response = await SurveyAPI.getSurveyStatistics(user.dealerId, dealerLocationId);
          setSurveyStatistics(response);
        } catch (error) {
          setSurveyStatistics({
            responseCount: 'N/A',
            promotersPercentage: 'N/A',
            detractorsPercentage: 'N/A',
            loyaltyScore: 'N/A',
          });
        }
      }
    }
    fetchSurveysStatistics();
  }, [dealerLocationId, user]);


  // function GetMessageTotal(key: string, location: string) {
  //   let r = 0;
  //   let items = dataDashBoard.MessageData;
  //   if (location != '') items = dataDashBoard.MessageData.filter((x: any) => x.DealerLocationNickName === location)
  //   for (let i = 0; i < items.length; i++) {
  //     r += items[i][key];
  //   }
  //   return r;
  // }

  function GetTaskLocation() {
    const uniqueLocations = Array.from(new Set(dataDashBoard.Tasks.map((item: any) => item.DealerId)));
    return uniqueLocations;
  }

  function GetTaskLocationUserName(userId: any) {
    let items = dataDashBoard.Tasks.filter((x: any) => x.UserID === userId);
    if (items.length > 0) {
      return items[0].FullName;
    }
  }
  function GetTaskLocationUser(dealerId: any) {
    let items = dataDashBoard.Tasks;
    items = dataDashBoard.Tasks.filter((x: any) => x.DealerId === dealerId)
    const uniqueLocationUsers = Array.from(new Set(items.map((item: any) => item.UserID)));
    return uniqueLocationUsers;
  }

  function GetTaskTotal(key: string, DealerId: number, userId: number = 0) {
    let r = 0;
    let items = dataDashBoard.Tasks;
    if (DealerId > 0) items = dataDashBoard.Tasks.filter((x: any) => x.DealerId === DealerId)
    if (userId > 0) items = items.filter((x: any) => x.UserID === userId)
    for (let i = 0; i < items.length; i++) {
      r += items[i][key];
    }
    return r;
  }

  function GetMessageTotal(key: string, DealerId: number, DepartmentId: number) {
    let r = 0;
    let items = dataDashBoard.MessageData;
    if (DealerId > 0) items = dataDashBoard.MessageData.filter((x: any) => x.DealerId === DealerId)
    if (DepartmentId > 0) items = dataDashBoard.MessageData.filter((x: any) => x.DepartmentId === DepartmentId)
    for (let i = 0; i < items.length; i++) {
      r += items[i][key];
    }
    return r;
  }


  function GetMessageName() {
    for (let i = 0; i < dataDashBoard.MessageData.length; i++) {
      return dataDashBoard.MessageData[i]['Name'];
    }
    return '';
  }
  function GetMessageLocations(): any {
    let locations = [] as any;
    for (let i = 0; i < dataDashBoard.MessageData.length; i++) {
      let name = dataDashBoard.MessageData[i]['DealerLocationNickName'];
      if (locations.length == 0 || locations.filter((x: any) => x.Name === name).length == 0) {
        locations.push({ DealerId: dataDashBoard.MessageData[i]['DealerId'], Name: name })
      }
    }
    return locations;
  }

  function GetMessageDepartments(DealerId: number): any {
    let items = dataDashBoard.MessageData.filter((x: any) => x.DealerId === DealerId)
    return items;
  }

  function GetSurveyPercent(value: number, count: number): number {
    //alert(count);
    if (count == 0) return 0
    let ret = (value / count) * 100.00;
    //alert( ret.toFixed(3));
    return parseFloat(ret.toFixed(2));
    //return ret.toString();
  }
  function GetSurveyTotal(key: string, DealerId: number): number {
    //alert(DealerId);
    let r = 0;
    let items = dataDashBoard.SurveyData;
    if (DealerId > 0) items = dataDashBoard.SurveyData.filter((x: any) => x.DealerLocationId === DealerId)
    // if (DepartmentId > 0) items = dataDashBoard.MessageData.filter((x: any) => x.DepartmentId === DepartmentId)
    for (let i = 0; i < items.length; i++) {
      r += items[i][key];
    }
    //alert(r);
    return r;
    //return r.toString() ;
  }

  function GetSurveyLocations(): any {
    let locations = [] as any;
    for (let i = 0; i < dataDashBoard.SurveyData.length; i++) {
      let name = dataDashBoard.SurveyData[i]['DealerLocationNickName'];
      if (locations.length == 0 || locations.filter((x: any) => x.Name === name).length == 0) {
        locations.push({ DealerLocationId: dataDashBoard.SurveyData[i]['DealerLocationId'], Name: name })
      }
    }
    return locations;
  }



  const handlerAccordMainChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpandedMain(isExpanded ? panel : false);
  };


  function handlerAccordClick(id: number) {

    if (accordExpanded.length == 0) {
      accordExpanded.push({ id: id, value: true });
      setExpanded(!expanded);
      return;
    }
    let item = accordExpanded.filter((x: any) => x.id == id)
    if (item.length == 0) {
      accordExpanded.push({ id: id, value: true });
      setExpanded(!expanded);
      return;
    }
    if (item.length == 1) {
      item[0].value = !item[0].value;
      setExpanded(!expanded);
    }
  }

  function GetAccordExpanded(id: number): boolean {
    let item = accordExpanded.filter((x: any) => x.id == id)
    if (item.length == 0) return false;
    return item[0].value;
  }

  function GetLocationName(dealerId: number): string {
    let item: any = locationOptions?.filter((x: any) => x.dealerLocationId == dealerId);
    if (item?.length > 0) {
      return item[0].name;
    }
    return 'n/a';
  }

  function handleCustomerclick() {
    //const [customerClickCount, setCustomerClickCount] = React.useState(1) as any;
    setCustomerClickCount(customerClickCount + 1);
    if (customerClickCount > 5) setCustomerClickCount(2);

  }


  const handleMoreClick = (event: any) => {
    SetAnchorElMore(event.currentTarget);
    SetOpenAnchorElMore(true);
  }

  function OpenGravityTextToPay() {
    //alert('OpenGravityTextToPay');
    SetOpenAnchorElMore(false);
    SetOpenGravityTextToPay(true);
  }

  return (
    <Box>
      <TabContext value={tabValue}>
          <Box sx={{ borderBottom:1, borderColor:'black' ,bgcolor:'white'}}>
            <TabList aria-label='Tabs' onChange={handleTabChange}>
              <Tab label='Analytics' value='1'></Tab>
              {isMessengerPermission == 1 ? 
              <Tab label='User Activity' value='2'></Tab>
              : ""
              }
            </TabList>
          </Box>
          <TabPanel value='1'>
            <Paper square style={{ marginBottom: 10 }}>
            <Box display="flex" justifyContent="center" alignItems="center" >
              <Box style={{ marginTop: "10px", minWidth: "1100px" }}>
            {/* <Container maxWidth="lg" className={classes.container}> */}
            <Box display="flex" flexDirection="row">

              <Box flexGrow={1} onClick={(event) => { SetDashboardClickCount(dashboardClickCount + 1) }}>
                <PageHeader title="Dashboard" />
              </Box>

              {!showNewDashboard && (
                <Box ml={1}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    margin="dense"
                    variant="outlined"
                    name="location"
                    label="Location"
                    disabled={locationLoading}
                    value={dealerLocationId}
                    inputProps={{ id: 'location' }}
                    onChange={({ target: { value } }): void => setDealerLocationId(parseInt(value, 10))}>
                    {locationOptions &&
                      locationOptions.map((loc) => (
                        <MenuItem key={loc.dealerLocationId} value={loc.dealerLocationId}>
                          {loc.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              )}
              {showNewDashboard && (
                <table>
                  <tr>
                    <td>
                      <Box ml={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            minDate={new Date('1900-01-01')}
                            value={startDate}
                            onError={() => {
                              console.log('error');
                            }}
                            onChange={(newValue: any) => { setStartDate(newValue); }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                        <span style={{ marginLeft: "10px" }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DatePicker
                              minDate={new Date('1900-01-01')}
                              value={endDate}
                              onError={() => {
                                console.log('error');
                              }}
                              onChange={(newValue: any) => {
                                setEndDate(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}

                            />
                          </LocalizationProvider>
                        </span>

                      </Box>
                    </td>
                    <td>
                      {dashboardClickCount > 5 && (
                        <Box>
                          <IconButton onClick={handleMoreClick}>
                            <MoreVertIcon></MoreVertIcon>
                          </IconButton>

                        </Box>
                      )}
                    </td>
                  </tr>
                </table>



              )}

            </Box>
            {!showNewDashboard && (
              <Box>
                <Box mt={2}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs>
                      <DashboardCard id="loyaltyScore" title="Loyalty Score" value={surveyStatistics.loyaltyScore} />
                    </Grid>
                    <Grid item xs>
                      <DashboardCard id="surveyResponse" title="Survey Responses" value={surveyStatistics.responseCount} />
                    </Grid>
                    <Grid item xs>
                      <DashboardCard
                        id="promoters"
                        title="Promoters"
                        value={surveyStatistics.promotersPercentage}
                        postfix="%"
                        className={classes.happyCustomerCard}
                      />
                    </Grid>
                    <Grid item xs>
                      <DashboardCard
                        id="detractors"
                        title="Detractors"
                        value={surveyStatistics.detractorsPercentage}
                        postfix="%"
                        className={classes.unHappyCustomerCard}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs>
                      <DashboardCard id="totalNewCustomers" title="New Customers" value={totalNewCustomers} />
                    </Grid>
                    <Grid item xs>
                      <DashboardCard id="messageCredits" title="Message Credits" prefix="" value={messageCount} />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {/* <Button onClick={KGTest}>Test KG</Button> */}
            {showNewDashboard && (
              <Box style={{ marginTop: "10px" }}>

                {typeof (dataDashBoard.SurveyData) !== 'undefined' && dataDashBoard.SurveyData != null && (
                  <Box style={{ marginTop: "15px" }}>
                    {/* <div style={{ display: "table" }}> */}
                    <Accordion className={classes.root} expanded={GetAccordExpanded(9999)} onChange={(event) => { handlerAccordClick(9999) }}>
                      {/* <Accordion expanded={true} onChange={handlerAccordMainChange('panel1')}> */}
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        {/* <Typography sx={{ width: '250px', flexShrink: 0 }}>FeedBacks</Typography> */}
                        {/* <Typography sx={{ color: 'text.secondary' }} style={{ minWidth: "225px" }}> */}
                        {/* myCardWidth */}
                        {/* <Typography sx={{ color: 'text.secondary' }} style={{width: '100%', minWidth:'200px', maxWidth:"300px"  }}></Typography> */}
                        <Typography sx={{ color: 'text.secondary' }} className={classes.myCardWidth}>
                          <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              <DashboardCard id="ResponseCount" className={classes.totalFeedback} title="Total Feedback" value={GetSurveyTotal('ResponseCount', 0).toString()} />
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }} className={classes.myCardWidth}>
                          <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs >
                              <DashboardCard id="ResponseCount" title="Detractors" className={classes.detractor} value={`${GetSurveyTotal('Detractor', 0)} (${GetSurveyPercent(GetSurveyTotal('Detractor', 0), GetSurveyTotal('ResponseCount', 0))}%)`} />
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }} className={classes.myCardWidth}>
                          <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              {/* <DashboardCard id="ResponseCount" title="Passive" value={`${GetSurveyTotal('Passive', 0)}`} /> */}
                              <DashboardCard id="ResponseCount" title="Passive" className={classes.passive} value={`${GetSurveyTotal('Passive', 0)} (${GetSurveyPercent(GetSurveyTotal('Passive', 0), GetSurveyTotal('ResponseCount', 0))}%)`} />
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }} className={classes.myCardWidth} >
                          <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              {/* <DashboardCard id="ResponseCount" title="Promoters" value={`${GetSurveyTotal('Promoters', 0)}`} /> */}
                              <DashboardCard id="ResponseCount" title="Promoters" className={classes.promoter} value={`${GetSurveyTotal('Promoters', 0)} (${GetSurveyPercent(GetSurveyTotal('Promoters', 0), GetSurveyTotal('ResponseCount', 0))}%)`} />
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }} className={classes.myCardWidth}>
                          <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              {/* <DashboardCard id="ResponseCount" title="Promoters" value={`${GetSurveyTotal('Promoters', 0)}`} /> */}
                              <DashboardCard id="ResponseCount" title="Loyalty Score" className={classes.loyaltyScore} value={`${Math.trunc(GetSurveyPercent(GetSurveyTotal('Promoters', 0), GetSurveyTotal('ResponseCount', 0))) - Math.trunc(GetSurveyPercent(GetSurveyTotal('Detractor', 0), GetSurveyTotal('ResponseCount', 0)))}`} />
                            </Grid>
                          </Grid>
                        </Typography>

                      </AccordionSummary>
                      <AccordionDetails style={{ borderStyle: "outset", border: "0px solid silver" }}>
                        <Box>
                          {GetSurveyLocations().map((row: any) => {
                            return (
                              <Box sx={{ display: 'flex' }}                          >
                                {/* <div>
                                <div style={{ display: "block", margin: "0px", padding: "0px", border: "1px solid #F4F6F6", width:"100%" }}> */}
                                {/* <div style={{ display: "table-row" }} > */}
                                <Typography className={classes.myCardWidth}>
                                  <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                                    <Grid item xs>
                                      <DashboardCard id="ResponseCount" className={classes.totalFeedback} title={row.Name} value={GetSurveyTotal('ResponseCount', row.DealerLocationId).toString()} />
                                    </Grid>
                                  </Grid>
                                </Typography>
                                <Typography className={classes.myCardWidth}>
                                  <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                                    <Grid item xs>
                                      <DashboardCard id="ResponseCount" className={classes.detractor} title={row.Name} value={`${GetSurveyTotal('Detractor', row.DealerLocationId)} (${GetSurveyPercent(GetSurveyTotal('Detractor', row.DealerLocationId), GetSurveyTotal('ResponseCount', row.DealerLocationId))}%)`} />
                                    </Grid>
                                  </Grid>
                                </Typography>
                                <Typography className={classes.myCardWidth}>
                                  <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                                    <Grid item xs>
                                      <DashboardCard id="Passive" className={classes.passive} title={row.Name} value={`${GetSurveyTotal('Passive', row.DealerLocationId)} (${GetSurveyPercent(GetSurveyTotal('Passive', row.DealerLocationId), GetSurveyTotal('ResponseCount', row.DealerLocationId))}%)`} />
                                    </Grid>
                                  </Grid>
                                </Typography>
                                <Typography className={classes.myCardWidth}>
                                  <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                                    <Grid item xs>
                                      <DashboardCard id="Promoters" className={classes.promoter} title={row.Name} value={`${GetSurveyTotal('Promoters', row.DealerLocationId)} (${GetSurveyPercent(GetSurveyTotal('Promoters', row.DealerLocationId), GetSurveyTotal('ResponseCount', row.DealerLocationId))}%)`} />
                                    </Grid>
                                  </Grid>
                                </Typography>
                                <Typography className={classes.myCardWidth}>
                                  <Grid style={{ paddingLeft: "0px" }} container spacing={2} justifyContent="space-between">
                                    <Grid item xs>
                                      <DashboardCard id="ResponseCount" className={classes.loyaltyScore} title={row.Name} value={`${Math.trunc(GetSurveyPercent(GetSurveyTotal('Promoters', row.DealerLocationId), GetSurveyTotal('ResponseCount', row.DealerLocationId))) - Math.trunc(GetSurveyPercent(GetSurveyTotal('Detractor', row.DealerLocationId), GetSurveyTotal('ResponseCount', row.DealerLocationId)))}`} />
                                    </Grid>
                                  </Grid>
                                </Typography>
                                {/* </div> */}
                                {/* </div>
                              </div> */}
                              </Box>
                            )

                          })}
                        </Box>

                      </AccordionDetails>

                    </Accordion>
                    {/* </div> */}
                  </Box>
                )}
                {typeof (dataDashBoard.Tasks) !== 'undefined' && dataDashBoard.Tasks != null && dataDashBoard.Tasks.length > 0 && isTaskPermission == 1 && (
                  // <Accordion className={classes.root} expanded={GetAccordExpanded(row.DealerId)} onChange={(event) => { handlerAccordClick(row.DealerId) }}></Accordion>
                  // <Accordion className={classes.root} expanded={true} onChange={(event) => { handlerAccordClick(0) }}></Accordion>
                  // {

                  GetTaskLocation().map((dealerId: any) => {
                    return (
                      <Accordion style={{ marginTop: "20px",borderRadius:"4px" }} className={classes.root} expanded={GetAccordExpanded(9997)} onChange={(event) => { handlerAccordClick(9997) }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >

                          {/* <div style={{ display: "table-row" }}>
                            <div className={classes.myTasksHeader} >
                              <div style={{textAlign: "center" }}>Total Tasks</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Total', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>Open</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('New', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>Closed</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Closed', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>30 Days</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Days30', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>60 Days</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Days60', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>90 Days</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Days90', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>180 Days</div>
                              <div style={{ textAlign: "center" }}>{GetTaskTotal('Days180', dealerId).toString()}</div>
                            </div>
                            <div className={classes.myTasksHeader}>
                              <div style={{textAlign: "center" }}>Location</div>
                              <div style={{ textAlign: "center" }}>{GetLocationName(dealerId)}</div>
                            </div>
                          </div> */}
                          <div style={{ display: "table", border: "0px solid red" }}>
                            <Table size={'small'} className={classes.myTasks} style={{ fontSize: "medium", border: "0px solid red" }}>
                              {/* <TableHead> */}


                              <TableRow >
                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>Total Tasks</div>
                                  <div>{GetTaskTotal('Total', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell >
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>Open</div>
                                  <div>{GetTaskTotal('New', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell >
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>Overdue</div>
                                  <div>{GetTaskTotal('Overdue', dealerId).toString()}</div>
                                </TableCell>

                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>Closed</div>
                                  <div>{GetTaskTotal('Closed', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>30 Days</div>
                                  <div>{GetTaskTotal('Days30', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>60 Days</div>
                                  <div>{GetTaskTotal('Days60', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>90 Days</div>
                                  <div>{GetTaskTotal('Days90', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell>
                                  <div style={{ fontWeight: "bold", paddingBottom: "5px" }}>180 Days</div>
                                  <div>{GetTaskTotal('Days90', dealerId).toString()}</div>
                                </TableCell>
                                <TableCell style={{ textAlign: "left", verticalAlign: "top" }} valign="top" >

                                  {/* <span style={{ minWidth: "150px !important" }}>
                                      <div>Location</div>
                                      <div>{GetLocationName(dealerId)}</div>
                                    </span> */}
                                </TableCell>

                              </TableRow> 
                              {/* </TableHead> */}
                            </Table>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ borderStyle: "outset", border: "0px solid silver" }}>
                          <Paper style={{ display: "table" }}>
                            <Table size={'small'} className={classes.myTasks} style={{ fontSize: "medium" }}>
                              {GetTaskLocationUser(dealerId).map((user: any) => {
                                return (
                                  <TableRow>
                                    <TableCell>{GetTaskTotal('Total', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('New', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Overdue', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Closed', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Days30', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Days60', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Days90', dealerId, user).toString()}</TableCell>
                                    <TableCell>{GetTaskTotal('Days90', dealerId, user).toString()}</TableCell>
                                    <TableCell style={{ textAlign: "left" }} ><span style={{ minWidth: "150px !important", width: "100% !important", textAlign: "left" }}>{GetTaskLocationUserName(user)}</span></TableCell>
                                  </TableRow>
                                  // <div style={{ display: "table-row" }}>
                                  //   <div className={classes.myCard}>
                                  //     {/* <div style={{ padding: "3px", textAlign: "center" }}>Total Tasks</div> */}
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Total', dealerId, user).toString()}</div>
                                  //   </div>
                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('New', dealerId, user).toString()}</div>
                                  //   </div>
                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Closed', dealerId, user).toString()}</div>
                                  //   </div>
                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Days30', dealerId, user).toString()}</div>
                                  //   </div>

                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Days90', dealerId, user).toString()}</div>
                                  //   </div>

                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Days90', dealerId, user).toString()}</div>
                                  //   </div>

                                  //   <div className={classes.myCard}>
                                  //     <div style={{ textAlign: "center" }}>{GetTaskTotal('Days180', dealerId, user).toString()}</div>
                                  //   </div>

                                  //   <div className={classes.myCard}>
                                  //     {/* <div style={{ padding: "3px", textAlign: "center" }}>User</div> */}
                                  //     <div style={{ textAlign: "left" }}>{GetTaskLocationUserName(user)}</div>
                                  //   </div>
                                  // </div>
                                )
                              })}
                            </Table>
                          </Paper>
                        </AccordionDetails>
                      </Accordion>

                    )
                  })
                  // }
                )}
                {typeof (dataDashBoard.MessageData) !== 'undefined' && dataDashBoard.MessageData != null && isMessengerPermission == 1 && (
                  // <div style={{ display: "table" }}></div>
                  <div style={{ width: "100%" }}>
                    <p></p>
                    <Accordion>
                      <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{ cursor: "default", paddingLeft: "15px", width: "100%" }}
                      >
                        <Typography sx={{ width: '250px', flexShrink: 0 }} className={classes.bold} >Text Messages</Typography>
                        <Typography className={classes.message + " " + classes.bold}>Total</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Sent</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Received</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Survey</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Broadcast</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Failed</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>Leads</Typography>
                        <Typography className={classes.message + " " + classes.bold} sx={{ color: 'text.secondary' }}>OptOut</Typography>
                      </AccordionSummary>
                    </Accordion>
                    {/* <Accordion expanded={expanded} onChange={handleChange('panel1')}></Accordion> */}
                    <Accordion
                     expanded={expandedMain} onChange={handlerAccordMainChange('panel1')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: '250px', flexShrink: 0 }} style={{ paddingLeft: "5px" }} >{GetMessageName()}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Total', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Sent', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Received', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Survey', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Broadcast', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Failed', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Widget', 0, 0)}</Typography>
                        <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('OptOut', 0, 0)}</Typography>


                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {GetMessageLocations().map((row: any) => {
                            return (
                              <div>
                                {/* <Accordion expanded={expanded} onChange={handleChange('panel1')}> */}
                                <Accordion className={classes.root} expanded={GetAccordExpanded(row.DealerId)} onChange={(event) => { handlerAccordClick(row.DealerId) }}>
                                  {/* <Accordion expanded={GetAccordExpanded(row.DealerId)} onChange={(event) => { handlerAccordClick(row.DealerId) }}> */}
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                  >
                                    <Typography sx={{ width: '235px', flexShrink: 0 }}><span className={classes.message}>{row.Name}</span> </Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Total', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Sent', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Received', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Survey', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Broadcast', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Failed', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('Widget', row.DealerId, 0)}</Typography>
                                    <Typography className={classes.message} sx={{ color: 'text.secondary' }}>{GetMessageTotal('OptOut', row.DealerId, 0)}</Typography>


                                  </AccordionSummary>
                                  {/* <AccordionDetails style={{border:"0px solid silver", padding:"0px", margin:"0px"}}> */}
                                  <AccordionDetails style={{ margin: "0px 0px 0px 0px", padding: "0px" }} >
                                    {/* <Typography style={{border:"0px solid green", padding:"0px", margin:"0px 0px 20px 10px"}}> */}
                                    {/* <div style={{ borderBottom: "1px solid siler", borderStyle: "outset", marginLeft: "20px" }}> */}
                                    {/* <div style={{ marginLeft: "20px", border:"1px solid red" }}> */}
                                    <Box style={{ marginLeft: "20px", marginTop:"5px", marginBottom:"10px", border:"0px solid silver" }}>
                                      <Paper>
                                        {GetMessageDepartments(row.DealerId).map((row: any) => {
                                          return (
                                            <div style={{ display: "table", margin: "0px", marginLeft:"5px", padding: "0px"}}>
                                              {/* <Paper> */}
                                              <div style={{ display: "table-row" }} >
                                                <Typography style={{ display: "table-cell", minWidth: "225px", maxWidth: "225px" }}>
                                                  <span className={classes.message}>{row.DepartmentName}</span>
                                                  <span className={classes.minDepartmentPhone}>{formatPhoneNumber(row.PhoneNumber)} </span>
                                                </Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Total', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Sent', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Received', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Survey', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Broadcast', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Failed', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('Widget', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ display: "table-cell" }} className={classes.message}>{GetMessageTotal('OptOut', 0, row.DepartmentId)}</Typography>
                                                <Typography style={{ paddingRight: "5px" }} className={classes.phone}>{formatPhoneNumber(row.PhoneNumber)}</Typography>
                                              </div>
                                              {/* </Paper> */}
                                            </div>
                                          )
                                        })}
                                      </Paper>
                                    </Box>
                                    {/* </div> */}
                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            )
                          })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {typeof (dataDashBoard.Customers) !== 'undefined' && dataDashBoard.Customers != null && isCustomerPermission == 1 && (
                  <Box style={{ display: "table", border: "0px solid red", margin: "0px", padding: "0px" }}>
                    {dataDashBoard.Customers.map((row: any) => {
                      return (
                        // <Paper style={{ padding: "3px 30px 3px 0px", marginTop: "10px" }}></Paper>
                        <Paper style={{ padding: "3px 10px 5px 0px", marginTop: "10px" }}>
                          {/* <Box> */}

                          {<Box display="flex" flexDirection="row">
                            <Box ml={1} className={classes.myCard} style={{ margin: "0px", marginLeft: "5px", padding: "0px" }} onClick={handleCustomerclick} >
                              <div style={{ padding: "3px 7px", textAlign: "center", fontWeight: "bold" }}>New Customers (Last 1 Yr)</div>
                              <div style={{ textAlign: "center", padding: "5px 0px" }}>{row.Year1}</div>
                            </Box>

                            <Box ml={1} className={classes.myCard} style={{ padding: "0px" }}>
                              <div style={{ padding: "3px 7px", textAlign: "center", fontWeight: "bold" }}>Last 180 Days</div>
                              <div style={{ textAlign: "center", padding: "5px" }}>{row.Days180}</div>
                            </Box>
                            <Box ml={1} className={classes.myCard} style={{ padding: "0px" }}>
                              <div style={{ padding: "3px 7px", textAlign: "center", fontWeight: "bold" }}>Last 90 Days</div>
                              <div style={{ textAlign: "center", padding: "5px" }}>{row.Days90}</div>
                            </Box>
                            <Box ml={1} className={classes.myCard} style={{ padding: "0px" }}>
                              <div style={{ padding: "3px 7px", textAlign: "center", fontWeight: "bold" }}>Last 60 Days</div>
                              <div style={{ textAlign: "center", padding: "5px" }}>{row.Days60}</div>
                            </Box>
                            <Box ml={1} className={classes.myCard} style={{ padding: "0px" }}>
                              <div style={{ padding: "3px 7px", textAlign: "center", fontWeight: "bold" }}>Last 30 Days</div>
                              <div style={{ textAlign: "center", padding: "5px" }}>{row.Days30}</div>
                            </Box>


                          </Box>}


                          {/* 
                        {<div style={{ display: "table-row" }}>
                          <Grid style={{ display: "table-cell", paddingLeft: "0px", minWidth: "265px", border: "0px solid blue", marginLeft: "0px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs style={{ border: "0px solid red", marginLeft: "-5px" }}>
                              <DashboardCard id="Promoters" title="New Customers (Last 1 Yr)" value={row.Year1} />
                            </Grid>
                          </Grid>
                          <Grid style={{ display: "table-cell", paddingLeft: "0px", minWidth: "150px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              <DashboardCard id="Promoters" title="Last 30 Days" value={row.Days30} />
                            </Grid>
                          </Grid>
                          <Grid style={{ display: "table-cell", paddingLeft: "0px", minWidth: "150px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              <DashboardCard id="Promoters" title="Last 60 Days" value={row.Days60} />
                            </Grid>
                          </Grid>
                          <Grid style={{ display: "table-cell", paddingLeft: "0px", minWidth: "150px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              <DashboardCard id="Promoters" title="Last 90 Days" value={row.Days90} />
                            </Grid>
                          </Grid>
                          <Grid style={{ display: "table-cell", paddingLeft: "0px", minWidth: "150px" }} container spacing={2} justifyContent="space-between">
                            <Grid item xs>
                              <DashboardCard id="Promoters" title="Last 180 Days" value={row.Days180} />
                            </Grid>
                          </Grid>
                        </div>} */}
                        </Paper>

                      )
                    })}
                  </Box>
                )}

                {typeof (dataDWDashBoard) !== 'undefined' && dataDWDashBoard != null && dataDWDashBoard.length > 0 && isInvoicePermission == 1 && (
                  // <Box style={{ display: "table", marginTop: "10px", borderStyle: "outset", border: "0px solid silver" }}>
                  <Box style={{ display: "table", marginTop: "10px", marginBottom:"10px" }}>
                    <Paper>
                      <Table size={'small'} className={classes.myTable} style={{ fontSize: "medium" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontSize: "medium", fontWeight: "bold" }}>Orders</TableCell>
                            <TableCell style={{ fontSize: "medium", fontWeight: "bold" }}>WorkOrders</TableCell>
                            <TableCell style={{ fontSize: "medium", fontWeight: "bold" }}>Wholegoods</TableCell>
                            <TableCell style={{ fontSize: "medium", fontWeight: "bold" }}>Parts</TableCell>

                          </TableRow>
                        </TableHead>
                        {dataDWDashBoard.map((row: any) => {
                          return (
                            <TableRow>
                              <TableCell style={{ fontSize: "medium" }}>{GetLocationName(row.DealerId)}</TableCell>
                              <TableCell style={{ fontSize: "medium" }} align='center'>{row.WorkOrder}</TableCell>
                              <TableCell style={{ fontSize: "medium" }} align='center'>{row.WG}</TableCell>
                              <TableCell style={{ fontSize: "medium" }} align='center'>{row.Part}</TableCell>

                            </TableRow>

                          )
                        }
                        )}
                      </Table>
                    </Paper>
                  </Box>
                )}
                {(isLoading || isLoading2) && (
                  <table>
                    <tr>
                      <td><h2>Loading dashboard...</h2></td>
                      <td><SmallCircularProgress></SmallCircularProgress></td>
                    </tr>
                  </table>
                )}


                {/* <Button variant="contained" style={{ marginTop: "15px" }} onClick={() => { setOpenUserActivity(true) }}>User Activity</Button> */}

              </Box>

            )}

            {/* {customerClickCount > 5 && ( */}
            

            {/* </Container > */}
              </Box>
            </Box>
          </Paper>
          </TabPanel>
          {isMessengerPermission == 1 ? 
          <TabPanel value='2'>
          <UserActivity 
          open={openUserActivity}
          isMessenger = {isMessengerPermission}
           onClose={() => { setOpenUserActivity(false) }} ></UserActivity>
          </TabPanel> 
          : "" 
          }          
        </TabContext>
      
      <Popover
        // id={id}
        open={openAnchorElMore}
        anchorEl={anchorElMore}
        onClose={e => SetOpenAnchorElMore(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          <Box style={{ backgroundColor: "#E5E7E9", padding: "3px 5px", fontWeight: "bold" }}>
            test
            <Menu
              id="basic-menu"
              anchorEl={anchorElMore}
              open={openAnchorElMore}
              onClose={e => SetOpenAnchorElMore(false)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={OpenGravityTextToPay}>Gravity Text To Pay</MenuItem>
              {/*
              {dashboardClickCount>10 && (dmsTypeId == DmsTypeEnum.Ideal || dmsTypeId == DmsTypeEnum.Infinity || dmsTypeId == DmsTypeEnum.Aspen) && (
                <MenuItem onClick={() => (SetOpenOpenTextAutomation(true), SetOpenAnchorElMore(false))}>Automation</MenuItem>
              )}
              */}
              

            </Menu>
          </Box>

        </Typography>
      </Popover>
      <Drawer
        PaperProps={{ style: { width: '90%', minWidth: "25%" } }}
        anchor="right"
        open={openGravityTextToPay}
        onClose={() => SetOpenGravityTextToPay(false)}
        variant={'temporary'}
      >
        <Box>
          <iframe src={`https://targetcrmservice.azurewebsites.net/?qtype=texttopay&dmsdealerid=${store.getState().user.dmsDealerId}&env=${config2.get('env')}`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe>
          {/* <iframe src={`http://localhost:3001/?qtype=texttopay&dmsdealerid=999002`} style={{ width: "100%", height: "100vh", minHeight: "500px" }}></iframe> */}

        </Box>
      </Drawer>
      {openTextAutomation && (
        <TextAutomation onClose={() => SetOpenOpenTextAutomation(false)}></TextAutomation>
      )}

      {selectedCustomerId > 0 && (
        // <CustomerEditDrawer customerId={selectedCustomerId} onClose={CloseCustomerDrawer} variant='temporary' onSave={OnCustomerSave} ></CustomerEditDrawer>
        <CustomerEditDrawer customerId={selectedCustomerId} onClose={() => SetSelectedCustomerId(0)} variant='temporary'  ></CustomerEditDrawer>
      )}

    </Box>
  );
}

export default DashboardPage;
