import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  LinearProgressProps,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ForwardIcon from '@material-ui/icons/Forward';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Yup from 'yup';

import { TabPanel } from 'common/components/tabs';
import { EmojiPickerButton } from 'common/components/buttons';
import TextLimitField from 'common/components/input-fields/TextLimitField';
import MediaGalleryAPI from 'components/broadcast/api/mediaGallery-api';
import { BroadCast, BroadCastType, MessageHistory, MmsMedia } from 'components/broadcast/models/broadcast';
import { GalleryImage } from 'components/broadcast/models/gallery-image';
import AppSnackBar from 'components/snackbar/snackbar';

import EmailEditor from './email-editor';
import { ImageGallery } from './image-gallery';
import { MessageSnippetWidget } from './message-templates';
import { SocialLinkWidget } from './social-link-widget';
import getTinyMce from './tiny-global';
import { UrlShortenerWidget } from './url-shortener-widget';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from 'app';
import { showSnackbar } from 'components/root-snackbar';
import Swal from 'sweetalert2';
import { useHistory, Prompt } from 'react-router-dom';
import { GetAttachments } from 'dmsAppsCallbacks';
import { AxiosResponse } from 'axios';
import config from 'config';
import { post } from 'api';
import { CalendarToday } from '@material-ui/icons';
import { ITaskTimes, taskHours } from 'components/lead/components/task-board/taskhours-step';
import { format ,isBefore } from 'date-fns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StaticDatePicker } from '@mui/lab';
import { TextField as TextFieldSch} from '@mui/material';
import moment from 'moment';
import BroadCastAPIV2, {BroadCastAPIV2 as BroadCastAPI} from 'components/broadcast/api/broadcast-api-v2';
import { useRouter } from 'common/hooks';
import { CompareObjects } from 'common/utils/compare-objects';
import BroadcastTestMessage from './broadcast-test-message';



const useStyles = makeStyles((theme: Theme): any => ({
  textareaHover: {
    border: '2px solid rgb(63 81 181)',
    background: '#3f51b359',
  },
  fileUploadOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    top: '0px',
    left: '3%',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  datePicker: {
    '& .css-dhopo2': {
      minHeight:'192px'
    },
  },
  fabStyle:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  fabLabels:{
    fontSize:'7px',
    margin:'0px'
  },
  swalAlert:{
    '& .swal2-popup':{ borderRadius:'20px'}
  }
}));

export function BroadcastForm({
  broadcast,
  type,
  onDuplicate,
  onSave,
  onPreview,
  onSend,
}: {
  broadcast: BroadCast;
  type: BroadCastType;
  onDuplicate(broadcast: BroadCast): Promise<void>;
  onSave(broadcast: BroadCast): Promise<void>;
  onPreview(broadcast: BroadCast): Promise<void>;
  onSend(broadcast: BroadCast): Promise<void>;
}): React.ReactElement {
  const dmsTypeId = useAppSelector((state)=>state.user.dmsTypeId); 
  const isHostedDealer = useAppSelector((state) => state.user.isHosted);
  const broadcastObject = broadcast;
  const emailSchema = Yup.object<any>().shape<any>({
    title: Yup.string().required('Title is required').max(50, 'Title can only have max length of 50'),
    subject: Yup.string().nullable().required('Subject is required'),
  });

  const schema = Yup.object<any>().shape<any>({
    title: Yup.string().required('Title is required').max(50, 'Title can only have max length of 50'),
    body: Yup.string()
      .nullable()
      .required('Message Body is required')
      .max(type == 'MMS' ? 1500 : 160, 'message is too large, reduce the chatacers'),
  });
  const theme = useTheme();
  let routeHistory = useHistory();
  const classes: any = useStyles();
  const router = useRouter();
  const { query }: any = router;
  const dispatch = useAppDispatch();
  const [showSnackBar] = AppSnackBar.useSnackBar();
  const [galleryImageUploading, setGalleryImageUploading] = React.useState(false);
  const [refreshImageGallery, setRefreshImageGallery] = React.useState(false);
  const [isDataChanged, setDataChanged] = useState(false);
  const [rows, setRows] = useState<MessageHistory[] | undefined>(broadcast.creditUsageHistory?.messageHistories);
  const [emailRows, setEmailRows] = useState<MessageHistory[] | undefined>(broadcast.creditUsageHistory?.emailHistories);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalMsg, setTotalMsg] = useState(
    type === 'EMAIL' ? (broadcast.creditUsageHistory?.emailHistories ? broadcast.creditUsageHistory?.emailHistories.length : 0) :
    (broadcast.creditUsageHistory?.messageHistories ? broadcast.creditUsageHistory?.messageHistories.length : 0),
  );
  const [page, setPage] = React.useState(0);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [checkUpload , setcheckUpload]  = useState(true);
  const [duplicateUpload , setduplicateupload] = useState(true);
  const [TotalSize , settotalSize]  = useState(0);
  const [isEmailEmpty, setIsEmailEmpty] = useState<boolean>(false);
  /********************************Edit Scheduled Broadcast**************************************/
  const [scheduleTime, setScheduleTime] = React.useState<ITaskTimes[]>(taskHours);
  const [selectedDate, setSelectedDate] = React.useState<Date>(broadcast.scheduleDate ? new Date(broadcast.scheduleDate) : new Date());
  const [selectedTime, setSelectedTime] = React.useState<string | undefined>(():any=>{
    if(broadcast.scheduleDate){
      const UTCdate = moment.utc(broadcast.scheduleDate).local().toDate();
      const date = new Date(UTCdate);
      const hours = date.getHours().toString().padStart(2,'0');
      const minutes = date.getMinutes().toString().padStart(2,'0');
      return `${hours}:${minutes}`;
    }else{
      return undefined;
    }
  });
  const [isScheduling, setIsScheduling] = React.useState<boolean>(false);
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openSch = Boolean(anchorEl);
  const id = openSch ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (date:any): void => {
    setSelectedDate(date);
  };
  const handleTimeChange = (e:any) => {
    setSelectedTime(e.target.value);
  };

  const combineDateTime = (date:any, time:any) => {
    if (!date || !time) return null;
    const dateObj = new Date(date);
    const [hours, minutes] = time.split(':');
    dateObj.setHours(parseInt(hours, 10));
    dateObj.setMinutes(parseInt(minutes, 10));
    dateObj.setSeconds(0,0);
    return dateObj;
  };
  const isSelectTimePassed = (yourTime:string) : boolean => {
    const [hours, minutes] = yourTime.split(':');
    const date = new Date();
    date.setHours(parseInt(hours,10));
    date.setMinutes(parseInt(minutes,10));
    date.setSeconds(0,0);
    if(date < new Date()){
      return true;
    }else{
      return false;
    }
  };
  async function handleRescheduleBroadcastForm(): Promise<void> {
    const scheduledDateTime = combineDateTime(selectedDate, selectedTime);
    if (scheduledDateTime) {
      try {
          setIsScheduling(true)
          const response = await BroadCastAPI.rescheduleBroadcast({
            broadcastId: broadcast.id,
            broadcastType: type,
            ScheduleTime: scheduledDateTime,
          });
          if(!response){
            throw new Error('Null Response');
          }
          setIsScheduling(false);
          handleClose();
          showSnackBar({ type: 'success', message: `Rescheduled broadcast on ${scheduledDateTime.toLocaleString('en-US', {hour12:true})}` });
          // router.push(`/broadcasts`);
      } catch (error) {
        setIsScheduling(false);
        showSnackBar({ type: 'error', message: 'Unable to schedule broadcast' });
      }
    }
  }

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const filteredTimes = scheduleTime.filter((time) => {
        const timeValue = time.value;
        const timeDate = new Date();
        const [hours, minutes] = timeValue.split(':');
        timeDate.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
        return !isBefore(timeDate, new Date());
      });
      if(selectedDate.getDate() === new Date().getDate()){
        setScheduleTime(filteredTimes);
      }else if(selectedDate.getDate() > new Date().getDate()){
        setScheduleTime(taskHours);
      }
      if(selectedTime && selectedDate.getDate() === new Date().getDate() && isSelectTimePassed(selectedTime)){
        setSelectedTime(undefined);
      }
    }, 500); // Update every second

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [scheduleTime, selectedDate, new Date()]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: broadcast,
    validationSchema: type === 'EMAIL' ? emailSchema : schema,
    onSubmit: (e) => {
      onSave(e);
    },
  });
  const onDropRejected = (error: any): void => {
    if (error[0].errors[0].code === 'file-too-large') {
      const size: number = type == 'MMS' ? 4 : 25;
      dispatch(showSnackbar({ type: 'error', message: `File size must be less than ${size} MB` }));
    } else {
      showSnackBar({ type: 'error', message: 'File type must be .jpeg, .jpg, .png, .gif, .pdf' });
    }
  };
  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const addImage = React.useCallback(
    (galleryImage: GalleryImage): void => {
      if (type === 'MMS') {
        
        const prevMedia = formik.values.mmsMedia ? formik.values.mmsMedia : [];
        if (prevMedia.map((x) => x.mediaUrl).indexOf(galleryImage.mediaUrl) === -1) {
          setcheckUpload(false);
          setduplicateupload(true); 
          const newResource = [{ id: 0, mmsMessageId: broadcast.id, mediaUrl: galleryImage.mediaUrl, mimeType: galleryImage.mimeType }];
            const newArray = prevMedia.concat(newResource)
            formik.setFieldValue('mmsMedia',newArray);
        }
        else{
          checkUpload ? setduplicateupload(false) : setduplicateupload(true)
        }   
      } else if (type === 'EMAIL') {
        const editor: any = getTinyMce().editors.tinyEmail;
        let content = editor.getContent();
        if (galleryImage.mimeType == 'application/pdf') {
          content += `<a href="${galleryImage.mediaUrl}">${galleryImage.mediaUrl}</a>`;
        } else {
          content += `<img alt="${galleryImage.mediaUrl}" src="${galleryImage.mediaUrl}"/>`;
        }
        editor.setContent(content);
      }
    },
    [broadcast.id, formik, type],
  );

  useEffect(() => {
    const listenStorageChange = () => {
      try {

        const fileName = dmsTypeId==0 ? localStorage.getItem('IdealFileUpload'):localStorage.getItem('InfinityFileUpload');
        if (fileName === 'CancelAttachment') {
          setGalleryImageUploading(false);
        } else if (fileName != null) {
          const response = localStorage.getItem(fileName);
          const addImageResponse: GalleryImage[] = JSON.parse(response!);
          addImageResponse.forEach((image) => addImage(image));
          setGalleryImageUploading(false);
          localStorage.removeItem(fileName);
          dmsTypeId==0 ? localStorage.removeItem('IdealFileUpload'):localStorage.removeItem('InfinityFileUpload');
        }
      } catch (error) {
        setGalleryImageUploading(false);
        showSnackBar({ type: 'error', message: 'Unable to upload image' });
      }
    };
    window.addEventListener('storage', listenStorageChange);
    return () => window.removeEventListener('storage', listenStorageChange);
  }, []);

  const onDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      const messagingUrl = config.get('api.messaging');
      const url = `${messagingUrl}/AddImages`;

      setGalleryImageUploading(true);
      const requestForm = new FormData();
      acceptedFiles.forEach((file) => {
        requestForm.append('files', file);
      });

      await post(url, requestForm, {
        
        onUploadProgress: (progressEvent: any) => {
          setcheckUpload(false);
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadingProgress(percentCompleted);
          
        },
      })
        .then((res: AxiosResponse<GalleryImage[]>) => {
          setGalleryImageUploading(false);
          setRefreshImageGallery(!refreshImageGallery);
          if (type === 'MMS') {
            const newMediaObjects: MmsMedia[] = res.data.map((galleryImage) => ({
              id: 0,
              mmsMessageId: broadcast.id,
              mediaUrl: galleryImage.mediaUrl,
            }));
            formik.setFieldValue('mmsMedia', newMediaObjects);
          } else if (type === 'EMAIL') {
            res.data.forEach((image) => addImage(image));
          }
        })
        .catch((err) => {
          setGalleryImageUploading(false);
          showSnackBar({ type: 'error', message: 'Unable to upload image' });
        });

    },
    [addImage, refreshImageGallery, showSnackBar],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: '.jpeg,.jpg,.png,.gif,.pdf',
    maxSize: type == 'MMS' ? 4194304 : 26214400, //4MB Max Limit for MMS, but 25MB for Email broadcast
  });
  async function handleBroadCastDuplicate(): Promise<void> {
    onDuplicate(formik.values);
  }
  async function handleBroadCastPreview(): Promise<void> {
    await onPreview(formik.values);
  }
  async function handleImageUpload(file: File): Promise<GalleryImage[]> {
    const response = await MediaGalleryAPI.addImage([file]);
    setRefreshImageGallery(!refreshImageGallery);
    return response;
  }
  async function handleSendBroadcast(): Promise<void> {
    let errors = (await formik.validateForm()) as any;
    if(query.type === 'EMAIL' && (formik.values.body === '' || formik.values.body === null)){
      errors = {...errors,body:'Body is required'};
      setIsEmailEmpty(true);
    }
    if (formik.isValid && Object.keys(errors).length === 0) {
      onSend(formik.values);
      setDataChanged(false);
    } else {
      let result = Object.keys(errors).map((key) => [errors[key]]);
      let html = '<ul style="font-size:15px;text-align:left;color:#ff1818">';

      result.forEach((x) => {
        html += `<li>${x.toString().replace('is required', '')}</li>`;
      });
      html += '</ul>';
      Swal.fire({
        title: 'Required information is missing',
        html: html,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    }
  }

  async function handleSendScheduleBroadcast(): Promise<void> {
    try {
      let errors = (await formik.validateForm()) as any;
      if(query.type === 'EMAIL' && (formik.values.body === '' || formik.values.body === null)){
        errors = {...errors,body:'Body is required'};
        setIsEmailEmpty(true);
      }
      if (formik.isValid && Object.keys(errors).length === 0) {
        setDataChanged(false);
        await BroadCastAPI.updateBroadcast(formik.values, query.type);
        showSnackBar({ type: 'success', message: 'Broadcast Updated' });
        setIsSending(true);
        await BroadCastAPI.sendBroadcast(
          formik.values,
          query.type,
          [],
          undefined,
          undefined,
          broadcast.isSchedule ?? false
        );
        setIsSending(false);
        router.push(`/broadcasts`); 
      } else {
        let result = Object.keys(errors).map((key) => [errors[key]]);
        let html = '<ul style="font-size:15px;text-align:left;color:#ff1818">';
  
        result.forEach((x) => {
          html += `<li>${x.toString().replace('is required', '')}</li>`;
        });
        html += '</ul>';
        Swal.fire({
          title: 'Required information is missing',
          html: html,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      setIsSending(false);
      showSnackBar({ type: 'error', message: 'Unable to queue broadcast' });
    }
  }

  async function handleSaveBroadcast(): Promise<void> {
    let errors = (await formik.validateForm()) as any;
    const isBroadcastValuesChanged = CompareObjects(broadcastObject,formik.values);
    if(query.type === 'EMAIL' && (formik.values.body === '' || formik.values.body === null)){
      errors = {...errors,body:'Body is required'};
      setIsEmailEmpty(true);
    }
    if (formik.isValid && Object.keys(errors).length === 0) {
      if(formik.values.isSchedule && isBroadcastValuesChanged){
        Swal.fire({
          title: 'Are you sure?',
          text: "Broadcast will be reverted to draft state!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, proceed!',

        }).then(async (result)=>{
          if(result.isConfirmed){
            try {
              setIsSending(true);
              const resp = await BroadCastAPIV2.deleteScheduledBroadcast(formik.values.id, query.type);
              if (resp) {
                formik.setFieldValue('isSchedule', undefined);
                formik.setFieldValue('scheduleDate', undefined);
                formik.setFieldValue('scheduledTime', undefined);
                onSave(formik.values);
                setDataChanged(false);
                Swal.fire('State changed!', 'Broadcast state changed to draft from scheduled.', 'success');
              }
              setIsSending(false);
            } catch (error) {
              setIsSending(false);
            }
          }
        })
      }else{
        onSave(formik.values);
        setDataChanged(false);
      }
    } else {
      let result = Object.keys(errors).map((key) => [errors[key]]);
      let html = '<ul style="font-size:15px;text-align:left;color:#ff1818">';
      result.forEach((x) => {
        html += `<li>${x.toString().replace('is required', '')}</li>`;
      });
      html += '</ >';
      Swal.fire({
        title: 'Required information is missing',
        html: html,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    }
  }

  function appendToBody(value: string): void {
    if (formik.values.body == null) formik.values.body = '';
    if (type === 'SMS' || type === 'MMS') {
      const curLength = type === 'SMS' ? 160 : 1500;
      const bodyLength = formik.values.body.length;
      const totalLength = curLength - bodyLength;
      if (value.length > totalLength) {
        value = value.substring(0, totalLength);
      }
    }
    formik.setFieldValue('body', formik.values.body + value);
  }

  function removeImage(index: number): void {
    if (type === 'MMS' && formik.values.mmsMedia) {
      let newMedia = formik.values.mmsMedia;
      const removedValue = newMedia[index];
      const remainingValues= formik.values.mmsMedia.filter(x=>x.mediaUrl != removedValue.mediaUrl);
      formik.setFieldValue('mmsMedia',remainingValues);
    }
  }
  // API for Image Size Calculation
  const [imageSize, setImageSize] = useState<number>();
  const fileSizeMaxLimit = type == 'MMS' ? 4194304 : 26214400;
  async function GetImageSize(url: string) {
    let data = await fetch(url, {
      method: 'GET',
      headers: { 'X-HTTP-Method-Override': 'HEAD' },
    })
      .then((resp) => resp.blob())
      .then((data) => data.size);
    return data;
  }
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (value: any) => {
    setRowsPerPage(value);
    setPage(0);
  };
  function handleMsgHistory(value: any) {
    if (value && value.length > 0) {
      const filteredRows = broadcast.creditUsageHistory?.messageHistories?.filter((x) => {
        if (x.cellPhone != null) {
          return x.cellPhone.includes(value);
        }
      });
      setTotalMsg(filteredRows ? filteredRows?.length : 0);
      setRows(filteredRows);
      setPage(0);
    } else {
      setTotalMsg(
        broadcast.creditUsageHistory?.messageHistories ? broadcast.creditUsageHistory?.messageHistories.length : 0,
      );
      setRows(broadcast.creditUsageHistory?.messageHistories);
      setPage(0);
    }
  }

  function handleEmailHistory(value: any) {
    if (value && value.length > 0) {
      const filteredRows = broadcast.creditUsageHistory?.emailHistories?.filter((x) => {
        if (x.email != null) {
          return x.email.includes(value);
        }
      });
      setTotalMsg(filteredRows ? filteredRows?.length : 0);
      setEmailRows(filteredRows);
      setPage(0);
    } else {
      setTotalMsg(
        broadcast.creditUsageHistory?.emailHistories ? broadcast.creditUsageHistory?.emailHistories.length : 0,
      );
      setEmailRows(broadcast.creditUsageHistory?.emailHistories);
      setPage(0);
    }
  }
  
  useEffect(() => {
    let value = 0;
   let promises:any = [];

if (formik.values.mmsMedia) {
  formik.values.mmsMedia?.forEach((x) => {
    promises.push(
      new Promise(async (resolve) => {
        const size = await GetImageSize(x.mediaUrl);
        if (size) {
          setImageSize((prevValue) => (prevValue ?? 0 )+ size);
        }
        resolve(size);
      })
    );
  });

  Promise.all<number | undefined>(promises).then((sizes) => {
    const totalSize = sizes.reduce((acc, size) => (size ? (acc ??0) + size : acc), 0);
    settotalSize(totalSize?? 0);
    if ((totalSize ?? 0) > fileSizeMaxLimit) {
      setcheckUpload(false);
    } else {
      setcheckUpload(true);
    }
  });
}
  }, [formik.values.mmsMedia]);

  const getFileExtension = (fileUrl: string) => {
    const parts = fileUrl.split('.');

    const lastPart = parts[parts.length - 1];
      if (lastPart.length > 0) {
        return lastPart.toLowerCase();
    }

    return null;
  };

  const [openTestMessageDialog, setOpenTestMessageDialog] = useState(false);

  const handleValidateBroadcastObject = async () =>
  {
    let errors = (await formik.validateForm()) as any;
   
    if(query.type === 'EMAIL' && (formik.values.body === '' || formik.values.body === null)){
      errors = {...errors,body:'Body is required'};
      setIsEmailEmpty(true);
    }

    if (formik.isValid && Object.keys(errors).length === 0) {
      return true;
    }
    
    let result = Object.keys(errors).map((key) => [errors[key]]);
    let html = '<ul style="font-size:15px;text-align:left;color:#ff1818">';
    result.forEach((x) => {
      html += `<li>${x.toString().replace('is required', '')}</li>`;
    });
    html += '</ >';

    Swal.fire({
      title: 'Required information is missing',
      html: html,
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });

    return false;
  }
    const handleOpenTestMessageDialog = async () => {
      
      if(await handleValidateBroadcastObject())
        setOpenTestMessageDialog(true);
  };

    const handleCloseTestMessageDialog = async () => {
    setOpenTestMessageDialog(false);
  };

  return (
    <>
      <Prompt when={isDataChanged} message={() => 'There are some unsaved changes. Do you want to go away?'}></Prompt>
      <Paper elevation={3} style={{ padding: theme.spacing(2, 1), marginBottom: theme.spacing(2) }}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Box mt={2} {...getRootProps()} onClick={(e) => e.preventDefault()}>
              <Grid container>
                <Box display="flex" flexGrow={1} flexDirection="row">
                  <ToggleButtonGroup exclusive size="small" value={type}>
                    <ToggleButton style={{ height: '32px' }} value="SMS" disabled>
                      SMS
                    </ToggleButton>
                    <ToggleButton style={{ height: '32px' }} value="MMS" disabled>
                      MMS
                    </ToggleButton>
                    <ToggleButton style={{ height: '32px' }} value="EMAIL" disabled>
                      Email
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>

              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                id="title"
                name="title"
                label="Title"
                required
                value={formik.values.title}
                onChange={formik.handleChange}
                onChangeCapture={() => {
                  setDataChanged(true);
                }}
                error={!!formik.errors.title}
                helperText={formik.errors.title}
                disabled={formik.values.isSent === 1 || formik.values.isSent === -1}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Alert severity="warning">
                Broadcast title is for future reference and isn&apos;t sent with the broadcast
              </Alert>

              <TabPanel value={type} index={type == 'SMS' ? 'SMS' : 'MMS'}>
                <TextLimitField
                  characters={type == 'SMS' ? 160 : 1500}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="dense"
                  id="Body"
                  name="body"
                  label="Body"
                  value={formik.values.body}
                  disabled={formik.values.isSent === 1 || formik.values.isSent === -1}
                  onChange={formik.handleChange}
                  onChangeCapture={() => {
                    setDataChanged(true);
                  }}
                  error={!!formik.errors.body}
                  onDrop={(ev: any) => {
                    let files: any[] = [];
                    if (ev.dataTransfer.items) {
                      // Use DataTransferItemList interface to access the file(s)
                      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                        // If dropped items aren't files, reject them
                        if (ev.dataTransfer.items[i].kind === 'file') {
                          let file = ev.dataTransfer.items[i].getAsFile();
                          files.push(file);
                        }
                      }
                    } else {
                      // Use DataTransfer interface to access the file(s)
                      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                        files.push(ev.dataTransfer.files[i]);
                      }
                    }

                    onDropAccepted(files);
                  }}
                  helperText={formik.errors.body}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <input {...getInputProps()} hidden />
                {isDragActive && <div className={classes.fileUploadOverlay}></div>}
              </TabPanel>

              <TabPanel value={type} index="EMAIL">
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id="subject"
                  name="subject"
                  label="Subject"
                  value={formik.values.subject}
                  disabled={formik.values.isSent === 1 || formik.values.isSent === -1}
                  error={!!formik.errors.subject}
                  helperText={formik.errors.subject}
                  onChange={formik.handleChange}
                  onChangeCapture={() => {
                    setDataChanged(true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  id="preHeader"
                  name="preHeader"
                  label="Pre Header"
                  value={formik.values.preHeader}
                  disabled={formik.values.isSent != 0}
                  onChange={formik.handleChange}
                  onChangeCapture={() => {
                    setDataChanged(true);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <EmailEditor
                  value={formik.values.body}
                  isEmpty={isEmailEmpty}
                  disabled={formik.values.isSent != 0}
                  onChange={(value: any): any => {
                    formik.setFieldValue('body', value);
                    setDataChanged(true);
                    value.length > 0 ? setIsEmailEmpty(false):setIsEmailEmpty(true);
                  }}
                  onImageUpload={handleImageUpload}
                />
                <input {...getInputProps()} hidden />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Grid item xs={2}>
              <EmojiPickerButton
                onSelect={(value: string): void => appendToBody(value)}
                disabled={formik.values.isSent != 0}
              />
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '.2rem' }}>
            <UrlShortenerWidget
              onClick={(value: string): void => appendToBody(value)}
              disabled={formik.values.isSent != 0}
            />
          </Grid>
          <Grid item style={{ marginTop: '.1rem' }}>
            <MessageSnippetWidget
              onClick={(value: string): void => appendToBody(value)}
              disabled={formik.values.isSent != 0}
            />
          </Grid>
          <Grid item style={{ marginTop: '.15rem' }}>
            <SocialLinkWidget
              onClick={(value: string): void => appendToBody(value)}
              disabled={formik.values.isSent != 0}
              disableAllLocations={true}
            />
          </Grid>
          <Grid item>
            <Box hidden={type === 'SMS'}>
              <IconButton
                style={{ padding: 0, marginLeft: '-.2rem', marginTop: '.2rem' }}
                onClick={(): void => {
                  if ((localStorage.getItem('Login_Source')?.toString() === 'DMS_IDEAL') || (isHostedDealer && localStorage.getItem('Login_Source')?.toString() === 'DMS_INFINITY')) {  
                    setGalleryImageUploading(true);
                    GetAttachments(type == 'MMS' ? 4194304 : 26214400);
                  } else {
                    open();
                  }
                }}
                size="small"
                disabled={formik.values.isSent != 0}>
                <AttachFileIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}>
            {formik.values.isSent ? (
              <Fab
                id="broadcastDuplicateBtn"
                style={{ position: 'absolute', top: theme.spacing(0), right: theme.spacing(-5) }}
                onClick={handleBroadCastDuplicate}
                title="Duplicate BroadCast"
                color="primary">
                  <div className={classes.fabStyle}>
                    <FileCopyIcon />
                    <p className={classes.fabLabels}>Duplicate</p>
                  </div>
              </Fab>
            ) : (
              <>
                <Fab
                  disabled={isSending}
                  id="broadcast_to_customer_group_btn"
                  style={{ position: 'absolute', top: theme.spacing(0), right: theme.spacing(-5)}}
                  type="submit"
                  onClick={handleSaveBroadcast}
                  title="Save Broadcast"
                  color="primary">
                  <div className={classes.fabStyle}>
                    <SaveIcon />
                    <p className={classes.fabLabels}>Save</p>
                  </div>
                </Fab>
                  <Fab
                    id="broadcastToCustomerGroupsBtn"
                    disabled={(type == 'MMS') && !checkUpload  || isSending}
                    style={{ position: 'absolute', top: formik.values.isSchedule ? theme.spacing(24) : theme.spacing(16), 
                    right: theme.spacing(-5),
                    cursor: (type == 'MMS') && (!checkUpload) ? 'not-allowed' : 'pointer',
                    pointerEvents: 'auto' 
                    }}
                    title={formik.values.isSchedule ? "Send Now": "Continue to customer groups"}
                    onClick={formik.values.isSchedule ? handleSendScheduleBroadcast : handleSendBroadcast}
                    color="primary">
                      <div className={classes.fabStyle}>
                        <ForwardIcon />
                        <p className={classes.fabLabels}>{formik.values.isSchedule ? 'Send Now': 'Recipients'}</p>
                      </div>
                  </Fab>
              </>
            )}
            <Fab
              id="previewBroadCastBtn"
              disabled={isSending}
              title="Preview BroadCast"
              style={{ position: 'absolute', top: theme.spacing(8), right: theme.spacing(-5)}}
              onClick={handleBroadCastPreview}
              color="primary">
                <div className={classes.fabStyle}>
                  <VisibilityIcon />
                  <p className={classes.fabLabels}>Preview</p>
                </div>
            </Fab>
            <Fab
              id="testMessageBroadCastBtn"
              disabled={isSending}
              title="Test Message"
              style={{ position: 'absolute', top: theme.spacing(8), right: theme.spacing(-5)}}
              onClick={handleOpenTestMessageDialog}
              color="primary">
                <div className={classes.fabStyle}>
                  <MarkEmailReadIcon />
                  <p className={classes.fabLabels}>Test Message</p>
                </div>
            </Fab>
            {formik.values.isSchedule && (
            <Fab
              title="Reschedule Broadcast"
              disabled={isSending}
              style={{ position: 'absolute', top: theme.spacing(16), right: theme.spacing(-5)}}
              onClick={handleClick}
              color="primary">
                <div className={classes.fabStyle}>
                  <CalendarToday />
                  <p className={classes.fabLabels}>Reschedule</p>
                </div>
            </Fab>)}
            <Popover
              id={id}
              open={openSch}
              anchorEl={anchorEl}
              style={{ width: '350px' }}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Grid container>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box className={classes.datePicker}>
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        disablePast
                        label="date range"
                        value={[selectedDate]}
                        onChange={handleDateChange}
                        renderInput={(props) => <TextFieldSch {...props} />}
                      />
                    </Box>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Box mt={1} pl={3} pr={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography style={{ fontWeight: 'bold', float: 'left' }}>
                      {format(selectedDate, 'MMMM d, yyyy')} at
                    </Typography>
                    <TextFieldSch
                      select
                      fullWidth
                      margin="dense"
                      size="small"
                      label="Time"
                      style={{ float: 'right', width: '115px' }}
                      value={selectedTime}
                      onChange={handleTimeChange}>
                      {scheduleTime.map((hour: ITaskTimes) => (
                        <MenuItem style={{ overflowY: 'auto', maxHeight: '200px' }} key={hour.value} value={hour.value}>
                          {hour.label}
                        </MenuItem>
                      ))}
                    </TextFieldSch>
                    {/* <TextField /> */}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    pl={3}
                    pr={3}
                    mt={2}
                    mb={2}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      style={{ float: 'left', backgroundColor: 'red', color: 'white' }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={handleRescheduleBroadcastForm}
                      disabled={!selectedTime}
                      variant="contained"
                      color="primary"
                      style={{ float: 'right', width: '181px', height: '36.5px' }}>
                      {isScheduling ? (
                        <CircularProgress size="1.7rem" style={{ color: 'white' }} />
                      ) : (
                        'Reschedule & Close'
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Popover>
          </Grid>
        </Grid>
        {galleryImageUploading ? (
          <Box width="100%">
            <Typography variant="subtitle1">Uploading files</Typography>
            <LinearProgressWithLabel value={uploadingProgress} />
          </Box>
        ) : (
          <Grid container justifyContent="flex-start">
            <ImageList cols={6} style={{ width: '100%' }}>
              {formik.values.mmsMedia?.map((media: any, index: number) => (
                <ImageListItem key={media.id} style={{ height: '150px' }}>
                  {getFileExtension(media.mediaUrl) === 'pdf' ? (
                    <img src="/pdf.png" alt={media.fileName} />
                  ) : (
                    <img src={media.mediaUrl} alt={media.fileName} />
                  )}
                  <ImageListItemBar
                    title=""
                    actionIcon={
                      !formik.values.isSent && (
                        <Button onClick={(): void => removeImage(index)}>
                          <RemoveCircleIcon />
                        </Button>
                      )
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
            {!checkUpload && (TotalSize >  fileSizeMaxLimit) ? (
              <span style={{ color: 'red' }}>Total Size Exceeds {type == 'MMS' ? 4 : 25} MB</span>
            ) : (
              formik.values.mmsMedia &&
              formik.values.mmsMedia?.length > 0 && (
                !checkUpload ?
                <div style={{ marginTop: '1.3rem', marginLeft:'0.5rem' }}>
                <CircularProgress size="1.5rem" />
                <span style={{  paddingTop:'1.0rem', marginLeft:'0.5rem' }}>Calculating</span>
              </div> :
                <span style={{ color: 'green' }}>Total Size: {((TotalSize || 0) / 1048576).toFixed(2)} MB</span>
              )
            )}
          </Grid>
        )}
      </Paper>
      <Box hidden={type === 'SMS'}>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <ImageGallery onAdd={addImage} disabled={formik.values.isSent != 0} refresh={refreshImageGallery} />
          </Grid>
        </Grid>
      </Box>
      {broadcast.isSent == 1 && (
        <Box pb={10}>
          <TableContainer component={Paper}>
            <Box ml={2} pt={2}>
              <Typography variant="h4" style={{ float: 'left' }}>
                Customer Group(s) : {broadcast?.creditUsageHistory?.segmentName}
              </Typography>
              <TextField
                style={{ float: 'right', paddingRight: '5px' }}
                variant="outlined"
                label="Search"
                size="small"
                inputProps={{ shrink: true }}
                onChangeCapture={(e: any) => {
                  type === 'EMAIL' ? handleEmailHistory(e.target.value) : handleMsgHistory(e.target.value);
                }}
              />
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>Customer</TableCell> */}
                  {(type === 'SMS' || type === 'MMS') && <TableCell align="left">Cell Phone</TableCell>}
                  {type === 'EMAIL' && <TableCell align="left">Email</TableCell>}
                  <TableCell align="left">Status</TableCell>
                  {/* <TableCell align="left">Description</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {type === 'EMAIL' ? 
                emailRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id}>
                  <TableCell align="left">{row.email ? row.email : 'N/A'}</TableCell>
                  <TableCell align="left">{row.hasError ? 'Failed' : 'Success'}</TableCell>
                  </TableRow>
                ))
                : 
                rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.cellPhone ? row.cellPhone : 'N/A'}</TableCell>
                    <TableCell align="left">{row.hasError ? 'Failed' : 'Success'}</TableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            </Table>
            <TablePagination
              size="small"
              component="div"
              rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
              count={totalMsg ? totalMsg : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => {
                handleChangeRowsPerPage(e.target.value);
              }}
            />
          </TableContainer>
        </Box>
      )}
      {openTestMessageDialog &&
        <BroadcastTestMessage
          broadcast={formik.values}
          type={type}
          openTestMessageDialog={openTestMessageDialog}
          onClose={handleCloseTestMessageDialog}
        />
      }
    </>
  );
}

export default BroadcastForm;
function classNames(arg0: { 'text-strike': any }): string | undefined {
  throw new Error('Function not implemented.');
}
