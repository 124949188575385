import {
  Box,
  Button, Grid,
  IconButton, Popover, TextField, Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { useStyles } from './CustomerForm';

export function Birthday({ formik }: { formik: any; }): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes: any = useStyles();
  const [date, setDate] = React.useState('01/01/2022'); //formik.values.dateOfBirth
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const formatDate = (date: string): string => {
    if (date){
      let today = new Date();
      let d = new Date(moment(date).format('MM/DD/YYYY'))
      if(today>d){
       return moment(date).format('MM/DD/YYYY'); 
      }
      else{
        return moment(d.getMonth()+"/"+d.getDate()+"/19"+d.getFullYear().toString().substr(-2)).format('MM/DD/YYYY'); 
      }
    }
      
    else {
      return '';
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  //March, 29, 1985
  return (
    <Grid container justify="flex-start" alignItems="center" style={{ marginLeft: '.2em' }}>
      <Grid item style={{ marginRight: '5px' }}>
        {' '}
        <FontAwesomeIcon icon={faBirthdayCake} />{' '}
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Birthday:&nbsp; {formatDate(formik.values.dob)}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)} className={classes.iconButton}>
          <EditIcon style={{ fontSize: '1rem' }} />
        </IconButton>
        {/* <EditIcon style={{ marginLeft: '10px', cursor: "pointer" }} aria-describedby={id} onClick={(e: any) => (handleClick(e))} fontSize='small' /> */}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box p={2} style={{ width: '500px' }}>
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={formatDate(formik.values.dob)}
                onError={() => {
                  console.log('error');
                }}
                maxDate={new Date()}
                onChange={(e: any) => {
                  console.log('date value :', e);
                  // setDate(e);
                  if (!e) {
                    e = '';
                  }
                  // setDate(e);
                  let date = formatDate(moment(e).format('MM/DD/YYYY'))
                  formik.setFieldValue('dob', date);
                }}
                renderInput={(params: any) => {
                  // params.error = !!formik.errors.dateOfBirth;
                  // console.log(formik.errors.dateOfBirth);
                  return (
                    <TextField
                      autoFocus
                      margin="dense"
                      name="dob"
                      {...params}
                      label={`Birthday`}
                      size="small"
                      onBlur={() => formik.setFieldTouched('dob', true, true)}
                      helperText={formik.errors.dob}
                      variant="outlined"
                      error={!!formik.errors.dob} />
                  );
                }} />
            </LocalizationProvider>
          </>
          <Grid item xs={2}>
            <Button
              fullWidth
              id="saveCustomerAddressBtn"
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => {
                handleClose();
              }}>
              Close
            </Button>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  );
}
