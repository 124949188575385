export const dateTimeFormats = {
    HourMinuteFormat: 'h:mm a',
    MonthDayFormat: 'M/D',
    MonthDayYearFormat: 'M/DD/YYYY',
    MonthDayYearHourMinuteFormat: 'M/d/yyyy h:mm a',

 };

 export const MessageStatusType = {
    delivered: "delivered",
    failed: "failed",
    sent:"sent",
    sending:"sending",
 };

 export const ConversationType = {
    All: {
        Display:"All",
        Value:"all"
    },
    UnRead: {
        Display:"Unread",
        Value:"unread"
    },
    Archive: {
        Display:"Archived",
        Value:"archive"
    }
 };

 export const BroadcastType = {
    EMAIL:"EMAIL",
    MMS:"MMS",
    SMS:"SMS",
 };

 
 export const SurveyConstants = {
    DefaultStartHour:"08",
    DefaultEndHour:"18",
 };
